import { useContext, useMemo, useRef } from 'react';
import {
  Images,
  ListMagnifyingGlass,
  PenNib,
  CodeBlock,
  Icon,
  Video,
} from '@phosphor-icons/react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useCurrentMessage,
  useSubmitUserInput,
  useDeleteAttachment,
} from 'src/hooks';
import {
  DrawerTooltipContainer,
  DrawerTooltipContainerRef,
} from 'src/v2/commonComponents/DrawerTooltipContainer';
import { getSkillFromMessage, sanitizeHTMLId } from 'src/utils';
import { RegenerateToggle } from '../RegenerateToggle';
import { isMediaSkill, MessageSkill } from 'src/types';
import { RedoBySkillSubmit } from '../RedoBySkillSubmit';
import styles from './RegenerateMessageControl.module.scss';

export const REDO_OPTIONS: Array<{
  skill: MessageSkill;
  Icon: Icon;
  availableForMediaSkill: boolean;
  onlyForMediaSkill?: boolean;
}> = [
  {
    skill: MessageSkill.WRITER,
    Icon: PenNib,
    availableForMediaSkill: true,
  },
  {
    skill: MessageSkill.CODER,
    Icon: CodeBlock,
    availableForMediaSkill: false,
  },
  {
    skill: MessageSkill.RESEARCHER,
    Icon: ListMagnifyingGlass,
    availableForMediaSkill: false,
  },
  {
    skill: MessageSkill.IMAGE_GENERATION,
    Icon: Images,
    availableForMediaSkill: true,
  },
  {
    skill: MessageSkill.VIDEO_GENERATION,
    Icon: Video,
    availableForMediaSkill: true,
    onlyForMediaSkill: true,
  },
];

interface RegenerateMessageControlProps {
  messageId?: string;
  disabled?: boolean;
}

export const RegenerateMessageControl = ({
  messageId,
  disabled,
}: RegenerateMessageControlProps) => {
  const { onClearInputBox } = useContext(ThreadInputBoxContext);

  const { currentMessage } = useCurrentMessage(messageId);
  const { onSubmitUserInput } = useSubmitUserInput();
  const { onDeleteAllAttachments } = useDeleteAttachment();

  const drawerRef = useRef<DrawerTooltipContainerRef>(null);

  const tooltipAnchorId = useMemo(
    () => `redo-trigger-${sanitizeHTMLId(messageId || '')}-tooltip`,
    [messageId],
  );

  const userInput = useMemo(
    () => currentMessage?.refined_query || currentMessage?.clean_query || '',
    [currentMessage?.refined_query, currentMessage?.clean_query],
  );

  const currentSkill = useMemo(
    () => getSkillFromMessage(currentMessage),
    [currentMessage],
  );

  const currentRedoOptions = useMemo(() => {
    return REDO_OPTIONS.filter(
      ({ skill, availableForMediaSkill, onlyForMediaSkill }) => {
        if (!currentSkill) {
          return false;
        }

        if (skill === currentSkill) {
          return false;
        }

        if (isMediaSkill(currentSkill)) {
          return availableForMediaSkill;
        }

        return skill !== currentSkill && !onlyForMediaSkill;
      },
    );
  }, [currentSkill]);

  const handleSubmit = (skill: MessageSkill) => {
    onDeleteAllAttachments();
    onClearInputBox();
    const preparedUserInput = `/${skill} ${userInput}`;
    onSubmitUserInput(preparedUserInput);
    drawerRef.current?.onVisibilityToggle();
  };

  return (
    <DrawerTooltipContainer
      ref={drawerRef}
      id={tooltipAnchorId}
      place="right"
      offset={8}
      classNameTooltip={styles.tooltip}
      ToggleButton={RegenerateToggle}
      disabledToggle={disabled}
    >
      <div className={styles.container}>
        <p className={styles.title}>Redo answer with</p>

        {currentRedoOptions.map(({ Icon, skill }) => (
          <RedoBySkillSubmit
            key={skill}
            Icon={Icon}
            skill={skill}
            onSubmit={handleSubmit}
          />
        ))}
      </div>
    </DrawerTooltipContainer>
  );
};
