import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  AVAILABLE_TO_UPLOAD_FILE_TYPES_LIST,
  MAX_NUMBER_OF_PASTED_WORDS,
  REG_EXP_FOR_WORD_SEPARATORS,
} from 'src/constants';
import { useAttachFile } from '../useAttachFile';
import { useSession } from '../useSession';
import { useConvertTextToFile } from './useConvertTextToFile';

export const usePasteUserInput = () => {
  const { isOpenTier } = useSession();

  const { handleUploadedFiles } = useAttachFile();
  const { onConvertTextToFile } = useConvertTextToFile();

  const onPasteUserInput = useCallback(
    async <T extends HTMLElement>(e: React.ClipboardEvent<T>) => {
      const clipboardData = e.clipboardData;
      const pastedText = clipboardData.getData('text');
      const items = clipboardData.items;
      const allPastedFiles = [];

      const valueSplittedToWords = pastedText.split(
        REG_EXP_FOR_WORD_SEPARATORS,
      );

      if (
        valueSplittedToWords.length > MAX_NUMBER_OF_PASTED_WORDS &&
        !isOpenTier
      ) {
        e.preventDefault();
        await onConvertTextToFile(pastedText);
      }

      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          if (isOpenTier) {
            toast.error('You’ll need a sign-in account to add attachment');
            e.preventDefault();
            break;
          }
          const file = items[i].getAsFile();
          if (file) {
            if (!AVAILABLE_TO_UPLOAD_FILE_TYPES_LIST.includes(file.type)) {
              toast.error(
                `.${file.name.split('.').pop()?.toLowerCase()} file type is not supported. Please try again with a compatible file format.`,
              );
              e.preventDefault();
            } else {
              allPastedFiles.push(file);
            }
          }
        }
      }

      if (allPastedFiles.length > 0) {
        e.preventDefault();
        await handleUploadedFiles([...allPastedFiles]);
      }
    },
    [isOpenTier, onConvertTextToFile, handleUploadedFiles],
  );

  return useMemo(
    () => ({
      onPasteUserInput,
    }),
    [onPasteUserInput],
  );
};
