import {
  ChatCircleDots,
  ListMagnifyingGlass,
  TextColumns,
  Images,
  Video,
  Icon as PhosphorIcon,
  FileMagnifyingGlass,
  CalendarDots,
} from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { Button } from 'src/v2/commonComponents/Button';
import {
  useLeftPanelState,
  useRightSidePanelActions,
  useRightSidePanelState,
  useLeftPanelActions,
  useConversations,
  useAttachedImageTooltipState,
  useAttachedImageTooltipActions,
  useConversationActions,
} from 'src/hooks';
import classNames from 'classnames';
import styles from './NavSidebar.module.scss';
import { TaskSkill, LeftPanelSections } from 'src/types';
import { UserImage } from 'src/v2/components/UserImage';
import { BetaLabel } from 'src/v2/commonComponents/BetaLabel';
import { DownloadAppsButton } from '../DownloadAppsButton';

interface SkillItem {
  section: LeftPanelSections;
  Icon: PhosphorIcon;
  tooltipContent: string;
  skill: TaskSkill | TaskSkill[];
  isBeta?: boolean;
}

const ACTION_LIST = [
  {
    section: LeftPanelSections.CONVERSATION_LIST,
    Icon: ChatCircleDots,
    tooltipContent: 'Chat history',
  },
];

const SKILL_LIST: SkillItem[] = [
  {
    section: LeftPanelSections.WRITER_CODER,
    Icon: TextColumns,
    tooltipContent: 'Writing & Coding',
    skill: [TaskSkill.CHITCHAT, TaskSkill.CODING],
  },
  {
    section: LeftPanelSections.IMAGE_GEN,
    Icon: Images,
    tooltipContent: 'Image Generation',
    skill: TaskSkill.IMAGE,
  },
  {
    section: LeftPanelSections.RESEARCHER,
    Icon: ListMagnifyingGlass,
    tooltipContent: 'Research',
    skill: TaskSkill.RESEARCH,
  },
  {
    section: LeftPanelSections.FILE_AGENT,
    Icon: FileMagnifyingGlass,
    tooltipContent: 'File Analysis',
    skill: TaskSkill.DOCUMENT_QA,
  },
  {
    section: LeftPanelSections.VIDEO,
    Icon: Video,
    tooltipContent: 'Video Generation',
    skill: TaskSkill.VIDEO,
    isBeta: true,
  },
  {
    section: LeftPanelSections.SCHEDULER,
    Icon: CalendarDots,
    tooltipContent: 'Scheduler',
    skill: TaskSkill.SCHEDULING,
    isBeta: true,
  },
];

export const NavSidebar = () => {
  const { toggleRightSidePanelExpanded } = useRightSidePanelActions();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { toggleLeftPanelExpanded, setLeftPanelData } = useLeftPanelActions();
  const { isLeftPanelExpanded, leftPanelSections } = useLeftPanelState();
  const { isAttachedImageEditingMode } = useAttachedImageTooltipState();
  const {
    onChangeIsAttachedImageEditingMode,
    onToggleImageEditTooltipVisible,
  } = useAttachedImageTooltipActions();
  const { hasUnreadMessage } = useConversations();

  const { setTaskSkillFilter } = useConversationActions();

  const handleButtonClick = (panelType: LeftPanelSections) => {
    if (leftPanelSections === panelType && isLeftPanelExpanded) {
      toggleLeftPanelExpanded(false);
      return;
    }

    setLeftPanelData({
      panelType,
      isExpanded: true,
    });

    if (isRightPanelExpanded) {
      toggleRightSidePanelExpanded(false);
    }

    if (isAttachedImageEditingMode) {
      onChangeIsAttachedImageEditingMode(false);
      onToggleImageEditTooltipVisible(true);
    }
  };

  const handleActionClick = (panelType: LeftPanelSections) => {
    setTaskSkillFilter();
    handleButtonClick(panelType);
  };

  const handleSkillClick = ({
    section,
    skill,
  }: {
    section: LeftPanelSections;
    skill: TaskSkill | TaskSkill[];
  }) => {
    setTaskSkillFilter(skill);
    handleButtonClick(section);
  };

  return (
    <div
      className={classNames(styles.root, {
        [styles.withBorder]: isLeftPanelExpanded,
        [styles.withShadow]: !isLeftPanelExpanded,
      })}
    >
      <div className={styles.container}>
        <AddConversationButton appearance="ninja-icon" />

        {ACTION_LIST.map(({ section, Icon, tooltipContent }) => (
          <Button
            key={section}
            color="transparent"
            shape="round"
            onClick={() => handleActionClick(section)}
            tooltipContent={tooltipContent}
            className={classNames(styles.button, {
              [styles.active]:
                isLeftPanelExpanded && section === leftPanelSections,
              [styles.withRedDot]:
                section === LeftPanelSections.CONVERSATION_LIST &&
                hasUnreadMessage,
            })}
            data-e2e={`action-${section}-button`}
          >
            <Icon size={SVG_SIZE_M} />
          </Button>
        ))}

        <hr className="divider" />

        {SKILL_LIST.map(({ section, Icon, tooltipContent, skill, isBeta }) => (
          <Button
            key={section}
            color="transparent"
            shape="round"
            onClick={() => handleSkillClick({ section, skill })}
            tooltipContent={tooltipContent}
            className={classNames(styles.button, {
              [styles.active]:
                isLeftPanelExpanded && section === leftPanelSections,
            })}
            data-e2e={`action-${section}-button`}
          >
            <Icon size={SVG_SIZE_M} />

            {isBeta && <BetaLabel className={styles.label} />}
          </Button>
        ))}
      </div>

      <div className={styles.container}>
        <DownloadAppsButton />

        <Button
          data-e2e="user-menu-button"
          color="transparent"
          shape="round"
          onClick={() => handleButtonClick(LeftPanelSections.USER_MENU)}
          tooltipContent="Account"
        >
          <UserImage />
        </Button>
      </div>
    </div>
  );
};
