interface LabelTeamsStandardProps {
  labelType?: 'short' | 'full';
}

/**
 * @deprecated
 */
export const LabelTeamsStandard = ({
  labelType = 'full',
}: LabelTeamsStandardProps) => {
  return (
    <label className="nj-label-standard" data-e2e="label-tier-team-standard">
      {labelType === 'short'
        ? 'Business Standard'
        : 'Business Standard subscription'}
    </label>
  );
};
