import {
  isChitChatCard,
  Message,
  MessageSkill,
  MessageType,
  TaskSkill,
} from 'src/types';

export const getSkillFromMessage = (message?: Message): MessageSkill | null => {
  if (!message) {
    return null;
  }

  const { message_type, payload } = message;

  const taskSkill = isChitChatCard(payload) ? payload.data?.skill : undefined;

  switch (message_type) {
    case MessageType.SCHEDULER_TASK_CREATION_CARD:
      return MessageSkill.SCHEDULER;

    case MessageType.RESEARCH_TASK_CREATION_CARD:
      return MessageSkill.RESEARCHER;

    case MessageType.CODE_TASK_CREATION_CARD:
      return MessageSkill.CODER;

    case MessageType.CHAT_CARD:
      if (taskSkill === TaskSkill.CODING) {
        return MessageSkill.CODER;
      }
      if (taskSkill === TaskSkill.DOCUMENT_QA) {
        return MessageSkill.FILE;
      }

      return MessageSkill.WRITER;

    case MessageType.VIDEO_CARD:
      return MessageSkill.VIDEO_GENERATION;

    case MessageType.IMAGE_CARD:
      return MessageSkill.IMAGE_GENERATION;

    default:
      return MessageSkill.WRITER;
  }
};
