import { useContext, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  useCurrentConversationTasks,
  useCurrentMessage,
  useTaskById,
} from 'src/hooks';
import { ListBullets } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { GTMEvent } from 'src/types';
import { getTaskIdFromMessage, sendGTMEvent } from 'src/utils';
import { Button } from 'src/v2/commonComponents/Button';

interface TaskDetailsButtonProps {
  messageId?: string;
}

export const TaskDetailsButton = ({ messageId }: TaskDetailsButtonProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  const { setCurrentTaskId } = useTaskById();
  const { onToggleVisibleTaskViewModal } = useContext(SessionContext);
  const { getTaskFromConversationById } = useCurrentConversationTasks();

  const taskId = useMemo(
    () => getTaskIdFromMessage(currentMessage),
    [currentMessage],
  );
  const taskState = useMemo(
    () => (taskId ? getTaskFromConversationById(taskId)?.state : undefined),
    [taskId, getTaskFromConversationById],
  );

  const handleClick = () => {
    if (!taskId) {
      return;
    }
    setCurrentTaskId(taskId);
    onToggleVisibleTaskViewModal(true);
    sendGTMEvent(GTMEvent.TASK_VIEW_OPENING, {
      taskId,
      task_state: taskState,
    });
  };

  return (
    <Button disabled={!taskId} color="transparent" onClick={handleClick}>
      <ListBullets size={SVG_SIZE_M} />
    </Button>
  );
};
