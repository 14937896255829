import { SVGProps } from 'react';

export const Portrait = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="11"
        height="15"
        rx="1.5"
        fillOpacity="0.4"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
