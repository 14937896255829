import { SVGProps } from 'react';

export const Ios = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5707_212)">
        <path
          d="M12.5021 8.33333C12.5021 6.89901 13.3277 5.6604 14.528 5.05786C13.4566 4.0544 12.0799 4 11.8353 4C11.1851 4 10.7212 4.17611 10.2728 4.34635C9.83849 4.51139 9.42826 4.66699 8.83472 4.66699C8.24117 4.66699 7.83094 4.51139 7.39661 4.34635C6.94828 4.17611 6.48432 4 5.83413 4C5.42454 4 1.83334 4.13086 1.83334 8.66699C1.83334 12.3763 4.36476 16 5.83413 16C6.73372 16 7.25107 15.7897 7.70787 15.6042C8.06471 15.4593 8.37336 15.334 8.83472 15.334C9.29607 15.334 9.60472 15.4593 9.96156 15.6045C10.4184 15.7897 10.9357 16 11.835 16C12.8644 16 14.3303 14.2193 15.1667 11.8788C13.5878 11.4698 12.5021 10.0398 12.5021 8.33333Z"
          fill="currentColor"
        />
        <path
          d="M8.50151 4C10.4546 3.6 11.8546 1.99008 11.8351 0C9.88201 0.4 8.48203 2.00992 8.50151 4Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M11.835 15.8333C10.9357 15.8333 10.4184 15.6231 9.96156 15.4378C9.60472 15.2926 9.29607 15.1673 8.83472 15.1673C8.37336 15.1673 8.06471 15.2926 7.70787 15.4375C7.25107 15.6231 6.73372 15.8333 5.83413 15.8333C4.37689 15.8333 1.87831 12.2689 1.83713 8.59184C1.83688 8.61817 1.83334 8.64035 1.83334 8.667C1.83334 12.3763 4.36476 16 5.83413 16C6.73372 16 7.25107 15.7897 7.70787 15.6042C8.06471 15.4593 8.37336 15.334 8.83472 15.334C9.29607 15.334 9.60472 15.4593 9.96156 15.6045C10.4184 15.7897 10.9357 16 11.835 16C12.8644 16 14.3303 14.2193 15.1667 11.8788C15.1473 11.8738 15.1298 11.8645 15.1106 11.8592C14.2629 14.126 12.8425 15.8333 11.835 15.8333Z"
          fill="currentColor"
        />
        <path
          opacity="0.2"
          d="M11.8197 0.294787C11.6821 2.11555 10.3325 3.45075 8.50355 3.83125C8.50172 3.88894 8.50096 3.9416 8.50153 4C10.3963 3.61193 11.7706 2.08162 11.8332 0.173782C11.8274 0.204954 11.8296 0.257482 11.8197 0.294787Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5707_212">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
