import { SVGProps } from 'react';

export const Android = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 13.5C0.8625 12.1625 1.27188 10.9312 1.97813 9.80624C2.68438 8.68124 3.625 7.78749 4.8 7.12499L3.4125 4.72499C3.3375 4.61249 3.31875 4.49374 3.35625 4.36874C3.39375 4.24374 3.475 4.14999 3.6 4.08749C3.7 4.02499 3.8125 4.01249 3.9375 4.04999C4.0625 4.08749 4.1625 4.16249 4.2375 4.27499L5.625 6.67499C6.7 6.22499 7.825 5.99999 9 5.99999C10.175 5.99999 11.3 6.22499 12.375 6.67499L13.7625 4.27499C13.8375 4.16249 13.9375 4.08749 14.0625 4.04999C14.1875 4.01249 14.3 4.02499 14.4 4.08749C14.525 4.14999 14.6063 4.24374 14.6438 4.36874C14.6813 4.49374 14.6625 4.61249 14.5875 4.72499L13.2 7.12499C14.375 7.78749 15.3156 8.68124 16.0219 9.80624C16.7281 10.9312 17.1375 12.1625 17.25 13.5H0.75ZM5.25 11.4375C5.5125 11.4375 5.73438 11.3469 5.91563 11.1656C6.09688 10.9844 6.1875 10.7625 6.1875 10.5C6.1875 10.2375 6.09688 10.0156 5.91563 9.83437C5.73438 9.65312 5.5125 9.56249 5.25 9.56249C4.9875 9.56249 4.76562 9.65312 4.58438 9.83437C4.40313 10.0156 4.3125 10.2375 4.3125 10.5C4.3125 10.7625 4.40313 10.9844 4.58438 11.1656C4.76562 11.3469 4.9875 11.4375 5.25 11.4375ZM12.75 11.4375C13.0125 11.4375 13.2344 11.3469 13.4156 11.1656C13.5969 10.9844 13.6875 10.7625 13.6875 10.5C13.6875 10.2375 13.5969 10.0156 13.4156 9.83437C13.2344 9.65312 13.0125 9.56249 12.75 9.56249C12.4875 9.56249 12.2656 9.65312 12.0844 9.83437C11.9031 10.0156 11.8125 10.2375 11.8125 10.5C11.8125 10.7625 11.9031 10.9844 12.0844 11.1656C12.2656 11.3469 12.4875 11.4375 12.75 11.4375Z"
        fill="currentColor"
      />
    </svg>
  );
};
