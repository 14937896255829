import {
  FacebookLogo,
  InstagramLogo,
  XLogo,
  TiktokLogo,
  Desktop,
  DeviceMobile,
  Monitor,
  FilmStrip,
  Icon,
} from '@phosphor-icons/react';
import { ImageGenSettingsDimensionOptionTypes } from 'src/types';

export const ImageSettingsIconsSet: Record<string, Icon> = {
  [ImageGenSettingsDimensionOptionTypes.FACEBOOK]: FacebookLogo,
  [ImageGenSettingsDimensionOptionTypes.INSTAGRAM]: InstagramLogo,
  [ImageGenSettingsDimensionOptionTypes.X]: XLogo,
  [ImageGenSettingsDimensionOptionTypes.TIKTOK]: TiktokLogo,
  [ImageGenSettingsDimensionOptionTypes.DESKTOP]: Desktop,
  [ImageGenSettingsDimensionOptionTypes.MOBILE]: DeviceMobile,
  [ImageGenSettingsDimensionOptionTypes.TV]: Monitor,
  [ImageGenSettingsDimensionOptionTypes.CINEMA]: FilmStrip,
};
