import { ChangeEvent, useRef } from 'react';
import { AttachmentFileInputId } from 'src/constants';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  useAttachFile,
  useBreakpoint,
  useOutsideClick,
  useVisible,
} from 'src/hooks';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { AttachFromGoogleDriveButton } from 'src/v2/components/ThreadInputBox/components/AttachFromGoogleDriveButton';
import { AttachFileButton } from '../AttachFileButton';
import styles from './AddAttachmentButton.module.scss';

interface AddAttachmentButtonProps {
  withTitle?: boolean;
  onClose?: () => void;
}

export const AddAttachmentButton = ({
  withTitle,
  onClose,
}: AddAttachmentButtonProps) => {
  const { handleUploadedFiles } = useAttachFile();
  const { isMobile } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    isVisible: isVisibleTooltip,
    onVisibilitySet: onShowTooltip,
    onVisibilityRemove: onHideTooltip,
  } = useVisible();

  useOutsideClick(containerRef, onHideTooltip);

  const handleFileSelect = async (event: ChangeEvent | File[]) => {
    if (onClose) {
      onClose();
    }
    const files = event?.hasOwnProperty('target')
      ? ((((event as ChangeEvent).target as HTMLInputElement)?.files ||
          []) as FileList)
      : (event as File[]);
    handleUploadedFiles(files);
  };

  return (
    <div ref={containerRef} className={styles.root}>
      <AttachFileButton withTitle={withTitle} onShowTooltip={onShowTooltip} />

      {isMobile && <TextDivider />}

      <AttachFromGoogleDriveButton
        withTitle={withTitle}
        onClose={onClose}
        onShowTooltip={onShowTooltip}
      />

      <input
        id={AttachmentFileInputId}
        type="file"
        accept=".txt,.docx,.pdf,.csv,.tsv,.png,.jpg,.jpeg,.json,.log,.tab,.js,.ts,.php,.html,.shtml,.htm,.htx,.htt,.acgi,.c,.h,.cpp,.c++,.cc,.f,.f77,.f90,.for,.jav,.java,.lsp,.p,.pas,.pl,.py,.sh,.zsh,.fish,.tcl,.tch,.hs,.lhs,.go,.lua,.apng,.bmp,.dib,.drle,.dpx,.fits,.fit,.fts,.g3,.gif,.ico,.jp2,.jpg2,.jfif,.pjpeg,.pjp,.jpm,.jpgm,.svg,.svgz,.svg.gz,.tif,.tiff,.tfx,.psd,.wbmp,.xif,.pcx,.webp,.wmf,.arw,.cr2,.crw,.dcr,.dng,.erf,.k25,.kdc,.mrw,.nef,.nrw,.orf,.pef,.raf,.raw,.sr2,.srf,.x3f,.xls,.xlsx,.pptx,.zip"
        onChange={handleFileSelect}
        className={styles.attachInput}
        multiple
      />

      <ReactTooltip
        clickable
        id="attachment-tooltip"
        disableStyleInjection
        noArrow={true}
        className="nj-attachment-tooltip"
        isOpen={isVisibleTooltip}
        offset={isMobile ? 0 : 20}
        imperativeModeOnly={true}
        openOnClick
        data-e2e="attachment-tooltip"
      >
        <span>You’ll need a sign-in account to add attachment</span>
        <GuestAuthLinks dataGTM="Chat-thread-attach-file-signup" />
      </ReactTooltip>
    </div>
  );
};
