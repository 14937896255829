import { CODER } from 'src/constants/externalLinks';
import { SkillSectionWrapper } from '../SkillSectionWrapper';
import { UpcomingSettingsMessage } from '../UpcomingSettingsMessage';

export const WriterCoderSection = () => {
  return (
    <SkillSectionWrapper>
      <UpcomingSettingsMessage learnMoreLink={CODER} sectionTitle="coder" />
    </SkillSectionWrapper>
  );
};
