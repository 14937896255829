import { useSession, useUserData } from 'src/hooks';
import log from 'src/utils/logger';
import { ImageGenStyles } from 'src/types/models/ImageGenStyles';
import {
  ImageGenSettingsDimensionGroupTypes,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsDimensionResolutionTypes,
  type ImageGenSettings,
  type ImageGenSettingsSelectedDimension,
} from 'src/types';
import { ImageStyleSelect } from 'src/components/ImageGenSettings/components/ImageStyleSelect';
import { Dimensions } from 'src/components/ImageGenSettings/components/Dimensions';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './ImageSettings.module.scss';
import { ImagesPerModelToggle } from 'src/components/ImageGenSettings/components/ImagesPerModelToggle';
import { useMemo } from 'react';
import { AvailableImageGenerationModels } from 'src/constants';

const defaultDimensionState = {
  group: ImageGenSettingsDimensionGroupTypes.PRIMARY,
  option: ImageGenSettingsDimensionOptionTypes.SQUARE,
  resolution: ImageGenSettingsDimensionResolutionTypes._720P,
};

export const ImageSettings = () => {
  const {
    appUser: { settings },
    isFreeTier,
    isOpenTier,
    isStandardTier,
  } = useSession();
  const { updateUserSettings } = useUserData();

  const styleValue =
    settings?.image_gen_settings?.style?.value || ImageGenStyles.NONE;

  // TODO: duplicated code in ImageGenModifyPanel
  const imagesPerModel = useMemo(() => {
    const numberImages = (settings?.image_gen_settings?.models || []).find(
      (item) =>
        (AvailableImageGenerationModels as string[]).includes(item.model) &&
        item.enabled,
    )?.num_images;

    return numberImages || 1;
  }, [settings?.image_gen_settings?.models]);

  const updateSettings = async (newSettings: Partial<ImageGenSettings>) => {
    try {
      await updateUserSettings({
        image_gen_settings: {
          ...settings?.image_gen_settings,
          ...newSettings,
        },
      });
    } catch (e) {
      log.error(e);
    }
  };

  const handleImageStyleSelect = (value: ImageGenStyles) =>
    updateSettings({ style: { value } });

  const handleDimensionChange = (
    dimension: ImageGenSettingsSelectedDimension,
  ) =>
    updateSettings({
      dimensions: {
        ...settings?.image_gen_settings?.dimensions,
        selected: dimension,
      },
    });

  const handleResetClick = () => {
    updateSettings({
      dimensions: {
        ...settings?.image_gen_settings?.dimensions,
        selected: defaultDimensionState,
      },
      style: { value: ImageGenStyles.NONE },
    });
  };

  const handleImageNumberChange = async (value: number) => {
    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        if ((AvailableImageGenerationModels as string[]).includes(item.model)) {
          return { ...item, num_images: value };
        }
        return item;
      },
    );

    const image_gen_settings = {
      image_gen_settings: {
        ...(settings?.image_gen_settings || {}),
        models: updatedModels,
      },
    };

    try {
      await updateUserSettings(image_gen_settings);
    } catch (e) {
      // No need to reset the values in case we fall into an exception, UI is handling gracefully
      log.error(e);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {(isOpenTier || isFreeTier || isStandardTier) && (
          <UpsellMessage
            title="Customize your images"
            description="Unlock high-resolution images with 11 aspect ratios and the ability to select from multiple image styles"
            dataGTMNinjaTier="Customize-images-upgrade"
            dataGTMOpenTier="Customize-images-signup"
            buttonStyle="with-background"
            buttonLabel="Upgrade to unlock"
            className={styles.upsellMessage}
          />
        )}

        <Dimensions
          selectedData={settings?.image_gen_settings?.dimensions?.selected}
          onChange={handleDimensionChange}
        />

        <TextDivider />

        <ImageStyleSelect
          selectedValue={styleValue}
          onChange={handleImageStyleSelect}
        />

        <TextDivider />

        <ImagesPerModelToggle
          selectedValue={imagesPerModel}
          onChange={handleImageNumberChange}
        />
      </div>

      <div className={styles.resetButtonWrapper}>
        <Button
          className={styles.resetButton}
          type="button"
          onClick={handleResetClick}
          color="link"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
