import { ImageGenStyles } from 'src/types';
import { useSession } from 'src/hooks';
import { imageStyleOptions } from 'src/constants';
import styles from './ImageStyleSelect.module.scss';
import classNames from 'classnames';
import { LabelPro } from 'src/components/LabelPro';
import { Button } from 'src/v2/commonComponents/Button';

interface ImageStyleSelectProps {
  selectedValue?: ImageGenStyles;
  onChange: (value: ImageGenStyles) => void;
  maxMenuHeight?: number;
}

export const ImageStyleSelect = ({
  selectedValue = ImageGenStyles.NONE,
  onChange,
}: ImageStyleSelectProps) => {
  const { isOpenTier, isFreeTier } = useSession();

  const isDisabled = isOpenTier || isFreeTier;

  const handleChange = (newValue: ImageGenStyles) => {
    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <div className={classNames(styles.root, { [styles.disabled]: isDisabled })}>
      <label className={styles.label}>
        <span>Style</span>
        {isDisabled && <LabelPro />}
      </label>

      <div className={styles.options}>
        {imageStyleOptions.map(({ value, label }) => (
          <Button
            key={value}
            disabled={isDisabled && value !== 'none'}
            onClick={() => handleChange(value as ImageGenStyles)}
            className={classNames(styles.button, {
              [styles.active]: value === selectedValue,
            })}
          >
            <span className={styles.optionTitle}>{label}</span>
          </Button>
        ))}
      </div>
    </div>
  );
};
