import { useMemo, useCallback } from 'react';
import { usersApi } from 'src/store/services';
import {
  DEFAULT_AVATAR_ID,
  DEFAULT_AVATAR_ENVIRONMENT,
  DEFAULT_AVATAR_VOICE_ID,
  DEFAULT_LANGUAGE,
  DEFAULT_CAMERA_INDEX,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SELECTED_GENDER,
} from 'src/constants';
import {
  useGetVoicesJSONQuery,
  useGetVoiceLanguageSamplesJSONQuery,
  useGetEnvironmentsJSONQuery,
  useGetCameraThumbnailsJSONQuery,
  useGetAvatarsJSONQuery,
  useGetCommunicationTonesJSONQuery,
} from 'src/store/services';
import { hexColorToMetahumanFormat } from 'src/utils';
import { useSession } from 'src/hooks';

/**
 * @deprecated
 */
export const useAvatarPreferences = () => {
  const {
    appUser: { user_id },
  } = useSession();

  const { data: userData } = usersApi.useGetUserByIdQuery(user_id, {
    skip: !user_id,
  });

  const { data: voicesData } = useGetVoicesJSONQuery();
  const { data: voiceLanguageSamplesData } =
    useGetVoiceLanguageSamplesJSONQuery();
  const { data: environmentData } = useGetEnvironmentsJSONQuery();
  const { data: cameraAngleData } = useGetCameraThumbnailsJSONQuery();
  const { data: avatarsListData } = useGetAvatarsJSONQuery();
  const { data: communicationTonesList } = useGetCommunicationTonesJSONQuery();

  const selectedAvatarName = useMemo(
    () => userData?.persona?.avatar_id || DEFAULT_AVATAR_ID,
    [userData],
  );

  const selectedCommunicationTone = useMemo(() => {
    if (
      !!userData?.persona?.communication_tone_id &&
      (communicationTonesList || []).length > 0
    ) {
      return (communicationTonesList || []).find(
        (item) => item.id === userData?.persona?.communication_tone_id,
      );
    }
  }, [userData, communicationTonesList]);

  const selectedAvatarGender = useMemo(() => {
    if (userData?.persona?.avatar_id) {
      return (
        (avatarsListData || []).find(
          (item) => item.id === userData?.persona?.avatar_id,
        )?.gender || DEFAULT_SELECTED_GENDER
      );
    }
    return DEFAULT_SELECTED_GENDER;
  }, [userData, avatarsListData]);

  const avatarImageURL = useMemo(
    () =>
      avatarsListData?.find((avatar) => avatar.id === selectedAvatarName)?.url,
    [selectedAvatarName, avatarsListData],
  );

  const getTextSampleByLanguageCode = useCallback(
    (languageCode: string) => {
      return (
        voiceLanguageSamplesData?.find(
          (item) => item.languageCode === languageCode,
        )?.text || ''
      );
    },
    [voiceLanguageSamplesData],
  );

  const avatarBackgroundRgbColor = useMemo(
    () =>
      hexColorToMetahumanFormat(
        userData?.settings?.theme_setting?.background_hex_color ||
          DEFAULT_BACKGROUND_COLOR,
      ),
    [userData?.settings?.theme_setting?.background_hex_color],
  );

  return {
    avatarsListData: avatarsListData || [],
    communicationTonesOptions: communicationTonesList || [],
    voiceOptions: voicesData || [],
    environmentOptions: environmentData || [],
    cameraAngleOptions: cameraAngleData || [],
    voiceLanguageSampleOptions: voiceLanguageSamplesData || [],
    selectedAvatarName,
    selectedAvatarGender,
    selectedCommunicationTone,
    avatarImageURL,
    avatarCameraIndex: +(
      userData?.settings?.camera_angle_setting?.camera_index ||
      DEFAULT_CAMERA_INDEX
    ),
    avatarEnvironment:
      userData?.settings?.theme_setting?.environment ||
      DEFAULT_AVATAR_ENVIRONMENT,
    avatarBackgroundHexColor:
      userData?.settings?.theme_setting?.background_hex_color ||
      DEFAULT_BACKGROUND_COLOR,
    avatarBackgroundRgbColor,
    avatarVoiceID:
      userData?.settings?.video_language_setting?.voice_id ||
      DEFAULT_AVATAR_VOICE_ID,
    avatarAudioLocale:
      userData?.settings?.video_language_setting?.avatar_audio_locale ||
      DEFAULT_LANGUAGE,
    // TODO(olha): temporary use the same language for user and AI, do not use metadata in future
    // chatAudioLocale: userData?.chatAudioLocale || DEFAULT_LANGUAGE,
    avatarCCLocale:
      userData?.settings?.video_language_setting?.avatar_cc_locale ||
      DEFAULT_LANGUAGE,
    getTextSampleByLanguageCode,
  };
};
