import { VideoItem } from '../VideoItem';
import { useSession } from 'src/hooks';
import { offlineRecordingApi } from 'src/store/services';
import dayjs from 'dayjs';

/**
 * @deprecated
 */
export const ListOfVideosTab = () => {
  const {
    appUser: { user_id },
  } = useSession();
  const { data, isLoading, isError } =
    offlineRecordingApi.useGetAvatarVideosQuery(user_id, {
      pollingInterval: 2000,
    });

  const videoData = data?.data || [];

  const sortedVideoData = videoData
    .slice()
    .sort((a, b) =>
      dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1,
    );

  return !isLoading && !isError && videoData.length > 0 ? (
    <div className="nj-view-video-tab-content">
      {sortedVideoData.map((item) => (
        <VideoItem key={item.video_id} videoData={item} />
      ))}
    </div>
  ) : null;
};
