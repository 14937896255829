const BACKLOG_THRESHOLD = 100;
const MOST_LIKELY_FULL_MESSAGE_CONTENT = 500;
const STEP_THRESHOLD = 50;

/* Function to dynamically change render chunk size based on the current content backlog.
Initial render chunk size is one char which makes it smooth but also good baseline to not have pauses in the streaming.
As content's backlog (the content that is not rendered yet but already received from WS) grows, we gradually increase render chunk size.
After 150 chars in the backlog, we increase a chunk size by 1 for each extra 50 chars in the backlog.
E.g. backlog length to the render chunk size
Backlog length | chunk size
    0-150      | 1
    151-200    | 2
    201-250    | 3
    ...
If we got more than 500 chars, most likely we try to stream already received message. In this case we just render the whole content with one rendering chunk.
*/
export const calculateRenderingChunkSize = (
  content: string,
  displayedTextRef: React.MutableRefObject<string>,
) => {
  const diff = content.length - displayedTextRef.current.length;
  let chunkSize = 1;
  if (diff > MOST_LIKELY_FULL_MESSAGE_CONTENT) {
    chunkSize = diff;
  } else if (diff > BACKLOG_THRESHOLD) {
    chunkSize = Math.floor((diff - 1) / STEP_THRESHOLD) - 1;
  }
  return chunkSize;
};
