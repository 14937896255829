import { SVG_SIZE_M } from 'src/constants';
import { useSession, useUserData } from 'src/hooks';
import { ResearcherSettingsMediaItem, WebSearchTypes } from 'src/types';
import { SwitchSimple } from 'src/v2/commonComponents/SwitchSimple';
import styles from './MediaSettingsItem.module.scss';

export const MediaSettingsItem = ({
  value,
  label,
  icon: Icon,
}: ResearcherSettingsMediaItem) => {
  const {
    appUser: { settings },
  } = useSession();

  const { updateUserSettings } = useUserData();

  const handleSwitchResearcherMediaSetting = async (
    checked: boolean,
    value: keyof WebSearchTypes,
  ) => {
    const research = {
      research: {
        ...(settings?.research || {}),
        web_search: {
          ...(settings?.research?.web_search || {}),
          [value]: checked,
        },
      },
    };

    await updateUserSettings(research);
  };

  return (
    <div key={value} className={styles.root}>
      <p className={styles.label}>
        <Icon size={SVG_SIZE_M} />
        <span>{label}</span>
      </p>
      <SwitchSimple
        isChecked={!!settings?.research?.web_search?.[value]}
        isDisabled={!settings?.research?.web_search?.is_capable}
        onChange={(checked: boolean) =>
          handleSwitchResearcherMediaSetting(
            checked,
            value as unknown as keyof WebSearchTypes,
          )
        }
      />
    </div>
  );
};
