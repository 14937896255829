import { SkillSectionWrapper } from '../SkillSectionWrapper';
import { ImageSettings } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ImageSettings';

export const ImageGenSection = () => {
  return (
    <SkillSectionWrapper hasSettingsTab>
      <ImageSettings />
    </SkillSectionWrapper>
  );
};
