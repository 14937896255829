import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import GoogleApiContext from 'src/contexts/GoogleApiContext';
import { useGetGoogleTokenQuery } from 'src/store/services';
import { useSession } from 'src/hooks/useSession';
import { useGoogleResourcesAccess } from 'src/hooks/useGoogleResourcesAccess';
import { env } from 'src/env';
import { googleDocsToFiles } from 'src/utils/googleDrive';
import log from 'loglevel';
import { MAX_NUMBER_OF_FILES } from 'src/constants';

export interface UseGoogleDrivePickerOptions {
  callback: (result: File[]) => void;
}

export const useGoogleDrivePicker = ({
  callback,
}: UseGoogleDrivePickerOptions) => {
  const {
    loadedApis: { drivePicker, gapi: gapiLoaded },
  } = useContext(GoogleApiContext);
  const {
    appUser: { user_id },
  } = useSession();
  const { canAccessGoogleDrive, currentProvider } = useGoogleResourcesAccess();

  const [picker, setPicker] = useState<google.picker.Picker>();
  const [isReady, setIsReady] = useState<boolean>(false);

  const { data, isFetching } = useGetGoogleTokenQuery(
    { userId: user_id, email: currentProvider?.email as string },
    {
      skip: !canAccessGoogleDrive || !currentProvider?.email,
    },
  );

  useEffect(() => {
    if (picker && data && !isFetching && gapiLoaded) {
      if (gapi.client.getToken()?.access_token !== data.access_token) {
        gapi.client.setToken({ access_token: data.access_token });
      }
      setIsReady(true);
    }
  }, [picker, data, gapiLoaded, isFetching]);

  const showGooglePicker = useCallback(() => {
    if (isReady && picker) {
      picker.setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const onCallback = useCallback(
    (payload: google.picker.ResponseObject) => {
      if (payload?.docs && payload?.action === google.picker.Action.PICKED) {
        googleDocsToFiles(payload?.docs)
          .then((result?: File[]) => {
            if (result) {
              callback(result);
            }
          })
          .catch((error) => {
            log.error('Failed to get files from Picker', error);
          });
      }
    },
    [callback],
  );

  useEffect(() => {
    if (
      data?.access_token &&
      env.REACT_APP_GOOGLE_CLIENT_ID &&
      env.REACT_APP_GOOGLE_API_KEY &&
      drivePicker &&
      !picker
    ) {
      const picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.DOCS)
        .setOAuthToken(data?.access_token)
        .setDeveloperKey(env.REACT_APP_GOOGLE_API_KEY)
        .setCallback(onCallback)
        .setAppId(env.REACT_APP_GOOGLE_CLIENT_ID.split('-')[0])
        .setOrigin(env.REACT_APP_NINJA_UI_URL)
        .setMaxItems(MAX_NUMBER_OF_FILES)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .build();
      setPicker(picker);
    }
  }, [drivePicker, picker, data?.access_token, onCallback]);

  return useMemo(
    () => ({ showGooglePicker, isReady }),
    [showGooglePicker, isReady],
  );
};
