import styles from './Components.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { AmpOnboardingPageStep } from 'src/v2/pages/auth/AmpOnboardingPage/common';
import { useQueryParams } from 'src/hooks';
import { EmailSubmitForm } from 'src/v2/pages/auth/components/EmailSubmitForm';
import {
  AmpOnboardingFailureReason,
  PreOnboardingResponse,
  useOnboardMutation,
} from 'src/store/services';
import { isTeamsTier } from 'src/utils/user';
import { UserTier } from 'src/types/models/UserTier';
import { InputError } from 'src/components/Tooltip';

interface OnboardEmailSectionProps {
  setStep: (step: AmpOnboardingPageStep) => void;
  preOnboardData?: PreOnboardingResponse;
}

export const OnboardEmailSection = ({
  setStep,
  preOnboardData,
}: OnboardEmailSectionProps) => {
  const { searchParams } = useQueryParams();
  const { amp_customer_id, amp_product_code } = searchParams;
  const [onboardingError, setOnboardingError] = useState('');
  const [completeOnboarding] = useOnboardMutation();

  useEffect(() => {
    if (preOnboardData) {
      routePreOnboardingResponse(setStep, preOnboardData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preOnboardData]);

  const completeOnboardingCallback = useCallback(
    async (email: string) => {
      setOnboardingError('');
      if (amp_customer_id && amp_product_code) {
        try {
          const res = await completeOnboarding({
            amp_customer_id,
            amp_product_code,
            email,
          }).unwrap();
          if (res.success) {
            setStep(AmpOnboardingPageStep.SIGN_UP);
          }
          if (!res.success) {
            if (
              res.failure_reason === AmpOnboardingFailureReason.GENERAL ||
              !res.failure_reason
            ) {
              setStep(AmpOnboardingPageStep.CONTACT_SUPPORT);
            } else if (
              [
                AmpOnboardingFailureReason.ON_APPLE_INDIVIDUAL_PLAN,
                AmpOnboardingFailureReason.ON_ANOTHER_TEAMS_PLAN,
              ].includes(res.failure_reason)
            ) {
              setStep(AmpOnboardingPageStep.EXISTING_PLAN);
            }
          }
        } catch (error) {
          setStep(AmpOnboardingPageStep.CONTACT_SUPPORT);
        }
      }
    },
    [completeOnboarding, amp_customer_id, amp_product_code, setStep],
  );

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>Thank you for purchasing Ninja!</h2>
      <p>
        Setup your Business plan by providing the owner’s email address and
        workspace name.
      </p>
      <EmailSubmitForm
        onSubmit={completeOnboardingCallback}
        buttonText="Continue"
      />
      {onboardingError && (
        <InputError title={onboardingError} renderType="inline" />
      )}
    </div>
  );
};

const routePreOnboardingResponse = (
  setStep: (step: AmpOnboardingPageStep) => void,
  data: PreOnboardingResponse,
) => {
  if (!data.success) {
    setStep(AmpOnboardingPageStep.CONTACT_SUPPORT);
  }
  if (!data.user_exists) {
    return;
  }
  // Subscription event may come minutes after the actual subscription. In this case User's Tier can still be Free.
  if (isTeamsTier(data.user_tier) || data.user_tier === UserTier.FREE) {
    if (data.user_signed_up) {
      setStep(AmpOnboardingPageStep.SIGN_IN);
    } else {
      setStep(AmpOnboardingPageStep.SIGN_UP);
    }
  } else {
    setStep(AmpOnboardingPageStep.EXISTING_PLAN);
  }
};
