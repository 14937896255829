import { Check, Copy } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { SVG_SIZE_M, SVG_SIZE_L } from 'src/constants';
import { useCurrentMessage, useExternalModels } from 'src/hooks';
import { Message, MessageType } from 'src/types';
import {
  getResearchCopyTemplate,
  getSchedulerCopyTemplate,
  getChatCardCopyTemplate,
} from 'src/utils';
import { Button } from 'src/v2/commonComponents/Button';

type CopyMessageButtonProps = {
  messageId?: string;
  disabled?: boolean;
};

export const CopyMessageButton = ({
  messageId,
  disabled,
}: CopyMessageButtonProps) => {
  const { currentMessage } = useCurrentMessage(messageId);

  const { textExternalModels } = useExternalModels();

  const getCopyString = (item: Message) => {
    switch (item.message_type) {
      case MessageType.CONVERSATION:
      case MessageType.TASK_CREATED:
        return item.content.trim();
      case MessageType.CODE_TASK_CREATION_CARD:
        return getChatCardCopyTemplate(item, textExternalModels);
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return getResearchCopyTemplate(item, textExternalModels);
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return getSchedulerCopyTemplate(item);
      case MessageType.CHAT_CARD:
        return getChatCardCopyTemplate(item, textExternalModels);

      default:
        // since all cards will have content at least
        // providing a default case, otherwise, each
        // copyable card type should have a template.
        return item.content.trim();
    }
  };

  const handleCopy = () => {
    if (!currentMessage) {
      return null;
    }
    navigator.clipboard.writeText(getCopyString(currentMessage));
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={SVG_SIZE_L} />
        <span>Copied</span>
      </div>,
      {
        className: 'nj-copy-snackbar',
      },
    );
  };

  return (
    <Button
      color="transparent"
      onClick={handleCopy}
      disabled={disabled || !currentMessage}
      data-e2e="copy-button"
    >
      <Copy size={SVG_SIZE_M} color="currentColor" />
    </Button>
  );
};
