import { useMemo } from 'react';
import { ResearchCard, MessageTabs, MessageTabData, GTMEvent } from 'src/types';
import { Tabs, TabPanel } from 'src/v2/commonComponents/Tabs';
import { prepareExternalModelsTabContent, sendGTMEvent } from 'src/utils';
import { renderMessageTabList } from '../MessageTabList';
import styles from './ThreadResearchCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { ThreadResearchReferences } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchReferences';
import { ThreadResearchMedia } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchMedia';
import { SuperAgentUpsell } from '../SuperAgentUpsell';
import {
  useMessageTab,
  useSession,
  useExternalModels,
  useMessageSwitchTabs,
} from 'src/hooks';
import { FeedbackRating } from 'src/v2/components/ThreadMessage/components/FeedbackRating';
import { NINJA_AGENT_TITLE } from 'src/constants';
import { SeeSuperAgentAnswerButton } from '../SeeSuperAgentAnswerButton';
import { SuperAgentIsWorkingHardText } from '../SuperAgentIsWorkingHardText';
import { IconName } from 'src/components/Icon';

interface ThreadResearchCardProps {
  messageId?: string;
  researchCard?: ResearchCard;
  isStreaming?: boolean;
  taskId?: string;
}

export const ThreadResearchCard = ({
  messageId,
  researchCard,
  isStreaming = false,
  taskId,
}: ThreadResearchCardProps) => {
  const {
    appUser: { settings },
  } = useSession();
  const { textExternalModels } = useExternalModels();

  const { search_result, deep_research_result, generative_ai_result } =
    researchCard?.data || {};
  const { referenced_model_summary } = generative_ai_result || {};
  const { references: external_models_result } = referenced_model_summary || {};
  const { web_page_references, image_references, video_references } =
    search_result || {};

  const withSuperAgent = useMemo(
    () => !!deep_research_result,
    [deep_research_result],
  );

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(search_result
        ? [
            {
              tabName: MessageTabs.NINJA_AGENT,
              iconName: 'ninjaOutlined' as IconName,
              tabTitle: NINJA_AGENT_TITLE,
              content: search_result.summary || '',
              references: web_page_references,
            },
          ]
        : []),
      ...(withSuperAgent || !settings?.super_agent?.is_capable
        ? [
            {
              tabName: MessageTabs.SUPER_AGENT,
              iconName: 'ninjaPixProLogo' as IconName,
              tabTitle: 'Ninja SuperAgent',
              content: deep_research_result?.summary || '',
              references: deep_research_result?.web_page_references,
            },
          ]
        : []),
      ...(external_models_result
        ? prepareExternalModelsTabContent(
            external_models_result,
            textExternalModels,
          )
        : []),
    ],
    [
      search_result,
      web_page_references,
      deep_research_result,
      external_models_result,
      withSuperAgent,
      settings?.super_agent?.is_capable,
      textExternalModels,
    ],
  );

  const { getTabSRegistry, onTabClick } = useMessageTab({
    messageId,
    tabsData,
  });

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    onTabClick(tabName);

    if (
      tabName === MessageTabs.SUPER_AGENT &&
      !settings?.super_agent?.is_capable
    ) {
      sendGTMEvent(GTMEvent.SUPERAGENT_UPSELL_TAB_CLICK);
      return;
    }

    if (withSuperAgent && tabName === MessageTabs.SUPER_AGENT) {
      deep_research_result?.summary
        ? sendGTMEvent(GTMEvent.SUPERAGENT_COMPLETE_CLICK)
        : sendGTMEvent(GTMEvent.SUPERAGENT_IN_PROGRESS_CLICK);
    }
  };

  const { activeTab, setActiveTab, handleSeeSuperAgentAnswerButton } =
    useMessageSwitchTabs({ taskId, handleTabClick });

  return (
    <div className={styles.root}>
      <Tabs<string> defaultActiveTab={activeTab} onTabClick={handleTabClick}>
        {renderMessageTabList({
          data: tabsData,
          withSuperAgent,
          tabRegistry: getTabSRegistry(),
        })}

        {tabsData.map(
          ({ tabName, content, references, tabTitle, reasoning }) => (
            <TabPanel key={tabName} tab={tabName}>
              {content.length === 0 && !reasoning ? (
                <>
                  {tabName === MessageTabs.SUPER_AGENT &&
                    !!deep_research_result && (
                      <SuperAgentIsWorkingHardText taskId={taskId} />
                    )}

                  {(tabName !== MessageTabs.SUPER_AGENT ||
                    !!deep_research_result) && (
                    <ProgressBar
                      count={3}
                      withColor={tabName === MessageTabs.SUPER_AGENT}
                    />
                  )}

                  {tabName === MessageTabs.SUPER_AGENT && (
                    <SuperAgentUpsell description="An advanced research agent that dives deep into your questions, leveraging real-time web data and relevant sources to deliver more accurate and richer answers." />
                  )}
                </>
              ) : (
                <div className={styles.container}>
                  <ThreadMessageContent
                    content={content}
                    isStreaming={isStreaming}
                    references={references}
                    reasoning={reasoning}
                  />

                  {references && (
                    <ThreadResearchReferences researchReferences={references} />
                  )}

                  {tabName === MessageTabs.NINJA_AGENT && (
                    <ThreadResearchMedia
                      images={image_references || []}
                      videos={video_references || []}
                    />
                  )}

                  {tabName === MessageTabs.NINJA_AGENT && withSuperAgent && (
                    <SeeSuperAgentAnswerButton
                      onClick={handleSeeSuperAgentAnswerButton}
                    />
                  )}

                  <FeedbackRating
                    title={`Rate ${tabTitle} answer`}
                    messageId={messageId}
                    isStreaming={isStreaming}
                    operationType="models"
                    modelId={tabName}
                  />
                </div>
              )}
            </TabPanel>
          ),
        )}
      </Tabs>
    </div>
  );
};
