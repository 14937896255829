import classNames from 'classnames';
import { StreamingText } from 'src/components/StreamingText';
import { Citation } from 'src/types';
import { ThinkingSection } from 'src/v2/components/ThinkingSection';
import { CotReasoning } from 'src/types/models/CotReasoning';

interface ThreadMessageContentProps {
  content: string;
  messageId?: string;
  className?: string;
  isStreaming?: boolean;
  references?: Citation[];
  reasoning?: CotReasoning;
}

export const ThreadMessageContent = ({
  content,
  messageId,
  className,
  isStreaming,
  references,
  reasoning,
}: ThreadMessageContentProps) => {
  if (!content && !reasoning) {
    return null;
  }

  return (
    <div className={classNames('nj-thread-message--content', className)}>
      {!!reasoning && (
        <ThinkingSection
          reasoning={reasoning}
          defaultExpanded={!content}
          isDone={!!content}
        />
      )}
      {content && (
        <StreamingText
          messageId={messageId}
          isStreaming={isStreaming}
          content={content}
          references={references}
        />
      )}
    </div>
  );
};
