import { useContext } from 'react';
import classNames from 'classnames';
import SessionContext from 'src/contexts/SessionContext';
import { TaskStatusFilter } from 'src/types';
import type { FilterControlItem } from '../ConversationControl';

interface ControlPanelItemProps extends FilterControlItem {
  activeFilter?: TaskStatusFilter;
  svgSize?: number;
  onClick: (chatsFilter: TaskStatusFilter) => void;
}

export const ControlPanelItem = ({
  activeFilter,
  controlItem,
  Icon,
  Component,
  withDivider,
  svgSize = 20,
  isTitle,
  onClick,
}: ControlPanelItemProps) => {
  const { onCloseTaskListControlsPanel } = useContext(SessionContext);

  const handleClick = () => {
    onClick(controlItem);
    onCloseTaskListControlsPanel();
  };

  return (
    <>
      <button
        className={classNames(
          'button-with-flex',
          'nj-mobile-action-panel--button',
          {
            selected: activeFilter === controlItem,
          },
        )}
        onClick={handleClick}
      >
        {Icon && <Icon size={svgSize} />}
        {Component && Component}

        {isTitle ? (
          <span className="nj-mobile-action-panel--title">{controlItem}</span>
        ) : (
          <span className="nj-mobile-action-panel--subtitle">
            {controlItem}
          </span>
        )}
      </button>

      {withDivider && <hr className="divider" />}
    </>
  );
};
