import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'react-use';
import { Dimensions } from 'src/components/ImageGenSettings/components/Dimensions';
import {
  AppRoutes,
  ImageGenSettings,
  ImageGenSettingsSelectedDimension,
  ImageGenStyles,
} from 'src/types';
import { toast } from 'react-toastify';
import { ImageStyleSelect } from 'src/components/ImageGenSettings/components/ImageStyleSelect';
import styles from './ImageGenModifyPanel.module.scss';
import { useSession, useSubmitUserInput, useUserData } from 'src/hooks';
import {
  AvailableImageGenerationModels,
  COMMON_ERROR_TEXT,
  ImageGenerationModelOptions,
  ImageGenerationModels,
} from 'src/constants';
import { ImagesPerModelToggle } from 'src/components/ImageGenSettings/components/ImagesPerModelToggle';
import { useDeleteAttachment } from 'src/hooks';
import { TextRequestPayload } from 'src/types/models/TextRequestPayload';
import { useRightSidePanelState } from 'src/hooks';

type ImageGenModifyPanelProps = {
  isOpen?: boolean;
  onClose: () => void;
  messageOriginalQuery?: string;
  messageCleanQuery?: string;
};

type ModifiedData = {
  dimensionValues?: ImageGenSettingsSelectedDimension;
  imageStyle?: ImageGenStyles;
  imageModel?: ImageGenerationModelOptions;
  imagesPerModel?: number;
};

export const ImageGenModifyPanel = ({
  isOpen,
  onClose,
  messageOriginalQuery = '',
  messageCleanQuery = '',
}: ImageGenModifyPanelProps) => {
  const navigate = useNavigate();
  const [isRegenerateLoading, setIsRegenerateLoading] =
    useState<boolean>(false);

  const {
    appUser: { settings },
    isOpenTier,
    isFreeTier,
  } = useSession();

  const { updateUserSettings } = useUserData();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { onDeleteAllAttachments } = useDeleteAttachment();
  const { isRightPanelExpanded } = useRightSidePanelState();

  const dimensionValues = settings?.image_gen_settings?.dimensions?.selected;
  const imageStyle = settings?.image_gen_settings?.style?.value;

  // TODO: duplicated code in ImageSettings.tsx
  const imagesPerModel = useMemo(() => {
    const numberImages = (settings?.image_gen_settings?.models || []).find(
      (item) =>
        (AvailableImageGenerationModels as string[]).includes(item.model) &&
        item.enabled,
    )?.num_images;

    return numberImages || 1;
  }, [settings?.image_gen_settings?.models]);

  const defaultSettings = {
    dimensionValues,
    imageStyle,
    imagesPerModel,
  };

  const [imageSettings, setImageSettings] =
    useSetState<ModifiedData>(defaultSettings);

  useEffect(() => {
    if (!isOpen) {
      setImageSettings(defaultSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setImageSettings({ dimensionValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionValues]);

  useEffect(() => {
    setImageSettings({ imageStyle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageStyle]);

  useEffect(() => {
    setImageSettings({ imagesPerModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesPerModel]);

  const handleDimensionChange = (
    dimensionValues: ImageGenSettingsSelectedDimension,
  ) => {
    setImageSettings({ dimensionValues });
  };

  const handleStyleChange = (imageStyle: ImageGenStyles) => {
    setImageSettings({ imageStyle });
  };

  const handleImagesPerModelChange = async (imagesPerModel: number) => {
    setImageSettings({ imagesPerModel });
  };

  const handleSettingsUpdate = async () => {
    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        return Object.values(ImageGenerationModels).includes(
          item.model as ImageGenerationModels,
        )
          ? {
              ...item,
              num_images: imageSettings.imagesPerModel,
            }
          : item;
      },
    );

    const image_gen_settings: ImageGenSettings = {
      ...(settings?.image_gen_settings || {}),
      style: {
        value: imageSettings.imageStyle,
      },
      dimensions: {
        ...(settings?.image_gen_settings?.dimensions || {}),
        selected: imageSettings.dimensionValues,
      },
      models: updatedModels,
    };

    await updateUserSettings({ image_gen_settings });
  };

  const handleRegenerateClick = async () => {
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
      return;
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
      return;
    }

    try {
      setIsRegenerateLoading(true);
      await onDeleteAllAttachments();
      const value = `/image ${
        messageCleanQuery || messageOriginalQuery
      }`.trim();

      await handleSettingsUpdate();

      onSubmitUserInput(value, {
        content: value,
        payload_list: [
          {
            payload_type: TextRequestPayload.payload_type.TEXT,
            content: value,
          },
        ],
      });
    } catch (e) {
      setImageSettings(defaultSettings);
      toast(COMMON_ERROR_TEXT);
    } finally {
      setIsRegenerateLoading(false);
      onClose();
    }
  };

  const submitTitle = useMemo(() => {
    if (isOpenTier) {
      return 'Sign up to unlock';
    }
    if (isFreeTier) {
      return 'Upgrade to unlock';
    }
    return 'Regenerate';
  }, [isOpenTier, isFreeTier]);

  const buttonId = isFreeTier
    ? 'Image-modify-upgrade'
    : isOpenTier
      ? 'Image-modify-signup'
      : '';

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.content, {
          [styles.mobileView]: isRightPanelExpanded,
        })}
      >
        <div className={styles.field}>
          <Dimensions
            onChange={handleDimensionChange}
            selectedData={imageSettings.dimensionValues}
            isVisible={isOpen}
          />

          <hr className="divider" />

          <ImageStyleSelect
            onChange={handleStyleChange}
            selectedValue={imageSettings.imageStyle}
            maxMenuHeight={200}
          />

          <ImagesPerModelToggle
            selectedValue={imageSettings.imagesPerModel}
            onChange={handleImagesPerModelChange}
          />
        </div>
        <button
          className={styles.regenerateButton}
          type="button"
          onClick={handleRegenerateClick}
          id={buttonId}
          disabled={isRegenerateLoading}
        >
          {submitTitle}
        </button>
      </div>
    </div>
  );
};
