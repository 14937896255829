interface LabelBusinessProps {
  labelType?: 'short' | 'full';
}

export const LabelBusiness = ({ labelType = 'full' }: LabelBusinessProps) => {
  return (
    <label className="nj-label-ultra" data-e2e="label-tier-teams-ultra">
      {labelType === 'short' ? 'Business' : 'Business subscription'}
    </label>
  );
};
