import {
  QuestionMark,
  Lightbulb,
  Headset,
  ArrowSquareOut,
} from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import {
  useBreakpoint,
  useLeftPanelState,
  useOutsideClick,
  useSession,
  useVisible,
} from 'src/hooks';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import styles from './CustomZendeskLauncher.module.scss';
import { useContext, useRef } from 'react';
import { HELP_CENTER_LINK } from 'src/constants/externalLinks';
import SessionContext from 'src/contexts/SessionContext';

const id = 'custom-zendesk-launcher';

export const CustomZendeskLauncher = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { onChangeIsOpenSupportModal } = useContext(SessionContext);

  const { isMobileOrTablet, isDesktopXLAndDown } = useBreakpoint();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const { isVisible, onVisibilityToggle, onVisibilityRemove } = useVisible();
  const { isOpenTier } = useSession();

  const handleHelpCenterClick = () => {
    window.open(HELP_CENTER_LINK, '_blank', 'noopener,noreferrer');
    onVisibilityRemove();
  };

  useOutsideClick(containerRef, onVisibilityRemove);

  const handleGetHelpClick = () => {
    onChangeIsOpenSupportModal(true);
    onVisibilityRemove();
  };

  if (isMobileOrTablet || (isLeftPanelExpanded && isDesktopXLAndDown)) {
    return null;
  }

  return (
    <div ref={containerRef}>
      <ReactTooltip
        place="top-end"
        id={id}
        isOpen={isVisible}
        className={styles.tooltip}
        offset={8}
      >
        <div>
          <Button
            onClick={handleHelpCenterClick}
            className={styles.menuItem}
            color="transparent"
            fullWidth
          >
            <Lightbulb size={SVG_SIZE_M} color="currentColor" />
            Help center
            <ArrowSquareOut size={SVG_SIZE_M} color="currentColor" />
          </Button>

          <Button
            onClick={handleGetHelpClick}
            className={styles.menuItem}
            color="transparent"
            disabled={isOpenTier}
            fullWidth
          >
            <Headset size={SVG_SIZE_M} color="currentColor" /> Get help
          </Button>
        </div>
      </ReactTooltip>

      <Button
        id={id}
        onClick={onVisibilityToggle}
        className={styles.supportButton}
        color="transparent"
        shape="regular"
      >
        <QuestionMark size={SVG_SIZE_M} />
        <span>Support</span>
      </Button>
    </div>
  );
};
