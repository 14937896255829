import classNames from 'classnames';
import { DeviceMobile } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { SVG_SIZE_M } from 'src/constants';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { useBreakpoint, useVisible } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import styles from './DownloadAppsButton.module.scss';

const TOOLTIP_ANCHOR_ID = 'download-apps-trigger';

export const DownloadAppsButton = () => {
  const { isDesktopAndUp } = useBreakpoint();
  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible(false);

  if (!isDesktopAndUp) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ReactTooltip
        id={TOOLTIP_ANCHOR_ID}
        isOpen={isVisible}
        place="top-end"
        className={styles.tooltip}
      >
        <div>
          <Icon type="downloadAppsQr" size={168} />
          <p className={styles.caption}>Scan to get the Ninja mobile app</p>
        </div>
      </ReactTooltip>

      <Button
        id={TOOLTIP_ANCHOR_ID}
        data-e2e={TOOLTIP_ANCHOR_ID}
        color="transparent"
        shape="round"
        className={classNames(styles.button, {
          [styles.active]: isVisible,
        })}
        onMouseEnter={onVisibilitySet}
        onMouseLeave={onVisibilityRemove}
      >
        <DeviceMobile size={SVG_SIZE_M} />
      </Button>
    </div>
  );
};
