interface LabelTeamsProProps {
  labelType?: 'short' | 'full';
}

/**
 * @deprecated
 */
export const LabelTeamsPro = ({ labelType = 'full' }: LabelTeamsProProps) => {
  return (
    <label className="nj-label-pro" data-e2e="label-tier-teams-pro">
      {labelType === 'short' ? 'Business Pro' : 'Business Pro subscription'}
    </label>
  );
};
