import { useCallback, useRef, useEffect, useState } from 'react';
import { RefreshButton } from '../RefreshButton';
import { PillarsBlendTile } from '../PillarsBlendTile';
import fastdom from 'fastdom';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { useRecommendedSkills } from './RecommendedSkills';
import React from 'react';
import { useQueryParams, useSession, useVisible } from 'src/hooks';
import { VideoGenUpsellModal } from '../VideoGenUpsellModal';

export const PillarsBlend = React.memo(() => {
  const { searchParams, removeSearchParam } = useQueryParams();
  const [skillsUpdateTrigger, setSkillsUpdateTrigger] = useState<number>(0);
  const pillardsBlendRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<gsap.core.Timeline | null>(null);
  const skills = useRecommendedSkills(skillsUpdateTrigger) || [];

  const { isFreeTier, isSubscribedTier } = useSession();
  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible();

  useGSAP(
    () => {
      if (pillardsBlendRef.current instanceof HTMLDivElement) {
        timelineRef.current = gsap.timeline({
          paused: true,
        });

        timelineRef.current
          .addLabel('start')
          .set('.nj-random-pillars--blend-tile', {
            opacity: 0,
          })
          .addLabel('blend tiles stagger and fade in')
          .to('.nj-random-pillars--blend-tile', {
            duration: 0.7,
            opacity: 1,
            ease: 'cubic-bezier(0.28, 0.82, 0.45, 0.96)',
          });

        fastdom.mutate(() => {
          timelineRef.current?.play();
        });
      }

      return () => {
        timelineRef.current?.kill();
      };
    },
    { dependencies: [pillardsBlendRef.current], scope: pillardsBlendRef },
  );

  // TODO(olha): looks like we don't use recommendation anymore
  const removeRecommendationParam = useCallback(() => {
    if (!!searchParams.recommendation) {
      removeSearchParam('recommendation');
    }
  }, [searchParams, removeSearchParam]);

  const handleReloadClick = useCallback(() => {
    timelineRef.current?.restart();
    removeRecommendationParam();
    setSkillsUpdateTrigger((prev) => prev + 1);
  }, [removeRecommendationParam]);

  useEffect(() => {
    const handlePillarsRefresh = (e: Event) => {
      handleReloadClick();
    };

    window.addEventListener('refresh-pillars', handlePillarsRefresh);

    return () => {
      window.removeEventListener('refresh-pillars', handlePillarsRefresh);
    };
  }, [handleReloadClick]);

  useEffect(() => {
    if (isFreeTier || isSubscribedTier) {
      removeRecommendationParam();
    }
  }, [isFreeTier, isSubscribedTier, removeRecommendationParam]);

  return (
    <div className="nj-random-pillars" ref={pillardsBlendRef}>
      <div className="nj-random-pillars--blend--body">
        {skills.map((skill, index) => {
          return (
            <PillarsBlendTile
              key={index}
              skill={skill.agent || ''}
              template={{
                displayMessage: skill.displayMessage,
                inputText: skill.inputText,
                command: skill.command,
              }}
              onClick={removeRecommendationParam}
              onShowUpsell={onVisibilitySet}
            />
          );
        })}
      </div>
      <div className="nj-random-pillars--blend--footer">
        <RefreshButton onClick={handleReloadClick} label="New examples" />
      </div>

      <VideoGenUpsellModal open={isVisible} onClose={onVisibilityRemove} />
    </div>
  );
});
