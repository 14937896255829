import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  BREAKPOINT_TABLET,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_ULTRA_WIDE,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_LAPTOP_L,
  BREAKPOINT_DESKTOP_L,
  BREAKPOINT_DESKTOP_XL,
  CUSTOM_BREAKPOINT_DESKTOP_XL_AVATAR,
  CUSTOM_BREAKPOINT_DESKTOP_SMALL_WITH_LEFT_PANEL,
} from 'src/constants';

type Breakpoints =
  | 'isUltraWideScreen'
  | 'isDesktop'
  | 'isTablet'
  | 'isMobile'
  | 'isMobileOrTablet'
  | 'isTabletAndUp'
  | 'isDesktopAndUp'
  | 'isLaptopAndUp'
  | 'isLaptopLAndUp'
  | 'isDesktopLAndUp'
  | 'isDesktopXLAndUp'
  | 'isDesktopXLAvatarAndUp'
  | 'isDesktopSmallChat'
  | 'isDesktopXLAndDown';

type UseBreakpoint = Record<Breakpoints, boolean>;

export const useBreakpoint = (): UseBreakpoint => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_TABLET - 1 });
  const isMobileOrTablet = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP - 1 });
  const isTablet = useMediaQuery({
    minWidth: BREAKPOINT_TABLET,
    maxWidth: BREAKPOINT_DESKTOP - 1,
  });
  const isDesktop = useMediaQuery({
    minWidth: BREAKPOINT_DESKTOP,
    maxWidth: BREAKPOINT_ULTRA_WIDE - 1,
  });
  const isUltraWideScreen = useMediaQuery({ minWidth: BREAKPOINT_ULTRA_WIDE });
  const isTabletAndUp = useMediaQuery({ minWidth: BREAKPOINT_TABLET });
  const isLaptopAndUp = useMediaQuery({ minWidth: BREAKPOINT_LAPTOP });
  const isLaptopLAndUp = useMediaQuery({ minWidth: BREAKPOINT_LAPTOP_L });
  const isDesktopAndUp = useMediaQuery({ minWidth: BREAKPOINT_DESKTOP });
  const isDesktopLAndUp = useMediaQuery({ minWidth: BREAKPOINT_DESKTOP_L });
  const isDesktopXLAndUp = useMediaQuery({ minWidth: BREAKPOINT_DESKTOP_XL });
  const isDesktopXLAndDown = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_XL });
  const isDesktopXLAvatarAndUp = useMediaQuery({
    minWidth: CUSTOM_BREAKPOINT_DESKTOP_XL_AVATAR,
  });
  const isDesktopSmallChat = useMediaQuery({
    minWidth: BREAKPOINT_DESKTOP,
    maxWidth: CUSTOM_BREAKPOINT_DESKTOP_SMALL_WITH_LEFT_PANEL,
  });

  return useMemo(
    () => ({
      isMobile,
      isMobileOrTablet,
      isTablet,
      isDesktop,
      isUltraWideScreen,
      isTabletAndUp,
      isLaptopAndUp,
      isLaptopLAndUp,
      isDesktopAndUp,
      isDesktopLAndUp,
      isDesktopXLAndUp,
      isDesktopXLAvatarAndUp,
      isDesktopSmallChat,
      isDesktopXLAndDown,
    }),
    [
      isMobile,
      isMobileOrTablet,
      isTablet,
      isDesktop,
      isUltraWideScreen,
      isTabletAndUp,
      isLaptopAndUp,
      isLaptopLAndUp,
      isDesktopAndUp,
      isDesktopLAndUp,
      isDesktopXLAndUp,
      isDesktopXLAvatarAndUp,
      isDesktopSmallChat,
      isDesktopXLAndDown,
    ],
  );
};
