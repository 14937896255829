import { useMemo } from 'react';
import {
  useMessageTab,
  useSession,
  useExternalModels,
  useMessageSwitchTabs,
  useTeamInfo,
} from 'src/hooks';
import {
  ChitChatCard,
  TaskSkill,
  MessageTabs,
  MessageTabData,
  GTMEvent,
  AiGenerationStatus,
} from 'src/types';
import { prepareExternalModelsTabContent, sendGTMEvent } from 'src/utils';
import { Tabs, TabPanel } from 'src/v2/commonComponents/Tabs';
import { renderMessageTabList } from '../MessageTabList';
import styles from './ThreadChitChatCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { SuperAgentUpsell } from '../SuperAgentUpsell';
import {
  NINJA_AGENT_TITLE,
  SUPER_AGENT_AS_EXTERNAL_MODEL,
} from 'src/constants';
import { FeedbackRating } from 'src/v2/components/ThreadMessage/components/FeedbackRating';
import { SeeSuperAgentAnswerButton } from '../SeeSuperAgentAnswerButton';
import { SuperAgentIsWorkingHardText } from '../SuperAgentIsWorkingHardText';
import { IconName } from 'src/components/Icon';

interface ThreadChatCardProps {
  messageId?: string;
  chitChatCard: ChitChatCard;
  isStreaming?: boolean;
  taskId?: string;
}

const TEXT_MAX_SIZE = 150;

export const ThreadChitChatCard = ({
  messageId,
  chitChatCard,
  isStreaming = false,
  taskId,
}: ThreadChatCardProps) => {
  const {
    isLimitedTier,
    isTeamsStandardTier,
    appUser: { settings },
  } = useSession();

  const { isOwner } = useTeamInfo();

  const { textExternalModels } = useExternalModels();

  const { data } = chitChatCard;
  const { ninja_model_output, referenced_model_summary, skill } = data || {};

  const { references: external_models_result } = referenced_model_summary || {};

  const tabContent = useMemo(
    () =>
      external_models_result
        ? prepareExternalModelsTabContent(
            external_models_result,
            textExternalModels,
          )
        : [],
    [external_models_result, textExternalModels],
  );

  const superAgentTabData = useMemo(
    () =>
      tabContent.find(({ tabName }) =>
        tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL),
      ),
    [tabContent],
  );

  const withSuperAgent = useMemo(
    () => !!superAgentTabData,
    [superAgentTabData],
  );

  const tabsData: MessageTabData[] = useMemo(
    () => [
      ...(ninja_model_output
        ? [
            {
              tabName: MessageTabs.NINJA_AGENT,
              iconName: 'ninjaOutlined' as IconName,
              tabTitle: NINJA_AGENT_TITLE,
              content: ninja_model_output || '',
              status:
                tabContent.some(
                  ({ status }) => status === AiGenerationStatus.SUCCESS,
                ) || isStreaming === false
                  ? AiGenerationStatus.SUCCESS
                  : undefined,
            },
          ]
        : []),
      ...(!withSuperAgent && !settings?.super_agent?.is_capable
        ? [
            {
              tabName: MessageTabs.SUPER_AGENT,
              iconName: 'ninjaPixProLogo' as IconName,
              tabTitle: 'Ninja SuperAgent',
              content: '',
            },
          ]
        : []),
      ...tabContent,
    ],
    [
      ninja_model_output,
      tabContent,
      settings?.super_agent?.is_capable,
      withSuperAgent,
      isStreaming,
    ],
  );

  const { getTabSRegistry, onTabClick } = useMessageTab({
    messageId,
    tabsData,
  });

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    onTabClick(tabName);

    if (tabName === MessageTabs.SUPER_AGENT) {
      sendGTMEvent(GTMEvent.SUPERAGENT_UPSELL_TAB_CLICK);
      return;
    }

    if (withSuperAgent && tabName === superAgentTabData?.tabName) {
      tabContent[0]?.content
        ? sendGTMEvent(GTMEvent.SUPERAGENT_COMPLETE_CLICK)
        : sendGTMEvent(GTMEvent.SUPERAGENT_IN_PROGRESS_CLICK);
      return;
    }
  };

  const { activeTab, setActiveTab, handleSeeSuperAgentAnswerButton } =
    useMessageSwitchTabs({
      taskId,
      handleTabClick,
      superAgentWithExternalModelName: superAgentTabData?.tabName,
    });

  if (skill === TaskSkill.DOCUMENT_QA && isLimitedTier) {
    if (ninja_model_output) {
      const trimmedContent = ninja_model_output.replace(/^\n+|\n+$/g, '');
      const isLongText = trimmedContent.length > TEXT_MAX_SIZE;
      const slicedContent = `${trimmedContent.slice(0, TEXT_MAX_SIZE).trim()}...`;
      return (
        <div>
          <div className={styles.contentWrapper}>
            <ThreadMessageContent
              content={slicedContent}
              messageId={messageId}
              isStreaming={isStreaming}
            />
            {isLongText && <div className={styles.blurOverlay} />}
          </div>
          {isLongText &&
            (!isTeamsStandardTier ? (
              <div className={styles.upsellBlockWrapper}>
                <span className={styles.upsellBlockText}>
                  Upgrade to Pro to reveal the answer and include your file(s)
                  in follow-up conversations.
                </span>
                <UpgradeButton dataGTM="File-agent-response-upgrade" />
              </div>
            ) : (
              <div className={styles.upgradeButtonWrapper}>
                <UpgradeButton
                  dataGTM="File-agent-response-upgrade"
                  title={
                    isOwner ? 'Upgrade to Unlock' : 'Request Upgrade to Unlock'
                  }
                />
              </div>
            ))}
        </div>
      );
    }

    return null;
  }

  return (
    <div className={styles.root}>
      <Tabs<string> defaultActiveTab={activeTab} onTabClick={handleTabClick}>
        {renderMessageTabList({
          data: tabsData,
          withSuperAgent,
          tabRegistry: getTabSRegistry(),
        })}

        {tabsData.map(({ tabName, content, tabTitle, status, reasoning }) => {
          const isSuperAgentTab =
            tabName.includes(SUPER_AGENT_AS_EXTERNAL_MODEL) ||
            tabName === MessageTabs.SUPER_AGENT;

          const isTabStreaming =
            tabName === MessageTabs.NINJA_AGENT
              ? isStreaming
              : status !== AiGenerationStatus.SUCCESS;

          return (
            <TabPanel key={tabName} tab={tabName}>
              {content.length === 0 && !reasoning ? (
                // TODO(olha): create a common component
                <>
                  {isSuperAgentTab && withSuperAgent && (
                    <SuperAgentIsWorkingHardText taskId={taskId} />
                  )}

                  {(!isSuperAgentTab || withSuperAgent) && (
                    <ProgressBar count={3} withColor={isSuperAgentTab} />
                  )}

                  {isSuperAgentTab && (
                    <SuperAgentUpsell description="An advanced agent that dives deep into your questions, leveraging relevant sources to deliver more accurate and richer answers." />
                  )}
                </>
              ) : (
                <div className={styles.contentWrapper}>
                  <ThreadMessageContent
                    messageId={messageId}
                    content={content}
                    isStreaming={isTabStreaming}
                    reasoning={reasoning}
                  />

                  {tabName === MessageTabs.NINJA_AGENT && withSuperAgent && (
                    <SeeSuperAgentAnswerButton
                      onClick={handleSeeSuperAgentAnswerButton}
                    />
                  )}

                  <FeedbackRating
                    title={`Rate ${tabTitle} answer`}
                    messageId={messageId}
                    isStreaming={status !== AiGenerationStatus.SUCCESS}
                    operationType="models"
                    modelId={tabName}
                  />
                </div>
              )}
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};
