import {
  useLazyGetBillingPortalUrlQuery,
  useResumeSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from 'src/store/services';
import { useSession, useSubscriptionInfo } from 'src/hooks';
import { AppRoutes, BillingSettingsNestedRoutes } from 'src/types';
import './CancelSubscription.scss';
import log from 'src/utils/logger';
import { CancelSubscriptionModal } from 'src/pages/PaymentBetaPage/components/CancelSubscriptionModal';
import dayjs from 'dayjs';
import { APPLE_PAYMENT_ERROR_TEXT, PAYMENTS_DATE_FORMAT } from 'src/constants';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'src/v2/commonComponents/Button';

export const CancelSubscription = () => {
  const { appUser } = useSession();
  const [trigger, { isLoading }] = useLazyGetBillingPortalUrlQuery();

  const { subscriptionInfo, isSubscriptionInfoLoading, isAppleOrAmpPayment } =
    useSubscriptionInfo();

  const [upgradeSubscription, { isLoading: isUpgradeSubscriptionLoading }] =
    useUpgradeSubscriptionMutation();

  const [resumeSubscription, { isLoading: isResumeSubscriptionLoading }] =
    useResumeSubscriptionMutation();

  const cancelledDate = useMemo(() => {
    return dayjs(subscriptionInfo?.curr_period_end_timestamp).format(
      PAYMENTS_DATE_FORMAT,
    );
  }, [subscriptionInfo?.curr_period_end_timestamp]);

  const isCancelDisabled =
    isLoading ||
    isSubscriptionInfoLoading ||
    subscriptionInfo?.is_cancelled ||
    subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment ||
    !!appUser?.stripe_info?.renew_early_pending_subscription;

  const isRenewDisabled =
    isResumeSubscriptionLoading ||
    isSubscriptionInfoLoading ||
    isUpgradeSubscriptionLoading ||
    subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment ||
    !!appUser?.stripe_info?.renew_early_pending_subscription;

  // TODO(olha): add error handling
  // TODO(olha): it's a temporary solution.
  const handleStripeClick = async () => {
    if (!subscriptionInfo) {
      return;
    }

    const { data } = await trigger({
      user_id: appUser.user_id,
      return_url: `${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.SUBSCRIPTION}`,
    });
    const redirectUrl = data?.url;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const handleRenewClick = async () => {
    if (!subscriptionInfo || !subscriptionInfo?.price_info?.lookup_key) {
      return;
    }

    if (!!subscriptionInfo && subscriptionInfo?.source === 'stripe') {
      try {
        const result = await upgradeSubscription({
          lookup_key: subscriptionInfo?.price_info?.lookup_key,
          user_id: appUser.user_id,
        }).unwrap();

        const redirectUrl =
          result.checkout_session?.url || result.billing_portal_session?.url;

        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      } catch (error) {
        toast.error('Failed to renew subscription.');
        log.error(error);
      }
    } else {
      try {
        await resumeSubscription(appUser.user_id).unwrap();
        toast.success('Subscription renewed successfully.');
      } catch (error) {
        toast.error('Failed to renew subscription.');
        log.error(error);
      }
    }
  };

  return (
    <div className="nj-cancel-subscription">
      <p className="nj-cancel-subscription--header">
        <span className="nj-cancel-subscription--title">
          Cancel subscription
        </span>

        <span className="nj-cancel-subscription--description">
          On cancellation, your subscription will continue until the end of the
          month. Once subscription is expired your account will be downgraded to
          Basic.
        </span>
      </p>

      <div className="nj-cancel-subscription--footer">
        {subscriptionInfo?.source !== 'paypal' &&
          !subscriptionInfo?.is_cancelled && (
            <Button
              disabled={isCancelDisabled}
              tooltipContent={
                isAppleOrAmpPayment ? APPLE_PAYMENT_ERROR_TEXT : ''
              }
              onClick={handleStripeClick}
              className="nj-cancel-subscription--submit"
            >
              Cancel subscription
            </Button>
          )}

        {subscriptionInfo?.source === 'paypal' && (
          <CancelSubscriptionModal
            disabled={isCancelDisabled}
            cancellationDate={cancelledDate}
            userId={appUser.user_id}
            tierId={appUser.tier_id}
          />
        )}

        {subscriptionInfo?.is_cancelled && (
          <button
            disabled={isRenewDisabled}
            onClick={handleRenewClick}
            className="nj-renew-subscription--submit"
          >
            Renew subscription
          </button>
        )}
      </div>
    </div>
  );
};
