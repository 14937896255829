import {
  setAutoScrollRegistry,
  setSocketConnected,
  setThreadStatus,
  setWelcomeUpsellModalType,
  showAccessRequestOnSchedulerTask,
  toggleWelcomeModal,
  useAppDispatch,
  useAppSelector,
  threadStatusRegistryState,
} from 'src/store';
import {
  teamsApi,
  TeamsTags,
  usersApi,
  UsersTags,
  walletApi,
  WalletTags,
} from 'src/store/services';
import {
  addConversation,
  addConversationMessageChunk,
  addConversationMessageContentChunk,
  addConversationMessageFooter,
  addConversationToList,
  addMessageToConversation,
  addMessageToTask,
  addTask,
  addTaskMessageContentChunk,
  addTaskToConversation,
  addTaskToConversationsList,
  addTaskToFilteredBySkillConversationsList,
  appendConversationMessage,
  appendTaskMessage,
  replaceConversationMessage,
  replaceTaskMessage,
  updateConversationInConversationsList,
  updateConversationMessage,
  updateConversationState,
  updateTaskInConversation,
  updateTaskInConversationsList,
  updateTaskState,
  updateUserQuery,
} from 'src/store/updateQueries';
import { useEffect, useRef } from 'react';
import ReconnectingWebSocket from 'src/utils/ws';
import { combusWSBaseUrl } from 'src/store/constants';
import {
  get_access_token,
  getWelcomeModalType,
  isBetaEnv,
  isGamma,
  isJsonString,
} from 'src/utils';
import {
  BannerType,
  ConversationRole,
  isApiTaskSelectable,
  isConversation,
  isMessage,
  isMessageChunk,
  isMessageFooter,
  isPartialApiTask,
  isPartialConversation,
  isPDUMessage,
  Message,
  MessageType,
  NinjaEventTypes,
  OperationType,
  TaskSkill,
  WelcomeUpsellModalType,
} from 'src/types';
import {
  removeBannersFromSet,
  setNewBannerToSet,
  setPartialAppUser,
  setUserAppVersion,
} from 'src/store/slices/sessionSlice';
import log from 'src/utils/logger';
import {
  DEFAULT_CHAT_ID,
  // DO_NOT_SHOW_BASIC_WELCOME_MODAL,
  PAYPAL_PAYMENT_IN_PROGRESS,
} from 'src/constants';
import { batch } from 'react-redux';
import { toast } from 'react-toastify';
import { maybeLogTaskMetric } from 'src/utils/metrics';

export const useSocket = (user_id: string, agent_id: string) => {
  const webSocketRef = useRef<ReconnectingWebSocket | null>(null);
  const dispatch = useAppDispatch();

  const threadsStatusRegistry = useAppSelector(threadStatusRegistryState);

  const updateThreadStatus = (currentConversationId: string) => {
    dispatch(
      setThreadStatus({
        threadId: currentConversationId,
        statusRegistry: {
          ignoreMessages: false,
          isSubmitHappened: false,
        },
      }),
    );
  };

  useEffect(() => {
    if (user_id && combusWSBaseUrl) {
      // user_id must have been changed, reopen connection with new token and user_id
      if (webSocketRef.current !== null) {
        webSocketRef.current?.close();
        webSocketRef.current = null;
      }

      webSocketRef.current = new ReconnectingWebSocket(
        combusWSBaseUrl,
        async () => {
          const token = await get_access_token();
          return (combusWSBaseUrl &&
            combusWSBaseUrl.indexOf('ws://localhost') >= 0) ||
            !token
            ? [user_id]
            : ['ninja.ws', token, user_id];
        },
        {
          debug: isBetaEnv || isGamma(),
          maxReconnectionDelay: 30000,
          shouldPing: true,
        },
      );

      webSocketRef.current.onopen = () => {
        log.debug('nj socket: onopen');
        dispatch(setSocketConnected(true));
      };

      webSocketRef.current.onclose = () => {
        log.debug('nj socket: onclose');
        dispatch(setSocketConnected(false));
      };

      webSocketRef.current.onerror = () => {
        log.error('nj socket: onerror');
        dispatch(setSocketConnected(false));
      };

      webSocketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (isPDUMessage(data)) {
          const { event_type, payload } = data;
          const apiPayload = JSON.parse(payload);

          const conversationId = apiPayload.conversation_id;
          const ignoreMessages =
            threadsStatusRegistry[conversationId]?.ignoreMessages;

          if (ignoreMessages) {
            return;
          }

          switch (event_type) {
            case NinjaEventTypes.TASK_COST: {
              dispatch(
                walletApi.util.updateQueryData(
                  'getUserTaskCostsInfo',
                  { user_id: user_id, task_id: apiPayload?.task_id || '' },
                  () => {
                    return {
                      chargeable_costs: apiPayload.cost_by_component.map(
                        (item: { component: string; cost: number }) => ({
                          item: { name: item.component },
                          cost: { amount: item.cost, currency: 'usd' },
                        }),
                      ),
                    };
                  },
                ),
              );
              break;
            }
            case NinjaEventTypes.NEW_TASK: {
              if (isApiTaskSelectable(apiPayload)) {
                dispatch(addTask(apiPayload));
                dispatch(addTaskToConversationsList(apiPayload));

                dispatch(addTaskToConversation(apiPayload));
                dispatch(showAccessRequestOnSchedulerTask(apiPayload));

                // update filtered cache
                dispatch(addTaskToFilteredBySkillConversationsList(apiPayload));
              } else {
                log.error(`Incorrect data type for ApiTask ${payload}`);
              }
              break;
            }
            case NinjaEventTypes.NEW_CONVERSATION: {
              if (isConversation(apiPayload)) {
                dispatch(addConversationToList(apiPayload));
                dispatch(addConversation(apiPayload));
              } else {
                log.error(`Incorrect data type for Conversation ${payload}`);
              }
              break;
            }
            case NinjaEventTypes.NEW_MESSAGE: {
              // TODO(olha): it's a quick workaround. Needs refactoring
              if (isMessage(apiPayload)) {
                switch (apiPayload.operation_type) {
                  case OperationType.APPEND:
                    if (apiPayload.task_id) {
                      dispatch(appendTaskMessage(apiPayload));
                    } else {
                      dispatch(appendConversationMessage(apiPayload));
                    }
                    break;
                  case OperationType.REPLACE:
                    if (apiPayload.task_id) {
                      dispatch(replaceTaskMessage(apiPayload));
                    } else {
                      dispatch(replaceConversationMessage(apiPayload));
                    }
                    break;
                  case OperationType.UPDATE:
                    if (!apiPayload.task_id) {
                      dispatch(updateConversationMessage(apiPayload));
                    }
                    break;
                  default:
                    if (apiPayload.task_id) {
                      dispatch(addMessageToTask(apiPayload));
                    } else {
                      dispatch(addMessageToConversation(apiPayload));

                      if (apiPayload.conversation_id) {
                        updateThreadStatus(apiPayload.conversation_id);

                        dispatch(
                          setAutoScrollRegistry({
                            conversationId: apiPayload.conversation_id || '',
                            messageId: apiPayload.message_id || '',
                          }),
                        );
                      }
                    }
                    break;
                }
              } else {
                log.error(`Incorrect data type for Message type ${payload}`);
              }
              break;
            }
            case NinjaEventTypes.NEW_STREAMABLE_MESSAGE_HEADER: {
              if (!isMessage(apiPayload)) {
                log.error(`Incorrect data type for Message type ${payload}`);
                break;
              }

              if (
                apiPayload.message_type === MessageType.CONVERSATION ||
                apiPayload.message_type === MessageType.CHAT_CARD
              ) {
                // TODO(olha) For now we suppose if operation_type === CREATE,
                dispatch(addMessageToConversation(apiPayload));

                updateThreadStatus(
                  apiPayload.conversation_id || DEFAULT_CHAT_ID,
                );

                dispatch(
                  setAutoScrollRegistry({
                    conversationId: apiPayload.conversation_id || '',
                    messageId: apiPayload.message_id || '',
                  }),
                );
                break;
              }

              if (apiPayload.task_id) {
                // TODO(olha) For now we suppose if operation_type === REPLACE,
                dispatch(replaceTaskMessage(apiPayload));
              } else {
                // TODO(olha) For now we suppose if operation_type === UPDATE,
                dispatch(updateConversationMessage(apiPayload));
              }
              break;
            }

            case NinjaEventTypes.NEW_STREAMABLE_MESSAGE_CHUNK: {
              if (!isMessageChunk(apiPayload)) {
                log.error(
                  `Incorrect data type for MessageChunk type ${payload}`,
                );
                break;
              }

              if (
                apiPayload.message_type === MessageType.CONVERSATION &&
                !apiPayload.task_id
              ) {
                dispatch(
                  addConversationMessageChunk({
                    user_id,
                    messageChunk: apiPayload,
                  }),
                );
                break;
              }

              if (!isJsonString(apiPayload.content)) {
                if (apiPayload.task_id) {
                  dispatch(
                    addTaskMessageContentChunk({
                      user_id,
                      messageChunk: apiPayload,
                    }),
                  );
                } else {
                  dispatch(
                    addConversationMessageContentChunk({
                      user_id,
                      messageChunk: apiPayload,
                    }),
                  );
                }

                break;
              }

              const { content, ...restChunk } = apiPayload;

              const messagePayload = JSON.parse(content);

              const chunkMessage: Message = {
                content: '',
                ...restChunk,
                payload: messagePayload,
                user_id,
                to_user_id: user_id,
                from_user_id: agent_id,
                role: ConversationRole.AGENT,
              };

              if (!isMessage(chunkMessage)) {
                log.error(
                  `Incorrect payload message type in MessageChunk ${payload}`,
                );
                break;
              }

              if (chunkMessage.task_id) {
                switch (chunkMessage.operation_type) {
                  case OperationType.APPEND:
                    dispatch(appendTaskMessage(chunkMessage));
                    break;

                  case OperationType.REPLACE:
                    dispatch(replaceTaskMessage(chunkMessage));
                    break;

                  default:
                    dispatch(addMessageToTask(chunkMessage));
                    break;
                }
              } else {
                switch (chunkMessage.operation_type) {
                  case OperationType.APPEND:
                    dispatch(appendConversationMessage(chunkMessage));
                    maybeLogTaskMetric(chunkMessage);
                    break;

                  case OperationType.REPLACE:
                    dispatch(replaceConversationMessage(chunkMessage));
                    break;

                  case OperationType.UPDATE:
                    dispatch(updateConversationMessage(chunkMessage));
                    break;

                  default:
                    break;
                }
              }
              break;
            }

            case NinjaEventTypes.NEW_STREAMABLE_MESSAGE_FOOTER: {
              if (!isMessageFooter(apiPayload)) {
                log.error(
                  `Incorrect data type for MessageFooter type ${payload}`,
                );
                break;
              }

              if (
                apiPayload.message_type === MessageType.CONVERSATION ||
                apiPayload.message_type === MessageType.TASK_CREATED
              ) {
                dispatch(addConversationMessageFooter(apiPayload));
                break;
              }

              if (!isMessage(apiPayload)) {
                log.error(
                  `Incorrect data type for Message in MessageFooter ${payload}`,
                );
                break;
              }
              if (apiPayload.task_id) {
                dispatch(replaceTaskMessage(apiPayload));
              } else {
                switch (apiPayload.operation_type) {
                  // TODO(olha): temporary workaround until BE change operation type from APPEND to UPDATE in the Footer. Delete OperationType.APPEND from here
                  case OperationType.APPEND:
                  case OperationType.UPDATE:
                    dispatch(updateConversationMessage(apiPayload));
                    break;

                  default:
                    dispatch(replaceConversationMessage(apiPayload));
                    break;
                }
              }
              break;
            }

            case NinjaEventTypes.UPDATE_TASK: {
              if (isPartialApiTask(apiPayload)) {
                dispatch(updateTaskState({ user_id, task: apiPayload }));
                dispatch(
                  updateTaskInConversationsList({ user_id, task: apiPayload }),
                );

                // update filtered cache
                if (apiPayload.skill) {
                  dispatch(
                    updateTaskInConversationsList({
                      user_id,
                      task: apiPayload,
                      filter_task_skills: apiPayload.skill,
                    }),
                  );
                }

                dispatch(
                  updateTaskInConversation({ user_id, task: apiPayload }),
                );
              } else {
                log.error(
                  `Arrived data is not of the Partial<ApiTask> type ${payload}`,
                );
              }
              break;
            }
            case NinjaEventTypes.UPDATE_CONVERSATION: {
              if (isPartialConversation(apiPayload)) {
                dispatch(
                  updateConversationState({
                    user_id,
                    conversation: apiPayload,
                  }),
                );

                dispatch(
                  updateConversationInConversationsList({
                    user_id,
                    conversation: apiPayload,
                  }),
                );

                Object.values(TaskSkill).forEach((filter) =>
                  dispatch(
                    updateConversationInConversationsList({
                      user_id,
                      conversation: apiPayload,
                      filter_task_skills: filter,
                    }),
                  ),
                );
              } else {
                log.error(
                  `Arrived data is not of the Conversation type ${payload}`,
                );
              }
              break;
            }
            case NinjaEventTypes.UPDATE_APP_VERSION: {
              dispatch(setUserAppVersion(apiPayload));
              break;
            }
            case NinjaEventTypes.NOTIFY_USER: {
              if (
                apiPayload.payload_type === 'user_balance_notification' &&
                apiPayload.task_balance !== undefined
              ) {
                const task_balance = apiPayload.task_balance;
                dispatch(
                  walletApi.util.updateQueryData(
                    'getUserTaskQuotaInfo',
                    { user_id: user_id },
                    () => {
                      return { count: task_balance };
                    },
                  ),
                );
                if (task_balance <= 3) {
                  dispatch(setNewBannerToSet(BannerType.LOW_TASKS));
                }
              } else if (
                apiPayload.payload_type === 'user_balance_notification' &&
                apiPayload.budget_balance !== undefined
              ) {
                if (apiPayload.low_budget) {
                  // dispatch(setNewBannerToSet(BannerType.LOW_CREDITS));
                  dispatch(
                    removeBannersFromSet([
                      BannerType.INSUFFICIENT_CREDITS,
                      BannerType.LOW_TASKS,
                    ]),
                  );
                } else {
                  dispatch(
                    removeBannersFromSet([
                      BannerType.LOW_CREDITS,
                      BannerType.INSUFFICIENT_CREDITS,
                    ]),
                  );
                }
                dispatch(
                  walletApi.util.updateQueryData(
                    'getUserBudgetQuotaInfo',
                    { user_id: user_id },
                    (data) => {
                      return {
                        ...data,
                        amount: apiPayload.budget_balance,
                        is_low_balance: !!apiPayload.low_budget,
                      };
                    },
                  ),
                );
              }
              break;
            }
            // TODO(olha): will be deprecated later
            case NinjaEventTypes.UPDATE_USER_STATUS: {
              if (!apiPayload || apiPayload.status === undefined) {
                break;
              }

              const { status } = apiPayload;
              dispatch(
                updateUserQuery({
                  id: user_id,
                  body: { status },
                }),
              );
              dispatch(setPartialAppUser({ status }));
              break;
            }
            case NinjaEventTypes.UPDATE_USER_V2: {
              if (!apiPayload) {
                break;
              }

              if (apiPayload.rate_limited_until !== undefined) {
                const { rate_limited_until } = apiPayload;
                dispatch(
                  updateUserQuery({
                    id: user_id,
                    body: { rate_limited_until },
                  }),
                );
                dispatch(setPartialAppUser({ rate_limited_until }));
                break;
              }

              if (apiPayload.status !== undefined) {
                const { status } = apiPayload;
                dispatch(
                  updateUserQuery({
                    id: user_id,
                    body: { status },
                  }),
                );
                dispatch(setPartialAppUser({ status }));
                break;
              }

              if (apiPayload.tier_id !== undefined) {
                const { tier_id } = apiPayload;
                localStorage.removeItem(PAYPAL_PAYMENT_IN_PROGRESS);
                // localStorage.removeItem(DO_NOT_SHOW_BASIC_WELCOME_MODAL);
                const modalType: WelcomeUpsellModalType | null =
                  getWelcomeModalType(tier_id);

                batch(() => {
                  if (modalType) {
                    dispatch(setWelcomeUpsellModalType(modalType));
                    dispatch(toggleWelcomeModal(true));
                    dispatch(
                      removeBannersFromSet([
                        BannerType.LOW_CREDITS,
                        BannerType.LOW_TASKS,
                        BannerType.LOW_DAYS,
                      ]),
                    );
                  }

                  dispatch(usersApi.util.invalidateTags([UsersTags.Users]));
                  dispatch(
                    teamsApi.util.invalidateTags([TeamsTags.TeamByUserId]),
                  );
                });
                break;
              }

              break;
            }
            case NinjaEventTypes.UPDATE_TEAM:
              if (!apiPayload) {
                break;
              }
              dispatch(
                teamsApi.util.updateQueryData(
                  'getTeamByUserId',
                  {
                    user_id,
                  },
                  (draft) => ({
                    ...draft,
                    team: {
                      ...draft.team,
                      num_seats_assigned:
                        apiPayload.num_seats_assigned ||
                        draft.team.num_seats_assigned,
                      num_seats_invite_pending:
                        apiPayload.num_seats_invite_pending ||
                        draft.team.num_seats_invite_pending,
                      num_seats_total:
                        apiPayload.num_seats_total ||
                        draft.team.num_seats_total,
                    },
                  }),
                ),
              );
              break;
            case NinjaEventTypes.PAYMENT_STATUS: {
              if (!apiPayload) {
                break;
              }
              if (apiPayload.status === 'SUCCESS') {
                toast('Payment successful');
              } else {
                toast('Payment failed. Please update your billing information');
              }
              dispatch(
                walletApi.util.invalidateTags([
                  WalletTags.UserSubscriptionInfo,
                ]),
              );
              break;
            }

            default:
              log.error('ERROR: Unknown data type: ', apiPayload);
          }
        }
      };
    }

    return () => {
      webSocketRef.current?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, combusWSBaseUrl]);

  return {
    openSocket: webSocketRef.current,
  };
};
