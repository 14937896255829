import { researchedMediaSettingOptions } from 'src/constants';
import { TextDivider } from 'src/v2/commonComponents/TextDivider';
import { MediaSettingsItem } from './components/MediaSettingsItem';
import { SkillSectionWrapper } from '../SkillSectionWrapper';
import styles from './ResearcherSection.module.scss';

export const ResearcherSection = () => {
  return (
    <SkillSectionWrapper hasSettingsTab>
      <div className={styles.root}>
        <p className={styles.caption}>Preferred media resources</p>

        <div className={styles.options}>
          {researchedMediaSettingOptions.map(({ value, label, icon }) => (
            <MediaSettingsItem
              key={value}
              value={value}
              label={label}
              icon={icon}
            />
          ))}

          <TextDivider />
        </div>
      </div>
    </SkillSectionWrapper>
  );
};
