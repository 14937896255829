import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useCommands, useConversationParams, useBreakpoint } from 'src/hooks';
import { useQuickPickerControls } from 'src/hooks';
import { PortalWrapper } from 'src/components/PortalWrapper';

interface QuickCommandPickerProps {
  isOpen: boolean;
  onClose: () => void;
  setCursorPosition: (position: number) => void;
}
// TODO(olha): component needs deep refactoring
export const QuickCommandPicker = ({
  isOpen,
  onClose,
  setCursorPosition,
}: QuickCommandPickerProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { isLandingPage } = useConversationParams();
  const { isMobile, isTablet } = useBreakpoint();
  const [isFocus, setIsFocus] = useState<boolean>(true);

  const { matchingCommands } = useCommands({
    shouldSearch: isOpen,
  });

  const handleCommandClick = useCallback(
    (index: number) => {
      const command = matchingCommands[index].title;
      const insertValue = `${command} `;
      setThreadInputBoxValue(insertValue);
      setCursorPosition(insertValue.length);
    },
    [matchingCommands, setThreadInputBoxValue, setCursorPosition],
  );

  const { selectedIndex } = useQuickPickerControls({
    options: matchingCommands,
    isOpen,
    shouldFocusInputBox: true,
    onClose,
    onItemClick: handleCommandClick,
  });

  const tooltipPlace = useMemo(() => {
    if (isLandingPage) {
      if (isMobile) {
        return 'top-end';
      }

      if (isTablet) {
        return 'bottom-start';
      }

      return 'right';
    }

    return 'top-start';
  }, [isLandingPage, isMobile, isTablet]);

  const tooltipOffset = useMemo(() => {
    if (isLandingPage) {
      if (isMobile) {
        return -140;
      }

      if (isTablet) {
        return -40;
      }

      return -360;
    }

    return 44;
  }, [isLandingPage, isMobile, isTablet]);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocus(true);
    };

    const handleBlur = () => {
      setIsFocus(false);
    };

    const inputElement = threadInputBoxRef?.current;

    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      inputElement.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
        inputElement.removeEventListener('blur', handleBlur);
      }
    };
  }, [threadInputBoxRef]);

  if (!isOpen || matchingCommands.length === 0) {
    return null;
  }

  return (
    <PortalWrapper>
      <ReactTooltip
        anchorSelect="#input-box-anchor-tooltip"
        isOpen={true}
        clickable
        noArrow
        openOnClick
        opacity={1}
        place={tooltipPlace}
        className={classNames('nj-thread-input-box--quick-picker-tooltip', {
          'reduced-height': isFocus,
        })}
        offset={tooltipOffset}
      >
        <div className="nj-thread-input-box--quick-picker-container">
          {matchingCommands.map(({ title, description }, index) => (
            <div
              className={classNames('nj-thread-input-box--quick-picker-item', {
                selected: index === selectedIndex,
              })}
              key={title}
              onMouseDown={(e) => {
                e.preventDefault();
                handleCommandClick(index);
              }}
            >
              <div>
                <p className="nj-thread-input-box--quick-picker-title bold">
                  {title}
                </p>
                <p className="nj-thread-input-box--quick-picker-title">
                  {description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </ReactTooltip>
    </PortalWrapper>
  );
};
