import { useMemo } from 'react';
import {
  useGoogleLogin,
  UseGoogleLoginOptionsAuthCodeFlow,
} from '@react-oauth/google';
import { useSession } from 'src/hooks';
import {
  useGetGoogleResourcesAccessQuery,
  useRevokeGoogleResourcesAccessMutation,
} from 'src/store/services';
import {
  CALENDAR_URL,
  CONTACTS_URL,
  DIRECTORY_URL,
  GOOGLE_DRIVE_READONLY_URL,
} from 'src/constants';
import { env } from 'src/env';

export const useGoogleResourcesAccess = () => {
  const { appUser, isOpenTier } = useSession();

  const googleLoginParams: UseGoogleLoginOptionsAuthCodeFlow = {
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: env.REACT_APP_GOOGLE_AUTHORIZATION_REDIRECT_URI,
    hint: appUser?.email,
    state: `${window.location.href.split('?')[0]}?user_id=${appUser.user_id}`,
  };

  const [revokeGoogleAccess] = useRevokeGoogleResourcesAccessMutation();

  const { data, isLoading, isFetching } = useGetGoogleResourcesAccessQuery(
    appUser.user_id,
    { skip: !appUser?.user_id || isOpenTier },
  );

  const currentProvider = useMemo(() => {
    return data?.providers ? data.providers[0] : undefined;
  }, [data?.providers]);

  const canAccessCalendar = useMemo(
    () => !!currentProvider?.scopes.find((item) => item === CALENDAR_URL),
    [currentProvider?.scopes],
  );
  const canAccessContacts = useMemo(
    () => !!currentProvider?.scopes.find((item) => item === CONTACTS_URL),
    [currentProvider?.scopes],
  );

  const canAccessDirectory = useMemo(
    () => !!currentProvider?.scopes.find((item) => item === DIRECTORY_URL),
    [currentProvider?.scopes],
  );

  const canAccessGoogleDrive = useMemo(
    () =>
      !!currentProvider?.scopes.find(
        (item) => item === GOOGLE_DRIVE_READONLY_URL,
      ),
    [currentProvider?.scopes],
  );

  const connectAll = useGoogleLogin({
    ...googleLoginParams,
    scope: `${CALENDAR_URL} ${CONTACTS_URL} ${DIRECTORY_URL} ${GOOGLE_DRIVE_READONLY_URL}`,
  });

  const connectCalendar = useGoogleLogin({
    ...googleLoginParams,
    scope: CALENDAR_URL,
  });

  const connectCalendarAndContacts = useGoogleLogin({
    ...googleLoginParams,
    scope: `${CONTACTS_URL} ${DIRECTORY_URL} ${CALENDAR_URL}`,
  });

  const connectContacts = useGoogleLogin({
    ...googleLoginParams,
    scope: `${CONTACTS_URL} ${DIRECTORY_URL}`,
  });

  const connectDirectory = useGoogleLogin({
    ...googleLoginParams,
    scope: DIRECTORY_URL,
  });

  const connectGoogleDrive = useGoogleLogin({
    ...googleLoginParams,
    scope: GOOGLE_DRIVE_READONLY_URL,
  });

  return {
    canAccessCalendar,
    canAccessContacts,
    canAccessDirectory,
    canAccessGoogleDrive,
    connectAll,
    connectCalendar,
    connectContacts,
    connectCalendarAndContacts,
    connectDirectory,
    connectGoogleDrive,
    revokeGoogleAccess,
    isLoadingConnectionCheck: isLoading,
    isFetchingConnectionCheck: isFetching,
    currentProvider,
  };
};
