import { useMemo } from 'react';
import { useGetConversationsQuery } from 'src/store/services';
import {
  useAppSelector,
  sessionState,
  conversationsListState,
} from 'src/store';
import { TaskState, TaskStatusFilter } from 'src/types';

// TODO(olha): divide into two separate hooks: useConversationsList, useCurrentConversation, useConversationActions
export const useConversations = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);
  const { pageToken, taskStatusFilter, taskSkillFilter } = useAppSelector(
    conversationsListState,
  );

  const includeArchived = useMemo(
    () => taskStatusFilter === TaskStatusFilter.ALL_AND_ARCHIVED,
    [taskStatusFilter],
  );

  const taskStateFromFilter = useMemo(() => {
    switch (taskStatusFilter) {
      case TaskStatusFilter.ALL_AND_ARCHIVED:
      case TaskStatusFilter.ALL:
        return;

      case TaskStatusFilter.DONE:
        return [TaskState.DONE];

      case TaskStatusFilter.BLOCKED:
        return [TaskState.BLOCKED];

      case TaskStatusFilter.FAILED:
        return [TaskState.FAILED];

      case TaskStatusFilter.IN_PROGRESS:
        return [TaskState.IN_PROGRESS];

      case TaskStatusFilter.STOPPED:
        return [TaskState.STOPPED];

      default:
        return;
    }
  }, [taskStatusFilter]);

  const {
    data: conversationListData,
    isLoading: isConversationsLoading,
    isFetching: isConversationsFetching,
  } = useGetConversationsQuery(
    {
      user_id,
      page_token: pageToken,
      include_archived: includeArchived,
      filter_task_skills: taskSkillFilter,
      filter_task_states: taskStateFromFilter,
    },
    { skip: !user_id },
  );

  const hasUnreadMessage = useMemo(
    () =>
      conversationListData?.data?.some(
        (item) => !!item.number_tasks_require_attention,
      ),
    [conversationListData],
  );

  return useMemo(
    () => ({
      conversations: conversationListData?.data,
      newPageToken: conversationListData?.page_token || '',
      isConversationsLoading,
      isConversationsFetching,
      hasUnreadMessage,
      pageToken,
    }),
    [
      conversationListData,
      isConversationsLoading,
      isConversationsFetching,
      hasUnreadMessage,
      pageToken,
    ],
  );
};
