import { ArrowsClockwise } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './RegenerateToggle.module.scss';

interface RegenerateToggleProps {
  id: string;
  disabled?: boolean;
  onClick: () => void;
  isActive?: boolean;
}

export const RegenerateToggle = ({
  id,
  disabled,
  onClick,
  isActive,
}: RegenerateToggleProps) => {
  return (
    <Button
      id={id}
      disabled={disabled}
      color={isActive ? 'tertiary' : 'transparent'}
      shape="round"
      onClick={onClick}
      className={styles.root}
    >
      <ArrowsClockwise size={SVG_SIZE_M} />
    </Button>
  );
};
