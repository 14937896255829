import { forwardRef, useMemo } from 'react';
import { X } from '@phosphor-icons/react';
import { useThemeActions, useModeSidePanel } from 'src/hooks';
import { Button } from 'src/components/Button';
import { ChatModeSidePanel } from 'src/types';
import {
  CameraAngleSelector,
  EnvironmentSelector,
  MovieStudioSection,
  SavedContentSection,
} from '../SideSettingsSections';
import { ImageSettings } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ImageSettings';
import { ExecutiveAssistanceSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/ExecutiveAssistanceSettings';
import { ImageGeneratorsSection } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideSettingsSections/ImageGeneratorsSection';

const SVG_SIZE = 24;

/**
 * @deprecated
 */
export const SideSettingsPanel = forwardRef<HTMLDivElement>((_, ref) => {
  const { chatModeSidePanel } = useModeSidePanel();
  const { toggleSettingsPanelExpanded, updateChatModeSidePanel } =
    useThemeActions();

  const handleClose = () => {
    toggleSettingsPanelExpanded(false);
    if (chatModeSidePanel === ChatModeSidePanel.SAVED_CONTENT) {
      updateChatModeSidePanel(ChatModeSidePanel.MOVIE_STUDIO);
    }
  };

  const sectionTitle: string = useMemo(() => {
    switch (chatModeSidePanel) {
      case ChatModeSidePanel.ENVIRONMENT:
        return 'Choose theme';
      case ChatModeSidePanel.CAMERA_ANGLE:
        return 'Camera Angle';
      case ChatModeSidePanel.MOVIE_STUDIO:
        return 'Movie Studio';
      case ChatModeSidePanel.SAVED_CONTENT:
        return 'Saved Content';
      case ChatModeSidePanel.AI_AGENT:
        return 'Ninja persona';
      case ChatModeSidePanel.NINJA_AGENTS:
        return 'Ninja agent settings';
      case ChatModeSidePanel.IMAGE_GENERATORS:
        return 'Image generators';
      case ChatModeSidePanel.VOICE_LANGUAGE:
        return 'Voice & Language';
      default:
        return '';
    }
  }, [chatModeSidePanel]);

  const section = useMemo(() => {
    switch (chatModeSidePanel) {
      case ChatModeSidePanel.ENVIRONMENT:
        return <EnvironmentSelector />;
      case ChatModeSidePanel.CAMERA_ANGLE:
        return <CameraAngleSelector />;
      case ChatModeSidePanel.MOVIE_STUDIO:
        return <MovieStudioSection />;
      case ChatModeSidePanel.SAVED_CONTENT:
        return <SavedContentSection />;
      case ChatModeSidePanel.NINJA_AGENTS:
        return <ImageSettings />;
      case ChatModeSidePanel.IMAGE_GENERATORS:
        return <ImageGeneratorsSection />;
      case ChatModeSidePanel.VOICE_LANGUAGE:
        return (
          <ExecutiveAssistanceSettings className="nj-section--voice-section-from-main-settings" />
        );
      default:
        return <></>;
    }
  }, [chatModeSidePanel]);

  return (
    <div className="nj-section--side-settings-panel" ref={ref}>
      <div className="nj-section--side-settings-panel-container-wrapper">
        <div className="nj-section--header">
          <h5 className="nj-section--title">{sectionTitle}</h5>
          <Button onClick={handleClose}>
            <X size={SVG_SIZE} />
          </Button>
        </div>

        {section}
      </div>
    </div>
  );
});
