import styles from './SuperAgentSelect.module.scss';
import { useSuperAgentModel } from 'src/hooks';
import { Check } from '@phosphor-icons/react';
import { superAgentModelSet, SVG_SIZE_M } from 'src/constants';
import { SuperAgentModels } from 'src/types';

type SuperAgentSelectProps = {
  onClose: () => void;
};

export const SuperAgentSelect = ({ onClose }: SuperAgentSelectProps) => {
  const { activeSuperAgentModel, onChangeSuperAgentModel } =
    useSuperAgentModel();

  const handleChangeValue = (value: SuperAgentModels | null) => {
    onChangeSuperAgentModel(value);
    onClose();
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.rowWrapper}
        onClick={() => handleChangeValue(null)}
      >
        <div className={styles.labelWrapper}>
          <span className={styles.title}>Ninja Orchestrator</span>
          <span className={styles.description}>
            AI model based on your skill specific preference
          </span>
        </div>
        <div>
          {activeSuperAgentModel === null && <Check size={SVG_SIZE_M} />}
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.headerRow}>
        <span className={styles.headerTitle}>Ninja SuperAgent</span>
        <span className={styles.headerCaption}>Compound AI Models</span>
      </div>
      {Object.keys(superAgentModelSet).map((item) => {
        const model = item as SuperAgentModels;
        return (
          <div
            className={styles.rowWrapper}
            onClick={() => handleChangeValue(model)}
            key={model}
          >
            <div className={styles.labelWrapper}>
              <span className={styles.title}>
                {superAgentModelSet[model].title}
              </span>
              <span className={styles.description}>
                {superAgentModelSet[model].description}
              </span>
            </div>
            <div>
              {activeSuperAgentModel === item && <Check size={SVG_SIZE_M} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
