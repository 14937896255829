import { SVG_SIZE_M } from 'src/constants';
import { ArrowsClockwise, Icon as PhosphorIcon } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './RedoBySkillSubmit.module.scss';
import { MessageSkill } from 'src/types';

interface RedoBySkillSubmitProps {
  Icon: PhosphorIcon;
  skill: MessageSkill;
  onSubmit: (skill: MessageSkill) => void;
}

export const RedoBySkillSubmit = ({
  Icon,
  skill,
  onSubmit,
}: RedoBySkillSubmitProps) => {
  const handleSubmit = () => {
    onSubmit(skill);
  };

  return (
    <Button
      onClick={handleSubmit}
      fullWidth
      color="transparent"
      className={styles.root}
    >
      <Icon size={SVG_SIZE_M} />
      <span className={styles.title}>/{skill}</span>

      <ArrowsClockwise size={SVG_SIZE_M} />
    </Button>
  );
};
