import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RootState } from '../index';
import { SuperAgentModels } from 'src/types';

dayjs.extend(utc);

export type StatusRegistry = {
  ignoreMessages?: boolean;
  isSubmitHappened?: boolean;
};

export type ThreadsStatusRegistry = Record<string, StatusRegistry>;
export type AutoScrollRegistry = Record<string, string | null>;

export interface ThreadsStatusRegistryPayload {
  threadId: string;
  statusRegistry: StatusRegistry;
}

export interface AutoScrollRegistryPayload {
  conversationId: string;
  messageId: string;
}

export interface ThreadState {
  shouldAnimate?: [string, string];
  threadsStatusRegistry: ThreadsStatusRegistry;
  autoScrollRegistry: AutoScrollRegistry;
  activeSuperAgentModel: SuperAgentModels | null;
}

const initialState = {
  ignoreMessages: false,
  isSubmitHappened: false,
  threadsStatusRegistry: {},
  autoScrollRegistry: {},
  activeSuperAgentModel: null,
} as ThreadState;

export const threadSlice = createSlice({
  name: 'thread',
  initialState,
  reducers: {
    setThreadStatus: (
      state,
      action: PayloadAction<ThreadsStatusRegistryPayload>,
    ) => {
      state.threadsStatusRegistry = {
        ...state.threadsStatusRegistry,
        [action.payload.threadId]: {
          ...(state.threadsStatusRegistry[action.payload.threadId] || {}),
          ...action.payload.statusRegistry,
        },
      };
    },
    setShouldAnimate: (
      state,
      action: PayloadAction<[string, string] | undefined>,
    ) => {
      state.shouldAnimate = action.payload || ['', ''];
    },
    setAutoScrollRegistry: (
      state,
      { payload }: PayloadAction<AutoScrollRegistryPayload>,
    ) => {
      state.autoScrollRegistry = {
        ...state.autoScrollRegistry,
        [payload.conversationId]: payload.messageId,
      };
    },
    removeAutoScrollRegistry: (
      state,
      { payload }: PayloadAction<AutoScrollRegistryPayload>,
    ) => {
      if (
        state.autoScrollRegistry[payload.conversationId] === payload.messageId
      ) {
        state.autoScrollRegistry = {
          ...state.autoScrollRegistry,
          [payload.conversationId]: null,
        };
      }
    },
    setActiveSuperAgentModel: (
      state,
      { payload }: PayloadAction<SuperAgentModels | null>,
    ) => {
      state.activeSuperAgentModel = payload;
    },
  },
});

export const threadState = (state: RootState) => state.thread as ThreadState;

export const threadStatusRegistryState = (state: RootState) =>
  state.thread.threadsStatusRegistry as ThreadsStatusRegistry;

export const {
  setThreadStatus,
  setShouldAnimate,
  setAutoScrollRegistry,
  removeAutoScrollRegistry,
  setActiveSuperAgentModel,
} = threadSlice.actions;

export default threadSlice.reducer;
