import { useRef, useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './SegmentedControl.module.scss';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  isVisible?: boolean;
}

interface SegmentedControlProps {
  isVisible?: boolean;
  options: Option[];
  activeValue: string;
  onChange: (value: string, index: number) => void;
}

export const SegmentedControl = ({
  options,
  isVisible,
  activeValue,
  onChange,
}: SegmentedControlProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [componentReady, setComponentReady] = useState<boolean>(false);

  const activeIndex = options.findIndex((item) => item.value === activeValue);

  useEffect(() => {
    setComponentReady(true);
  }, []);

  useLayoutEffect(() => {
    if (isVisible && activeIndex < 0) {
      return;
    }

    const container = containerRef.current;
    const activeButton = buttonRefs.current[activeIndex];

    if (!container || !activeButton) {
      return;
    }

    const { offsetWidth, offsetLeft } = activeButton;
    const { style } = container;
    const circleWidth = 32;
    const xPosition = offsetLeft + offsetWidth / 2 - circleWidth / 2;

    style.setProperty('--highlight-width', `${offsetWidth}px`);
    style.setProperty('--highlight-x-pos', `${xPosition}px`);
  }, [isVisible, activeIndex]);

  const handleClick = (segment: Option, index: number) => {
    if (segment.disabled) {
      return;
    }

    onChange(segment.value, index);
  };

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, {
        [styles.ready]: componentReady,
      })}
    >
      {options.map((segment, index) => {
        const isActive = activeIndex === index;

        return (
          <button
            key={segment.value}
            ref={(el) => (buttonRefs.current[index] = el)}
            className={classNames(styles.segment, {
              [styles.active]: isActive,
              [styles.disabled]: segment.disabled,
            })}
            onClick={() => handleClick(segment, index)}
          >
            {segment.label}
          </button>
        );
      })}
    </div>
  );
};
