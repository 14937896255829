import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

type InvitationAccessDeniedModal = {
  isOpen: boolean;
  teamName: string;
  teamOwner: string;
};

interface TeamsState {
  isOpenInviteUsersModal: boolean;
  isOpenJoinWorkspaceModal: boolean;
  selectedNumberOfSeatsToBuy?: number;
  isFirstInviteUsersRequest: boolean;
  invitationAccessDeniedModal: InvitationAccessDeniedModal;
  isOpenRequestUpgradeModal: boolean;
  isOpenPurchaseSeatsModal: boolean;
  isOpenAddSeatsCheckout: boolean;
  isGetEnterpriseModalOpen: boolean;
  isOpenUnableJoinTeamModal: boolean;
}

const initialState: TeamsState = {
  isOpenInviteUsersModal: false,
  isOpenJoinWorkspaceModal: false,
  selectedNumberOfSeatsToBuy: undefined,
  isFirstInviteUsersRequest: false,
  invitationAccessDeniedModal: {
    isOpen: false,
    teamName: '',
    teamOwner: '',
  },
  isOpenRequestUpgradeModal: false,
  isOpenPurchaseSeatsModal: false,
  isOpenAddSeatsCheckout: false,
  isGetEnterpriseModalOpen: false,
  isOpenUnableJoinTeamModal: false,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setIsOpenInviteUsersModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenInviteUsersModal = payload;
    },
    setIsOpenUnableJoinTeamModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenUnableJoinTeamModal = payload;
    },
    setIsOpenRequestUpgradeModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenRequestUpgradeModal = payload;
    },
    setIsGetEnterpriseModalOpen: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isGetEnterpriseModalOpen = payload;
    },
    setIsOpenAddSeatsCheckout: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenAddSeatsCheckout = payload;
    },
    setIsOpenPurchaseSeatsModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenPurchaseSeatsModal = payload;
    },
    setIsOpenJoinWorkspaceModal: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isOpenJoinWorkspaceModal = payload;
    },
    setSelectedNumberOfSeatsToBuy: (
      state,
      { payload }: PayloadAction<number | undefined>,
    ) => {
      state.selectedNumberOfSeatsToBuy = payload;
    },
    setIsFirstInviteUsersRequest: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isFirstInviteUsersRequest = payload;
    },
    setInvitationAccessDeniedModalState: (
      state,
      { payload }: PayloadAction<InvitationAccessDeniedModal>,
    ) => {
      state.invitationAccessDeniedModal = payload;
    },
  },
});

export const teamsState = (state: RootState) => state.teams as TeamsState;

export const {
  setIsOpenInviteUsersModal,
  setIsOpenJoinWorkspaceModal,
  setSelectedNumberOfSeatsToBuy,
  setIsFirstInviteUsersRequest,
  setInvitationAccessDeniedModalState,
  setIsOpenRequestUpgradeModal,
  setIsOpenPurchaseSeatsModal,
  setIsOpenAddSeatsCheckout,
  setIsGetEnterpriseModalOpen,
  setIsOpenUnableJoinTeamModal,
} = teamsSlice.actions;

export default teamsSlice.reducer;
