import { useConversationsFiltering } from 'src/hooks';
import styles from './EmptyContainer.module.scss';
import { Broom, ChatsCircle } from '@phosphor-icons/react';
import { SVG_SIZE_XXL } from 'src/constants';
import { EmptyFilteredList } from '../EmptyFilteredList';

const NO_RESULTS_MESSAGE = `There are no matches for your criteria.`;
const DEFAULT_MESSAGE = `Starting a chat with Ninja creates new conversation threads. Dive in and chat whenever you are ready.`;

export const EmptyContainer = () => {
  const { taskStatusFilter, taskSkillFilter } = useConversationsFiltering();

  if (!!taskSkillFilter) {
    return <EmptyFilteredList />;
  }

  if (!!taskStatusFilter) {
    return (
      <div className={styles.root}>
        <span>
          <Broom size={SVG_SIZE_XXL} weight="light" />
        </span>
        <span>{NO_RESULTS_MESSAGE}</span>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <span>
        <ChatsCircle size={SVG_SIZE_XXL} weight="light" />
      </span>
      <span>{DEFAULT_MESSAGE}</span>
    </div>
  );
};
