import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ApiTask,
  ConversationList,
  FilteredTasksByPageRequest,
  UserConversationId,
  TaskRequestArgs,
} from 'src/types';
import { baseTasksQuery } from './config';
import { DEFAULT_PAGE_LIMIT, DEFAULT_SORT_ORDER } from 'src/constants';

export enum TasksTags {
  FilteredConversationTasks = 'FilteredConversationTasks',
  TaskById = 'TaskById',
}

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  // TODO(olha): probably should use baseUsersV2Query
  baseQuery: baseTasksQuery,
  tagTypes: [TasksTags.FilteredConversationTasks, TasksTags.TaskById],
  endpoints: (builder) => ({
    /**
     * @deprecated
     */
    getFilteredTasks: builder.query<
      ConversationList,
      FilteredTasksByPageRequest
    >({
      query: ({ user_id, page_token = '', filter_state, filter_skills }) => ({
        url: `users/${user_id}/tasks`,
        params: {
          sort_order: DEFAULT_SORT_ORDER,
          limit: DEFAULT_PAGE_LIMIT,
          filter_state,
          page_token,
          filter_skills,
        },
      }),
      transformResponse: (response: ConversationList) => {
        return {
          ...response,
          data: [...response.data].sort(
            (a, b) =>
              Date.parse(b.timestamp || '') - Date.parse(a.timestamp || ''),
          ),
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { filter_state, filter_skills, filter_states } = queryArgs;
        return { filter_state, filter_skills, filter_states };
      },
      merge: (currentCache, newData, meta) => {
        if (!meta.arg.page_token) {
          currentCache.data = newData.data;
        } else {
          currentCache.data.push(...newData.data);
        }

        currentCache.page_token = newData.page_token;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          !!currentArg?.page_token &&
          currentArg?.page_token !== previousArg?.page_token
        );
      },
      providesTags: [TasksTags.FilteredConversationTasks],
    }),

    getTaskById: builder.query<ApiTask, TaskRequestArgs>({
      query: ({ task_id, user_id }) => ({
        url: `/users/${user_id}/tasks/${task_id}`,
        params: {
          show_messages: true,
        },
      }),
      providesTags: (_, __, { task_id }) => {
        return [{ type: TasksTags.TaskById, id: task_id }];
      },
    }),

    updateTaskField: builder.mutation<
      ApiTask,
      { user_id: string; task_id: string; task: Partial<ApiTask> }
    >({
      query: ({ user_id, task_id, task }) => ({
        url: `/users/${user_id}/tasks/${task_id}`,
        method: `PATCH`,
        body: task,
      }),
    }),

    // TODO(olha): move to conversationApi
    setAllTasksReadInThread: builder.mutation<void, UserConversationId>({
      query: ({ userId, conversationId }) => ({
        url: `/users/${userId}/conversations/${conversationId}/mark_no_attention_required`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  /**
   * @deprecated
   */
  useGetFilteredTasksQuery,
  useGetTaskByIdQuery,
  useLazyGetTaskByIdQuery,
  useUpdateTaskFieldMutation,
  useSetAllTasksReadInThreadMutation,
} = tasksApi;
