import classNames from 'classnames';
import {
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsResolutions,
} from 'src/types';
import { Icon } from 'src/components/Icon';
import { SVG_SIZE_L, SVG_SIZE_S } from 'src/constants';
import styles from './DimensionPrimaryItem.module.scss';

interface DimensionPrimaryItemProps {
  data: ImageGenSettingsResolutions;
  isSelected: boolean;
  onClick: () => void;
}

const IconsSet: Record<string, React.ReactElement> = {
  [ImageGenSettingsDimensionOptionTypes.SQUARE]: (
    <Icon type="square" size={SVG_SIZE_L} />
  ),
  [ImageGenSettingsDimensionOptionTypes.PORTRAIT]: (
    <Icon type="portrait" size={SVG_SIZE_S} />
  ),
  [ImageGenSettingsDimensionOptionTypes.LANDSCAPE]: (
    <Icon type="landscape" size={SVG_SIZE_L} />
  ),
};

export const DimensionPrimaryItem = ({
  data,
  isSelected,
  onClick,
}: DimensionPrimaryItemProps) => {
  const { name, aspect_ratio } = data;

  const CurrentIcon = IconsSet[name.toLowerCase()];

  const handleDisablingCheck = (data: ImageGenSettingsResolutions) => {
    return data.resolutions && Object.values(data.resolutions).length > 0
      ? Object.values(data.resolutions || {}).every(
          ({ is_capable }) => !is_capable,
        )
      : true;
  };

  const isDisabled = handleDisablingCheck(data);

  return (
    <button
      onClick={onClick}
      className={classNames(styles.root, {
        [styles.active]: isSelected,
        [styles.disabled]: isDisabled,
      })}
      disabled={isDisabled}
      data-e2e={`image-dimension-${name}-button`}
    >
      <span className={styles.icon}>{CurrentIcon}</span>
      <span className={styles.subtitle}>{aspect_ratio}</span>
    </button>
  );
};
