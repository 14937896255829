import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { TaskStatusFilter, TaskSkill } from 'src/types';

interface ConversationsList {
  pageToken: string;
  taskSkillFilter?: TaskSkill[];
  taskStatusFilter?: TaskStatusFilter;
}

const initialState: ConversationsList = {
  pageToken: '',
  taskSkillFilter: undefined,
  taskStatusFilter: undefined,
};

export const conversationsListSlice = createSlice({
  name: 'conversationsList',
  initialState,
  reducers: {
    setPageToken: (state, { payload }: PayloadAction<string>) => {
      state.pageToken = payload;
    },
    setTaskStatusFilter: (
      state,
      { payload }: PayloadAction<TaskStatusFilter | undefined>,
    ) => {
      state.pageToken = '';
      state.taskSkillFilter = undefined;
      state.taskStatusFilter = payload;
    },
    setTaskSkillFilter: (
      state,
      { payload }: PayloadAction<TaskSkill | TaskSkill[] | undefined>,
    ) => {
      state.pageToken = '';
      state.taskStatusFilter = undefined;
      state.taskSkillFilter = Array.isArray(payload)
        ? payload
        : !!payload
          ? [payload]
          : undefined;
    },
  },
});

export const conversationsListState = (state: RootState) =>
  state.conversationsList as ConversationsList;

export const { setPageToken, setTaskSkillFilter, setTaskStatusFilter } =
  conversationsListSlice.actions;

export default conversationsListSlice.reducer;
