import styles from './UnableJoinTeamModal.module.scss';
import { Modal } from 'src/components/Modal';
import { useUnableJoinTeamModal } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import { INVITATION_TOKEN } from 'src/constants';

export const UnableJoinTeamModal = () => {
  const { onChangeIsOpenUnableJoinTeamModal, isOpenUnableJoinTeamModal } =
    useUnableJoinTeamModal();

  const handleClose = () => {
    onChangeIsOpenUnableJoinTeamModal(false);
    localStorage.removeItem(INVITATION_TOKEN);
  };

  return (
    <Modal
      open={isOpenUnableJoinTeamModal}
      onClose={handleClose}
      className={styles.unableJoinTeamModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Unable to join the Workspace</h4>
        <span className={styles.text}>
          Please cancel your existing subscription and then accept the new
          workspace invite.
        </span>
        <div className={styles.buttonRow}>
          <Button onClick={handleClose} className={styles.button}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
