import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { Icon } from 'src/components/Icon';
import { Check } from '@phosphor-icons/react';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { SVG_SIZE_M, SVG_SIZE_S } from 'src/constants';

export const WELCOME_UPSELL_MODAL_FREE_FEATURES = [
  { title: '10 tasks per day' },
  { title: 'Writing & online research' },
  { title: 'Code generation' },
  { title: 'Basic Image Generation' },
];

export const WELCOME_UPSELL_MODAL_FREE_FEATURES_SHORT = [
  { title: '10 tasks per day' },
];

export const WELCOME_UPSELL_MODAL_STANDARD_FEATURES = [
  { title: '720p (HD) image generation' },
  { title: '2 image aspect ratios' },
  { title: 'Creative and professional writing' },
  { title: 'Code generation and debugging' },
  { title: 'Online research' },
];

export const WELCOME_UPSELL_MODAL_STANDARD_FEATURES_SHORT = [
  { title: 'Unlimited daily tasks' },
  { title: 'Research & Writing' },
  { title: 'Code Generation' },
  { title: 'Image Generation' },
  { title: 'Meeting Scheduling' },
];

export const WELCOME_UPSELL_MODAL_PRO_FEATURES = [
  { title: '1080p (Full HD) image generation' },
  { title: '11 image aspect ratios' },
  { title: 'Advanced online research' },
  { title: 'Advanced AI image editing' },
  // { title: 'Multi-party meeting scheduling' },
  { title: 'File upload analysis up to ~150k words across 35+ file types' },
];

export const WELCOME_UPSELL_MODAL_ULTRA_FEATURES = [
  { title: '2k (Quad HD) image generation' },
  { title: 'Advanced AI image editing' },
  { title: 'Video generation (Beta)' },
  { title: 'Advanced coding (coming soon)' },
  { title: 'File upload analysis up to ~1.5M words across 35+ file types' },
];

export const WELCOME_UPSELL_MODAL_ULTRA_FEATURES_SHORT = [
  { title: 'File Upload Analysis' },
  { title: 'Advanced Image Settings' },
  { title: 'Unlimited Access to 20 AI models' },
];

export const EXTERNAL_MODELS_FREE_LIST = [
  {
    title: 'Unlimited daily tasks',
    icon: <Check size={SVG_SIZE_S} />,
  },
  {
    title: 'File upload analysis',
    icon: <Check size={SVG_SIZE_S} />,
  },
  {
    title: 'Custom image settings',
    icon: <Check size={SVG_SIZE_S} />,
  },
  {
    title: 'Access up to 20 leading AI models',
    icon: <Check size={SVG_SIZE_S} />,
  },
];
export const EXTERNAL_MODELS_STANDARD_LIST = [
  {
    title: 'Fine-tuned Llama 3.3 70B',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'Ninja Pix (PixArt-Sigma)',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'Ninja Flux (FLUX.1)',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
];

export const EXTERNAL_MODELS_PRO_LIST = [
  {
    title: 'Fine-tuned Llama 3.3 70B',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'Ninja Pix (PixArt-Sigma)',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'Ninja Flux (FLUX.1)',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'OpenAI GPT-4o Mini',
    icon: <ChatGPTLogo />,
  },
  {
    title: 'Claude 3.5 Haiku',
    icon: <Icon type="claudeLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'Google Gemini 1.5 Flash',
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE_M} />,
  },
];

export const EXTERNAL_MODELS_ULTRA_LIST = [
  {
    title: 'Fine-tuned Llama 3.3 70B',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'Ninja Pix (PixArt-Sigma)',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'Ninja Flux (FLUX.1)',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
  },
  {
    title: 'DeepSeek V3',
    icon: <Icon type="deepSeekLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'DeepSeek R1',
    icon: <Icon type="deepSeekLogo" size={SVG_SIZE_M} />,
  },
  {
    title: 'OpenAI GPT-4o',
    icon: <ChatGPTLogo />,
  },
  {
    title: 'Amazon Nova Pro',
    icon: <Icon type="nova" size={SVG_SIZE_M} />,
  },
  {
    title: 'Anthropic Claude 3.5 Sonnet',
    icon: <Icon type="claudeLogo" size={SVG_SIZE_M} />,
  },
];
