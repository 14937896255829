import { SVGProps } from 'react';

export const Square = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="19.5"
        y="4.5"
        width="15"
        height="15"
        rx="1.5"
        transform="rotate(90 19.5 4.5)"
        fillOpacity="0.4"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
