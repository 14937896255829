import styles from './ChatThreadModelSelector.module.scss';
import { useRef } from 'react';
import { Button } from 'src/v2/commonComponents/Button';
import { superAgentModelSet, SVG_SIZE_M, SVG_SIZE_XL } from 'src/constants';
import { Icon } from 'src/components/Icon';
import {
  useBreakpoint,
  useOutsideClick,
  useSuperAgentModel,
  useVisible,
} from 'src/hooks';
import { CaretDown } from '@phosphor-icons/react';
import cn from 'classnames';
import { SuperAgentSelect } from './components';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';

const buttonId = 'chat-model-selector';

export const ChatThreadModelSelector = () => {
  const { isMobileOrTablet } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();
  const { activeSuperAgentModel } = useSuperAgentModel();

  useOutsideClick(containerRef, onVisibilityRemove);

  return (
    <div className={styles.root} ref={containerRef}>
      <Button
        color="transparent"
        onClick={onVisibilityToggle}
        id={buttonId}
        className={cn(styles.button, { [styles.buttonSelected]: isVisible })}
      >
        <div className={styles.buttonContent}>
          <Icon type="ninjaMainIcon" size={SVG_SIZE_XL} />
          <span className={styles.buttonTitle}>
            {activeSuperAgentModel !== null
              ? superAgentModelSet[activeSuperAgentModel].title
              : 'Ninja Orchestrator'}
          </span>
          <CaretDown size={SVG_SIZE_M} />
        </div>
      </Button>

      <ReactTooltip
        id={buttonId}
        className={styles.modelsTooltip}
        isOpen={isVisible}
        place={isMobileOrTablet ? 'bottom' : 'bottom-start'}
      >
        <SuperAgentSelect onClose={onVisibilityRemove} />
      </ReactTooltip>
    </div>
  );
};
