import { ChangeEvent, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useSubmitUserInput, usePasteUserInput } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './EditableMessageInput.module.scss';

const MAX_INPUT_ROWS = 6;

interface EditableMessageInputProps {
  defaultContent: string;
  onCancel: () => void;
}

export const EditableMessageInput = ({
  defaultContent,
  onCancel,
}: EditableMessageInputProps) => {
  const { onSubmitUserInput } = useSubmitUserInput();
  const { onPasteUserInput } = usePasteUserInput();

  const [value, setValue] = useState<string>(defaultContent);

  const handleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(target.value);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = () => {
    onSubmitUserInput(value);
    onCancel();
  };

  return (
    <div className={styles.root}>
      <div className={styles.textAreaWrapper}>
        {/* TODO(olha): create a common component */}
        <TextareaAutosize
          value={value}
          minRows={1}
          maxRows={MAX_INPUT_ROWS}
          className={styles.textArea}
          onChange={handleChange}
          onPaste={onPasteUserInput}
        />
      </div>

      <div className={styles.actions}>
        <Button
          onClick={handleCancel}
          className={styles.cancelButton}
          color="link"
        >
          Cancel
        </Button>

        <Button disabled={defaultContent === value} onClick={handleSubmit}>
          Regenerate
        </Button>
      </div>
    </div>
  );
};
