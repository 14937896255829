import { SkillSectionWrapper } from '../SkillSectionWrapper';
import { SCHEDULER } from 'src/constants/externalLinks';
import { UpcomingSettingsMessage } from '../UpcomingSettingsMessage';

export const SchedulerSection = () => {
  return (
    <SkillSectionWrapper>
      <UpcomingSettingsMessage
        learnMoreLink={SCHEDULER}
        sectionTitle="scheduler"
      />
    </SkillSectionWrapper>
  );
};
