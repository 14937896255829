import { ShareFat } from '@phosphor-icons/react';
import { useLazyCreateMessageSharedLinkQuery } from 'src/store/services/sharingApi';
import { useSession, useVisible } from 'src/hooks';
import { ShareModal } from './components/ShareModal';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';

type ShareButtonProps = {
  messageId?: string;
  disabled?: boolean;
};

export const ShareButton = ({ messageId, disabled }: ShareButtonProps) => {
  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible();

  const { appUser } = useSession();
  const [
    createMessageLink,
    { data: shareMessageLink, isLoading: isMessageLinkLoading },
  ] = useLazyCreateMessageSharedLinkQuery();

  const handleClose = () => {
    onVisibilityRemove();
  };

  const handleShareButtonClick = async () => {
    sendGTMEvent(GTMEvent.SHARE_BUTTON);
    onVisibilitySet();

    if (messageId) {
      return await createMessageLink({
        userId: appUser.user_id,
        messageId: messageId,
      });
    }
  };

  return (
    <>
      <Button
        type="button"
        disabled={disabled || isMessageLinkLoading}
        onClick={handleShareButtonClick}
        data-e2e="share-button"
        color="transparent"
      >
        <ShareFat size={SVG_SIZE_M} />
      </Button>

      <ShareModal
        isOpen={isVisible}
        isLoading={isMessageLinkLoading}
        shareLink={shareMessageLink}
        onClose={handleClose}
      />
    </>
  );
};
