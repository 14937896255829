import { memo, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {
  isChitChatCard,
  isImageGenerationCard,
  isResearchTaskCreationCard,
  Message,
  TaskSkill,
  MessageType,
  ChitChatCard,
  TaskState,
  ErrorPayload,
} from 'src/types';
import {
  useSession,
  useCurrentConversationTasks,
  useLeftPanelState,
  useBreakpoint,
} from 'src/hooks';
import { MessageAuthorPicture } from '../MessageAuthorPicture';
import { ThreadMessageBody } from './components/ThreadMessageBody';
import { RelatedQuestions } from 'src/components/RelatedQuestions';
import { MessageProgress } from './components/MessageProgress';
import styles from './ThreadMessage.module.scss';
import { BlockedTaskMessage } from './components/BlockedTaskMessage';
import { MockedVideoProgress } from './components/MockedVideoProgress';
import { MessageActions } from './components/MessageActions';
import className from 'classnames';

interface ThreadMessageProps {
  message: Message;
  canShowProgress?: boolean;
}

const ThreadMessageComponent = ({
  message,
  canShowProgress,
}: ThreadMessageProps) => {
  const [messageToShow, setMessageToShow] = useState(message);
  const { isOpenTier, isStandardTier, isFreeTier, isTeamsStandardTier } =
    useSession();
  const { getTaskFromConversationById } = useCurrentConversationTasks();
  const { isLeftPanelExpanded } = useLeftPanelState();
  const { isDesktopSmallChat } = useBreakpoint();

  const {
    message_type,
    is_final_answer,
    payload: originalPayload,
    message_id,
  } = message;

  // (olha)(!!!): we have to use original is_final_answer only in this condition
  // TODO: stan - Olha, let's discuss this change (adding IMAGE_CARD to the condition)
  const isFinalMessageChunk = useMemo(() => {
    if (
      message_type !== MessageType.CHAT_CARD &&
      message_type !== MessageType.IMAGE_CARD
    ) {
      return is_final_answer;
    }

    if (!(originalPayload as ChitChatCard)?.task_id) {
      return is_final_answer;
    }

    const messageTaskState = getTaskFromConversationById(
      (originalPayload as ChitChatCard)?.task_id,
    )?.state;

    return messageTaskState
      ? messageTaskState !== TaskState.IN_PROGRESS
      : false;
  }, [
    is_final_answer,
    message_type,
    originalPayload,
    getTaskFromConversationById,
  ]);

  const throttleMessage = useRef(
    throttle((newMessage: Message) => {
      setMessageToShow(newMessage);
    }, 500),
  );

  useEffect(() => {
    throttleMessage.current(message);
  }, [message]);

  useEffect(() => {
    if (isFinalMessageChunk) {
      setMessageToShow(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinalMessageChunk]);

  const { payload } = messageToShow;

  const relatedQuestions = useMemo(() => {
    if (isResearchTaskCreationCard(payload)) {
      return payload?.research_card?.data?.related_questions;
    }

    if (
      isChitChatCard(payload) &&
      payload.data?.skill !== TaskSkill.DOCUMENT_QA
    ) {
      return payload?.data?.related_questions;
    }

    if (
      isChitChatCard(payload) &&
      payload.data?.skill === TaskSkill.DOCUMENT_QA &&
      !(isFreeTier || isStandardTier || isOpenTier || isTeamsStandardTier)
    ) {
      return payload?.data?.related_questions;
    }

    if (isImageGenerationCard(payload)) {
      return payload?.related_questions;
    }
  }, [payload, isOpenTier, isStandardTier, isFreeTier, isTeamsStandardTier]);

  const messageProgressCustomComponent = (
    payload as ErrorPayload
  )?.unsupported_skills?.includes(TaskSkill.VIDEO) ? (
    <MockedVideoProgress />
  ) : undefined;

  return (
    <div
      className={className(styles.root, {
        [styles.smallWidth]: isLeftPanelExpanded && isDesktopSmallChat,
      })}
    >
      <div className={styles.header}>
        <MessageAuthorPicture
          message={messageToShow}
          canShowProgress={canShowProgress}
        />

        <MessageActions
          messageId={message_id}
          isFinalMessageChunk={isFinalMessageChunk}
        />
      </div>

      <div className={styles.container}>
        <MessageProgress
          message={messageToShow}
          customComponent={messageProgressCustomComponent}
        />

        {messageToShow.message_type ===
          MessageType.SCHEDULER_TASK_CREATION_CARD && (
          <BlockedTaskMessage message={messageToShow} />
        )}

        <ThreadMessageBody
          message={messageToShow}
          isFinalMessageChunk={isFinalMessageChunk}
        />

        <RelatedQuestions
          data={relatedQuestions}
          defaultExpanded={canShowProgress}
          title={
            isImageGenerationCard(payload)
              ? 'Related prompts'
              : 'Related questions'
          }
        />
      </div>
    </div>
  );
};

export const ThreadMessage = memo(
  ThreadMessageComponent,
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.message, nextProps.message) &&
      prevProps.canShowProgress === nextProps.canShowProgress
    );
  },
);
