import { isIOS, isMobile, isTablet } from 'react-device-detect';
import classNames from 'classnames';
import { Icon } from 'src/components/Icon';
import { SVG_SIZE_S } from 'src/constants';
import { IOS_MOBILE_APP_LINK } from 'src/constants/externalLinks';
import styles from './DownloadMobileAppBanner.module.scss';

// (irina): component is used to show a banner to download the IOS app (android will be displayed later)
export const DownloadMobileAppBanner = () => {
  if (!isIOS && !(isMobile || isTablet)) {
    return null;
  }

  const title = `Get Ninja for ${isIOS ? 'iPhone' : 'Android'}`;
  const link = isIOS ? IOS_MOBILE_APP_LINK : '';

  return (
    <div className={classNames('nj-common-banner', styles.root)}>
      <div className="nj-common-banner--left-side-wrapper">
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">{title}</h5>
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        <a
          key={title}
          href={link}
          className="nj-button nj-banner--renew-button nj-button--app"
          target="_blank"
          rel="noreferrer"
        >
          <Icon type={isIOS ? 'ios' : 'android'} size={SVG_SIZE_S} />
          <span>Get app</span>
        </a>
      </div>
    </div>
  );
};
