import { ReactNode } from 'react';
import { Icon as PhosphorIcon, Gear } from '@phosphor-icons/react';
import { Tabs, Tab, TabList, TabPanel } from 'src/v2/commonComponents/Tabs';
import { SVG_SIZE_M } from 'src/constants';
import styles from './SkillSectionWrapper.module.scss';
import { ConversationsList } from 'src/v2/components/ConversationsList';

enum SkillTabList {
  RECENT_TASKS = 'recent_tasks',
  SETTINGS = 'settings',
}

interface SkillTab {
  tabName: SkillTabList;
  tabTitle: string;
  IconComponent?: PhosphorIcon;
  TabPanelComponent: ReactNode;
}

interface SkillSectionWrapperProps {
  children: ReactNode;
  hasSettingsTab?: boolean;
}

export const SkillSectionWrapper = ({
  children,
  hasSettingsTab,
}: SkillSectionWrapperProps) => {
  const tabsData: SkillTab[] = [
    {
      tabName: SkillTabList.RECENT_TASKS,
      tabTitle: 'Recent tasks',
      TabPanelComponent: <ConversationsList />,
    },
    ...(hasSettingsTab
      ? [
          {
            tabName: SkillTabList.SETTINGS,
            tabTitle: 'Settings',
            IconComponent: Gear,
            TabPanelComponent: children,
          },
        ]
      : []),
  ];

  return (
    <Tabs<SkillTabList>
      defaultActiveTab={SkillTabList.RECENT_TASKS}
      className={styles.root}
    >
      {tabsData.length === 1 ? (
        <TabList className={styles.tabListSingle}>
          <Tab tab={tabsData[0].tabName} className={styles.singleTab}>
            <>{tabsData[0].tabTitle}</>
          </Tab>
        </TabList>
      ) : (
        <TabList className={styles.tabList}>
          {tabsData.map(({ tabName, tabTitle, IconComponent }) => (
            <Tab key={tabName} tab={tabName}>
              <>
                {IconComponent && <IconComponent size={SVG_SIZE_M} />}
                {tabTitle}
              </>
            </Tab>
          ))}
        </TabList>
      )}

      {tabsData.map(({ tabName, TabPanelComponent }) => (
        <TabPanel key={tabName} tab={tabName} className={styles.tabPanel}>
          {TabPanelComponent}
        </TabPanel>
      ))}
    </Tabs>
  );
};
