/// <reference types="gapi" />

import React, {
  createContext,
  useMemo,
  ReactNode,
  useCallback,
  useState,
  useEffect,
} from 'react';
import useLoadScript from 'src/hooks/useLoadScript';
import { env } from 'src/env';
import { useSession } from 'src/hooks';
import { isBetaEnv } from 'src/utils';

interface LoadedApis {
  drive?: boolean;
  drivePicker?: boolean;
  gapi?: boolean;
}

interface GoogleApiContextProps {
  loadedApis: LoadedApis;
  isGDriveEnabled: boolean;
}

const GoogleApiContext = createContext<GoogleApiContextProps>({
  loadedApis: {},
  isGDriveEnabled: false,
});

interface GoogleApiProviderProps {
  clientId: string;
  nonce?: string;
  children: ReactNode;
}

const GoogleApiProvider = ({ nonce, children }: GoogleApiProviderProps) => {
  const {
    appUser: { email, metadata },
  } = useSession();

  const [loadedApis, setLoadedApis] = useState<LoadedApis>({
    drive: false,
    drivePicker: false,
    gapi: false,
  });

  const [gapiScriptLoaded, setGapiScriptLoaded] = useState<boolean>(false);

  // Temporary: always enabled for Beta, google verification support email and any user that has gdrive_enabled: "true" in their metadata field
  const isGDriveEnabled = useMemo(() => {
    const gdriveEnabledForUser = metadata?.gdrive_enabled;
    return (
      isBetaEnv ||
      ['gillytully.846964@gmail.com'].includes(email) ||
      gdriveEnabledForUser === 'true'
    );
  }, [metadata, email]);

  const onPickerLoaded = useCallback(() => {
    setLoadedApis((prevState) => {
      return {
        ...prevState,
        drivePicker: true,
      };
    });
  }, []);
  const onGApiClientLoaded = useCallback(() => {
    const loadClients = async () => {
      try {
        await gapi.client.init({
          apiKey: env.REACT_APP_GOOGLE_API_KEY,
          discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
          ],
        });
        setLoadedApis((prevState) => {
          return {
            ...prevState,
            gapi: true,
            drive: true,
          };
        });
      } catch {
        console.warn('Unable to init gapi client');
      }
    };
    loadClients();
  }, []);

  useEffect(() => {
    if (gapiScriptLoaded && isGDriveEnabled) {
      gapi.load('picker', onPickerLoaded);
      gapi.load('client', onGApiClientLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gapiScriptLoaded, isGDriveEnabled]);

  const onScriptLoadSuccess = useCallback(() => {
    setGapiScriptLoaded(true);
  }, []);

  useLoadScript({
    src: 'https://apis.google.com/js/api.js',
    nonce,
    onScriptLoadSuccess,
  });

  const contextValue = useMemo(
    () => ({
      loadedApis,
      isGDriveEnabled,
    }),
    [loadedApis, isGDriveEnabled],
  );

  return (
    <GoogleApiContext.Provider value={contextValue}>
      {children}
    </GoogleApiContext.Provider>
  );
};

export { GoogleApiProvider };
export default GoogleApiContext;
