import { useCallback, useMemo } from 'react';
import {
  useAppSelector,
  teamsState,
  useAppDispatch,
  setIsOpenUnableJoinTeamModal,
} from 'src/store';

export const useUnableJoinTeamModal = () => {
  const { isOpenUnableJoinTeamModal } = useAppSelector(teamsState);
  const dispatch = useAppDispatch();

  const onChangeIsOpenUnableJoinTeamModal = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenUnableJoinTeamModal(value));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      isOpenUnableJoinTeamModal,
      onChangeIsOpenUnableJoinTeamModal,
    }),
    [isOpenUnableJoinTeamModal, onChangeIsOpenUnableJoinTeamModal],
  );
};
