import { Modal } from 'src/components/Modal';
import { LoadingContent } from '../LoadingContent';
import { ShareLinks } from '../ShareLinks';

interface ShareModalProps {
  isOpen: boolean;
  isLoading: boolean;
  shareLink?: string;
  onClose: () => void;
}

export const ShareModal = ({
  isOpen,
  isLoading,
  shareLink,
  onClose,
}: ShareModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose} className="nj-share--modal">
      <h5 className="nj-share--title">Public link to share</h5>

      {isLoading && <LoadingContent />}

      {!!shareLink && <ShareLinks link={shareLink} />}
    </Modal>
  );
};
