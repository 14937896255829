import { SkillSectionWrapper } from '../SkillSectionWrapper';
import { FILE_AGENT } from 'src/constants/externalLinks';
import { UpcomingSettingsMessage } from '../UpcomingSettingsMessage';

export const FileAgentSection = () => {
  return (
    <SkillSectionWrapper>
      <UpcomingSettingsMessage
        learnMoreLink={FILE_AGENT}
        sectionTitle="file-agent"
      />
    </SkillSectionWrapper>
  );
};
