import { useMemo } from 'react';
import { Hash } from '@phosphor-icons/react';
import {
  useBreakpoint,
  useCurrentConversation,
  useLeftPanelState,
  useRightSidePanelState,
} from 'src/hooks';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import styles from './ConversationsHeader.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { ChatThreadModelSelector } from 'src/v2/components/ChatThreadModelSelector';
import cn from 'classnames';
import { isBetaEnv } from 'src/utils';

export const ConversationsHeader = () => {
  const { isMobileOrTablet, isDesktopAndUp } = useBreakpoint();
  const { currentConversation } = useCurrentConversation();
  const { isLeftPanelExpanded } = useLeftPanelState();
  const { isRightPanelExpanded } = useRightSidePanelState();

  const headerTitle = useMemo(
    () => currentConversation?.conversation_hash?.replace('#', '') || '',
    [currentConversation],
  );

  return (
    <header className={styles.root}>
      {!isBetaEnv ? (
        <div className={styles.actionWrapper}>
          {!isLeftPanelExpanded && isMobileOrTablet && <MainNavToggleButton />}
        </div>
      ) : (
        !isLeftPanelExpanded &&
        isMobileOrTablet && (
          <div className={styles.actionWrapper}>
            <MainNavToggleButton />
          </div>
        )
      )}

      {isBetaEnv && <ChatThreadModelSelector />}

      {headerTitle && isDesktopAndUp && (
        <h4 className={styles.desktopTitle}>
          <Hash size={SVG_SIZE_M} />
          <span className={styles.center}>{headerTitle}</span>
        </h4>
      )}

      <div
        className={cn(styles.actionWrapper, styles.rightSideButtons, {
          [styles.actionWrapperDesktopWidth]:
            !isMobileOrTablet && !isRightPanelExpanded && isBetaEnv, // todo it is temporary till we implement active button here
        })}
      >
        <AddConversationButton
          appearance="gradient-button"
          className="nj-thread-header--mobile-create-new-chat"
        />
      </div>
    </header>
  );
};
