import styles from './ConfirmInvitedUsersStep.module.scss';
import { Button } from 'src/v2/commonComponents/Button';
import { CaretLeft } from '@phosphor-icons/react';
import { LIMIT_NUMBER_OF_INVITED_EMAILS, SVG_SIZE_L } from 'src/constants';
import { useState } from 'react';
import {
  useSelectedNumberOfSeats,
  useSession,
  useSubscriptionInfo,
  useTeamInfo,
} from 'src/hooks';
import { CheckboxSimple } from 'src/v2/commonComponents/CheckboxSimple';
import log from 'src/utils/logger';
import {
  useInviteUsersMutation,
  useLazyGetSeatsListQuery,
} from 'src/store/services';
import { SeatRole } from 'src/types/models/SeatRole';
import { toast } from 'react-toastify';
import { SeatStatus } from 'src/types/models/SeatStatus';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, ManageUsersNestedRoutes } from 'src/types';
import { getAvailableSeatsCount } from 'src/utils';

type ConfirmInvitedUsersStepProps = {
  onGoBack: () => void;
  onClose: () => void;
  listOfEmails: string[];
  hasLimitError: boolean;
};

const errorMoreThenLimitText = `For large datasets, we can only process up to ${LIMIT_NUMBER_OF_INVITED_EMAILS} users at a time. If your file or email list contains more than ${LIMIT_NUMBER_OF_INVITED_EMAILS} users, we will only parse the first ${LIMIT_NUMBER_OF_INVITED_EMAILS} and add them to your workspace. To add the remaining users, please go through the workflow again and provide the next batch of up to ${LIMIT_NUMBER_OF_INVITED_EMAILS} users.`;

export const ConfirmInvitedUsersStep = ({
  onGoBack,
  onClose,
  listOfEmails,
  hasLimitError,
}: ConfirmInvitedUsersStepProps) => {
  const { appUser } = useSession();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  const { teamInfo } = useTeamInfo();
  const {
    subscriptionInfo,
    isAppleOrAmpPayment,
    isSubscriptionInfoLoading,
    isSubscriptionInfoFetching,
  } = useSubscriptionInfo();

  const [inviteUsers, { isLoading }] = useInviteUsersMutation();
  const [refetchSeats] = useLazyGetSeatsListQuery();

  const navigate = useNavigate();

  const { onChangeSelectedNumberOfSeatsToBuy } = useSelectedNumberOfSeats();

  const availableNumberOfSeats = getAvailableSeatsCount(teamInfo);

  const isMoreSelectedThenPossible =
    selectedEmails.length > availableNumberOfSeats;

  const isSendButtonDisabled =
    selectedEmails.length === 0 || isMoreSelectedThenPossible;

  const handleSendInvite = async () => {
    try {
      // todo it is temporary
      // const data = await inviteUsers({
      onClose();
      const toastProcessingInvite = toast('Processing the invites');
      const data = await inviteUsers({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        users: selectedEmails.map((item) => ({
          email: item,
          role: SeatRole.MEMBER,
        })),
      }).unwrap();

      toast.dismiss(toastProcessingInvite);

      refetchSeats({
        user_id: appUser.user_id,
        team_id: teamInfo?.team_id || '',
        page_token: '',
        statuses: SeatStatus.INVITE_PENDING,
      });
      if (data.invited_seats.length > 0) {
        toast.success(
          "We've sent out the invitations for your \n" +
            'team to join the workspace.',
        );
      }
      if (data.failed.length > 0) {
        data.failed.forEach((item) => {
          toast.error(item.failed_reason);
        });
      }
    } catch (e) {
      log.error('error', e);
      onClose();
      toast.error('Something went wrong, users were not invited.');
    }
  };

  const handleChangeSelectAll = () => {
    if (selectedEmails.length === listOfEmails.length) {
      setSelectedEmails([]);
    } else {
      setSelectedEmails(listOfEmails);
    }
  };

  const handleChangeCheckboxValue = (item: string) => {
    if (selectedEmails.includes(item)) {
      setSelectedEmails(selectedEmails.filter((email) => email !== item));
    } else {
      setSelectedEmails([...selectedEmails, item]);
    }
  };

  const handleOpenBuySeats = () => {
    if (selectedEmails.length > availableNumberOfSeats) {
      onClose();
      onChangeSelectedNumberOfSeatsToBuy(
        selectedEmails.length - availableNumberOfSeats,
      );
      navigate(
        `${AppRoutes.MANAGE_ACCOUNT}/${ManageUsersNestedRoutes.MANAGE_USERS}`,
      );
    }
  };

  const isAddSeatsDisabled =
    isSubscriptionInfoLoading ||
    isSubscriptionInfoFetching ||
    subscriptionInfo?.has_scheduled_downgrade ||
    subscriptionInfo?.is_cancelled ||
    subscriptionInfo?.has_scheduled_plan_change ||
    subscriptionInfo?.processing_plan_change ||
    isAppleOrAmpPayment;

  return (
    <div className={styles.root}>
      <div className={styles.titleRow}>
        <Button color="transparent" onClick={onGoBack}>
          <CaretLeft size={SVG_SIZE_L} />
        </Button>
        <span className={styles.title}>Confirm user details</span>
      </div>
      <span className={styles.description}>
        Seats available in workspace: {availableNumberOfSeats}
      </span>
      <div className={styles.emailsListWrapper}>
        <div className={styles.listHeader}>
          <span>Users ({selectedEmails.length})</span>
          <CheckboxSimple
            isChecked={selectedEmails.length === listOfEmails.length}
            onChange={handleChangeSelectAll}
            className={styles.checkbox}
          />
        </div>
        <div className={styles.listBody}>
          {listOfEmails.map((item, index) => (
            <div className={styles.listRowWrapper} key={`${item}_${index}`}>
              <div className={styles.listRow}>
                <span className={styles.listItemEmail}>{item}</span>
                <CheckboxSimple
                  isChecked={selectedEmails.includes(item)}
                  onChange={() => handleChangeCheckboxValue(item)}
                  className={styles.checkbox}
                />
              </div>
              {index + 1 !== listOfEmails.length && (
                <hr className={styles.divider} />
              )}
            </div>
          ))}
        </div>
      </div>
      {isMoreSelectedThenPossible && (
        <div className={styles.errorRow}>
          <span className={styles.errorText}>
            Seat limit exceeded. Unselect users to meet current seat count or
            add seats to continue.
          </span>
          <Button
            onClick={handleOpenBuySeats}
            color="secondary"
            disabled={isAddSeatsDisabled}
          >
            Add seats
          </Button>
        </div>
      )}
      {hasLimitError && (
        <span className={styles.descriptionText}>{errorMoreThenLimitText}</span>
      )}
      <div className={styles.buttonRow}>
        <Button
          color="transparent"
          onClick={onClose}
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSendInvite}
          disabled={isSendButtonDisabled || isLoading}
        >
          Send invite
        </Button>
      </div>
    </div>
  );
};
