import { Button } from 'src/v2/commonComponents/Button';
import styles from './EmptyFilteredList.module.scss';
import { LeftPanelSections } from 'src/types';
import { useConversationActions, useLeftPanelActions } from 'src/hooks';

export const EmptyFilteredList = () => {
  const { setTaskSkillFilter } = useConversationActions();
  const { setLeftPanelData } = useLeftPanelActions();

  const handleClick = () => {
    setTaskSkillFilter();
    setLeftPanelData({
      panelType: LeftPanelSections.CONVERSATION_LIST,
      isExpanded: true,
    });
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>No recent tasks</p>

      <Button className={styles.button} onClick={handleClick} color="link">
        View all chats
      </Button>
    </div>
  );
};
