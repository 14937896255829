import styles from './ThinkingSection.module.scss';
import { useCollapse } from 'react-collapsed';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { ThinkingStep } from 'src/v2/components/ThinkingSection/components/ThinkingStep';
import { CotReasoning } from 'src/types/models/CotReasoning';
import { useEffect } from 'react';

type ThinkingSectionProps = {
  reasoning: CotReasoning;
  defaultExpanded?: boolean;
  isDone?: boolean;
};

export const ThinkingSection = ({
  reasoning,
  defaultExpanded,
  isDone,
}: ThinkingSectionProps) => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse({
      defaultExpanded,
    });

  useEffect(() => {
    if (isDone) {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button {...getToggleProps()} className={styles.collapseButton}>
          <span className={styles.buttonText}>
            Thinking {isDone ? 'done' : 'in progress...'}{' '}
            {reasoning?.time_secs && reasoning.time_secs > 0
              ? `in ${reasoning.time_secs} seconds`
              : ''}
          </span>
          {isExpanded ? (
            <CaretUp size={SVG_SIZE_M} />
          ) : (
            <CaretDown size={SVG_SIZE_M} />
          )}
        </button>
      </div>

      <div {...getCollapseProps()}>
        <div className={styles.collapseItemsBlock}>
          {Object.keys(reasoning.steps || {}).map((item) => {
            return (
              <ThinkingStep step={(reasoning?.steps || {})[item]} key={item} />
            );
          })}
        </div>
      </div>
    </div>
  );
};
