import { ACCEPTED_INVITE_MESSAGE_TEXT, INVITATION_TOKEN } from 'src/constants';
import { useEffect, useMemo, useState } from 'react';
import {
  useInvitationAccessDeniedModal,
  useInvitationInfo,
  useJoinWorkspaceModal,
  useUnableJoinTeamModal,
  useSession,
  useSubscriptionInfo,
  useTeamInfo,
} from 'src/hooks';
import log from 'src/utils/logger';
import { InvitationStatus } from 'src/types/models/InvitationStatus';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const useCheckIfUserInvitedToTeam = () => {
  const { appUser, isSubscribedTier } = useSession();
  const { onChangeIsOpenJoinWorkspaceModal } = useJoinWorkspaceModal();
  const invitationToken = localStorage.getItem(INVITATION_TOKEN);
  const { pathname } = useLocation();
  const { isOwner, teamInfo, isTeamInfoLoading, isTeamInfoFetching } =
    useTeamInfo();
  const { isAppleOrAmpPayment } = useSubscriptionInfo();
  const { onChangeIsOpenUnableJoinTeamModal } = useUnableJoinTeamModal();
  const {
    invitationInfo,
    onAcceptInvite,
    invitationError,
    isInvitationInfoLoading,
  } = useInvitationInfo({
    token: invitationToken,
  });
  const { onOpenInvitationDeniedAccessModal } =
    useInvitationAccessDeniedModal();
  const [isInviteLoading, setIsInviteLoading] = useState(false);

  const handleAccept = async () => {
    try {
      // localStorage.setItem(
      //   DO_NOT_SHOW_BASIC_WELCOME_MODAL,
      //   LOCAL_STORAGE_VALUE,
      // );
      await onAcceptInvite();
    } catch (e) {
      log.error(e);
    } finally {
      setIsInviteLoading(false);
    }
  };

  useEffect(() => {
    if (!!invitationToken && pathname !== AppRoutes.INVITE) {
      setIsInviteLoading(true);
    }
  }, [invitationToken, pathname]);

  useEffect(() => {
    if (!!invitationError) {
      setIsInviteLoading(false);
      localStorage.removeItem(INVITATION_TOKEN);
    }
  }, [invitationError]);

  useEffect(() => {
    if (
      !!invitationToken &&
      !!invitationInfo &&
      !invitationError &&
      !isInvitationInfoLoading &&
      pathname !== AppRoutes.INVITE
    ) {
      if (invitationInfo.status === InvitationStatus.ACCEPTED) {
        setIsInviteLoading(false);
        toast(ACCEPTED_INVITE_MESSAGE_TEXT);
        localStorage.removeItem(INVITATION_TOKEN);
      }

      if (
        invitationInfo.status === InvitationStatus.CANCELLED ||
        invitationInfo.status === InvitationStatus.EXPIRED
      ) {
        setIsInviteLoading(false);
        onOpenInvitationDeniedAccessModal(
          invitationInfo?.team_name || '',
          invitationInfo?.user_email || '',
        );
        localStorage.removeItem(INVITATION_TOKEN);
      }

      if (invitationInfo.status === InvitationStatus.ACTIVE) {
        if (invitationInfo.user_email === appUser.email) {
          if (isSubscribedTier) {
            setIsInviteLoading(false);
            if (!isTeamInfoLoading && !isTeamInfoFetching) {
              if (!!teamInfo) {
                if (
                  (isOwner && (teamInfo?.num_seats_assigned || 0) > 0) ||
                  isAppleOrAmpPayment
                ) {
                  onChangeIsOpenUnableJoinTeamModal(true);
                } else {
                  onChangeIsOpenJoinWorkspaceModal(true);
                }
              } else {
                if (isAppleOrAmpPayment) {
                  onChangeIsOpenUnableJoinTeamModal(true);
                } else {
                  onChangeIsOpenJoinWorkspaceModal(true);
                }
              }
            }
          } else {
            handleAccept();
          }
        } else {
          localStorage.removeItem(INVITATION_TOKEN);
          setIsInviteLoading(false); // todo check this case
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invitationToken,
    invitationInfo,
    appUser,
    isSubscribedTier,
    invitationError,
    isInvitationInfoLoading,
    pathname,
    teamInfo,
    isTeamInfoLoading,
    isTeamInfoFetching,
    isAppleOrAmpPayment,
  ]);

  return useMemo(
    () => ({
      isInviteLoading,
    }),
    [isInviteLoading],
  );
};
