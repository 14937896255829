import { useState, useEffect, useRef, memo } from 'react';
import { Markdown } from 'src/components/Markdown';
import { Citation } from 'src/types';
import { calculateRenderingChunkSize } from 'src/utils/streaming';

interface StreamingTextProps {
  content: string;
  messageId?: string;
  isStreaming?: boolean;
  references?: Citation[];
}

const StreamingTextComponent = ({
  content,
  messageId,
  isStreaming,
  references,
}: StreamingTextProps) => {
  // Initial streaming state to prevent re-renders after streaming ends.
  const [defaultStreamingValue] = useState(isStreaming);
  const [displayedText, setDisplayedText] = useState('');

  // reference to current displayed text, avoids dependency in useEffect.
  const displayedTextRef = useRef('');

  // RequestAnimationFrame ID for cancellation on unmount.
  const requestRef = useRef(0);
  const stoppedStreamingRef = useRef(false);

  const chunkSize = calculateRenderingChunkSize(content, displayedTextRef);

  const animate = () => {
    if (!!stoppedStreamingRef.current) {
      return;
    }

    // Check if the end of the content has not been reached yet
    if (displayedTextRef.current.length < content.length) {
      const chunk = content.slice(
        displayedTextRef.current.length,
        Math.min(content.length, displayedTextRef.current.length + chunkSize),
      );

      const newDisplayedText = `${displayedTextRef.current}${chunk || ''}`;
      displayedTextRef.current = newDisplayedText;

      setDisplayedText(newDisplayedText);

      // Schedule the next call of the animation
      requestRef.current = requestAnimationFrame(animate);
    } else {
      setDisplayedText(content);
    }
  };

  useEffect(() => {
    if (!defaultStreamingValue || !content) {
      // Return function for messages that don't require streaming
      return;
    }

    requestRef.current = requestAnimationFrame(animate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
        stoppedStreamingRef.current = true;
      }
    };
  }, []);

  return (
    <Markdown
      isStreaming={isStreaming}
      references={references}
      messageId={messageId}
    >
      {defaultStreamingValue ? displayedText : content}
    </Markdown>
  );
};

export const StreamingText = memo(StreamingTextComponent);
