import { Helmet } from 'react-helmet-async';
import { SEO_MAIN_PAGE_TITLE, SEO_MAIN_PAGE_DESCRIPTION } from 'src/constants';
import { env } from 'src/env';

export const MainPageHelmet = () => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{SEO_MAIN_PAGE_TITLE}</title>

      <meta
        content={SEO_MAIN_PAGE_DESCRIPTION}
        name="description"
        data-rh="true"
      />
      <meta content={SEO_MAIN_PAGE_TITLE} property="og:title" />
      <meta
        content={SEO_MAIN_PAGE_DESCRIPTION}
        property="og:description"
        data-rh="true"
      />
      <meta
        content={SEO_MAIN_PAGE_TITLE}
        property="twitter:title"
        data-rh="true"
      />
      <meta
        content={SEO_MAIN_PAGE_DESCRIPTION}
        property="twitter:description"
        data-rh="true"
      />

      <script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${env.REACT_APP_ZENDESK_KEY}`}
        async
      />
    </Helmet>
  );
};
