import classNames from 'classnames';
import styles from './BetaLabel.module.scss';

interface BetaLabelProps {
  className?: string;
}

export const BetaLabel = ({ className }: BetaLabelProps) => {
  return <span className={classNames(styles.root, className)}>Beta</span>;
};
