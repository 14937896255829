import { LearnMoreLink } from 'src/v2/commonComponents/LearnMoreLink';
import styles from './UpcomingSettingsMessage.module.scss';

interface UpcomingSettingsMessageProps {
  sectionTitle: string;
  learnMoreLink: string;
}

export const UpcomingSettingsMessage = ({
  sectionTitle,
  learnMoreLink,
}: UpcomingSettingsMessageProps) => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>Skill-specific preference are on the way!</p>

      <p className={styles.description}>
        We’re working hard to bring you more customizable options to enhance
        your experience—stay tuned!
      </p>

      <LearnMoreLink
        to={learnMoreLink}
        dataE2E={`learn-more-${sectionTitle}-link`}
        className={styles.link}
      />
    </div>
  );
};
