import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import {
  APPLE_PAYMENT_UPGRADE_ERROR_TEXT,
  SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
} from 'src/constants';
import { AppRoutes } from 'src/types';
import { useSession, useSubscriptionInfo } from 'src/hooks';
import styles from './UpsellMessage.module.scss';

type UpsellMessageProps = {
  title?: string;
  dataGTMOpenTier: string;
  dataGTMNinjaTier: string;
  description: string;
  className?: string;
  buttonStyle?: 'simple' | 'with-background';
  buttonLabel?: string;
};

export const UpsellMessage = ({
  title,
  dataGTMOpenTier,
  dataGTMNinjaTier,
  description,
  className,
  buttonStyle = 'simple',
  buttonLabel,
}: UpsellMessageProps) => {
  const { isOpenTier, isFreeTier, isProTrialTier, isProTier, isStandardTier } =
    useSession();
  const navigate = useNavigate();
  const { subscriptionInfo, isAppleOrAmpPayment } = useSubscriptionInfo();

  const hasPendingChanges =
    subscriptionInfo?.is_cancelled ||
    subscriptionInfo?.has_scheduled_downgrade ||
    subscriptionInfo?.has_scheduled_plan_change ||
    subscriptionInfo?.processing_plan_change;

  const handleUpgradeClick = () => {
    if (hasPendingChanges || isAppleOrAmpPayment) {
      toast.error(
        isAppleOrAmpPayment
          ? APPLE_PAYMENT_UPGRADE_ERROR_TEXT
          : SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
      );
    } else {
      navigate(AppRoutes.PAYMENT);
    }
  };

  const buttonText = buttonLabel || 'Upgrade';

  const buttonClasses = classNames(styles.buttonTryPro, {
    [styles.withBackground]: buttonStyle === 'with-background',
  });

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.textWrapper}>
        {title && <h4 className={styles.title}>{title}</h4>}
        <p className={title ? styles.description : styles.mainText}>
          {description}
        </p>
      </div>

      <div className={styles.buttonWrapper}>
        {isOpenTier && (
          <Link
            id={dataGTMOpenTier}
            to={AppRoutes.SIGN_UP}
            className={buttonClasses}
          >
            {buttonText}
          </Link>
        )}

        {(isFreeTier || isProTrialTier || isProTier || isStandardTier) && (
          <button
            type="button"
            onClick={handleUpgradeClick}
            className={buttonClasses}
            id={dataGTMNinjaTier}
            data-e2e="upgrade-account-button"
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};
