import { SkillSectionWrapper } from '../SkillSectionWrapper';
import { LEARN_MORE_VIDEO_GEN } from 'src/constants/externalLinks';
import { UpcomingSettingsMessage } from '../UpcomingSettingsMessage';

export const VideoGenSection = () => {
  return (
    <SkillSectionWrapper>
      <UpcomingSettingsMessage
        learnMoreLink={LEARN_MORE_VIDEO_GEN}
        sectionTitle="video"
      />
    </SkillSectionWrapper>
  );
};
