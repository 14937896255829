import { LlmResource } from './api';
import { Message } from 'src/types/models/Message';
import { ImageGenData } from 'src/types/models/ImageGenData';
import { Icon } from '@phosphor-icons/react';

export enum Side {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
  CENTER = 'center',
}

/**
 * @deprecated
 */
export enum ChatMode {
  CHAT = 'chat',
  AVATAR = 'avatar',
  // TODO(olha): deprecated
  CALENDAR = 'calendar',
  TASK = 'task',
}

/**
 * @deprecated
 */
export enum ChatModeSidePanel {
  NONE = 'none',
  SETTINGS = 'settings',
  CALENDAR_LLM = 'calendarLLM',
  AVATAR = 'avatar',
  ENVIRONMENT = 'environment',
  CAMERA_ANGLE = 'cameraAngle',
  MOVIE_STUDIO = 'movieStudio',
  SAVED_CONTENT = 'savedContent',
  PROFILE_INFO = 'profileInfo',
  AI_AGENT = 'aiAgent',
  CALENDAR_AGENT = 'calendarAgent',
  NINJA_AGENTS = 'ninjaAgents',
  EXTERNAL_MODELS = 'externalModels',
  VOICE_LANGUAGE = 'voiceLanguage',
  IMAGE_GENERATORS = 'imageGenerators',
  IMAGE_EDITOR = 'imageEditor',
}

export enum LeftPanelSections {
  CONVERSATION_LIST = 'conversationList',
  IMAGE_GEN = 'imageGen',
  WRITER_CODER = 'writerCoder',
  RESEARCHER = 'researcher',
  FILE_AGENT = 'fileAgent',
  VIDEO = 'video',
  SCHEDULER = 'scheduler',
  USER_MENU = 'userMenu',
  /**
   * @deprecated
   */
  AGENTS_SETTINGS = 'agentSettings',
  /**
   * @deprecated
   */
  EXTERNAL_MODELS = 'externalModels',
}

export enum RightPanelSections {
  IMAGE = 'image',
}

export enum SourceTypes {
  OPEN_AI = 'openai',
  ANTHROPIC = 'anthropic',
  GOOGLE = 'google',
  OPEN_AI_ENABLED = 'openai_enabled',
  ANTHROPIC_ENABLED = 'anthropic_enabled',
  GOOGLE_ENABLED = 'google_enabled',
}

export enum CodeSourceTypes {
  OPEN_AI = 'OPEN_AI',
  ANTHROPIC = 'ANTHROPIC',
  GOOGLE = 'GOOGLE',
  NINJA_CODER_1 = 'NINJA_CODER_1',
}

export enum CodeSourceSettingsTypes {
  OPEN_AI = 'openai',
  ANTHROPIC = 'anthropic',
  GOOGLE = 'google',
  OPEN_AI_ENABLED = 'openai_enabled',
  ANTHROPIC_ENABLED = 'anthropic_enabled',
  GOOGLE_ENABLED = 'google_enabled',
}

export enum CodeSourceNinjaSettingsTypes {
  NINJA_CODER_1 = 'ninja',
}

export enum NinjaSearchTypes {
  DEEP_RESEARCH = 'deep_research',
  DEEP_RESEARCH_MODEL = 'deep_research_model',
}

export enum WebSearchTypes {
  IMAGE_SEARCH = 'search_images',
  VIDEO_SEARCH = 'search_videos',
}

// TODO: get rid of this duplicate object
// and use Partial<AppUser> instead.
export interface CurrentUser {
  _id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  settings: {
    research?: {
      llms: string[];
    };
    web_search?: {
      search_images?: boolean;
      search_videos?: boolean;
    };
    llm?: {
      llm_resources?: Array<LlmResource>;
    };
  };
  metadata?: Metadata;
}

export type DayTimeType = {
  from: Date | number | string;
  to: Date | number | string;
  hasError?: boolean;
};
export type DayTimeArray = DayTimeType[];

export type TimeArray = { from: string; to: string }[];

export interface Metadata {
  avatarName?: string;
  cameraIndex?: number;
  environment?: string;
  backgroundHexColor?: string;
  voiceID?: string;
  avatarAudioLocale?: string;
  chatAudioLocale?: string;
  avatarCCLocale?: string;
  is_automatically_timezone?: boolean;
  timezone?: string;
  default_duration?: number;
  hourly_rate?: number;
  hours_per_day?: number;
  job_title?: string;
  department_of_team?: string;
  address_street?: string;
  address_apartment?: string;
  address_city?: string;
  address_state?: string;
  address_zipcode?: string;
  preference_schedule?: { [index: string]: DayTimeArray };
  calendar_LLM?: CalendarLLMTypes;
  source_chat_gpt_4?: boolean;
  source_anthropic?: boolean;
  source_google_palm_2?: boolean;
  source_chat_gpt_4_turbo?: boolean;
  is_show_cc?: boolean;
  is_dark_theme?: boolean;
  savings_duration?: TimeSavingFilter;
  preferred_schedule?: { [index: string]: TimeArray };
  is_hide_calendar_hint?: boolean;
  is_hide_research_hint?: boolean;
  is_user_saw_onboarding_hints?: boolean;
  app_version?: string;
}

export interface AvatarBackgroundColor {
  hex: string;
  r: number;
  g: number;
  b: number;
}

export enum Commands {
  CALENDAR = '/calendar',
  RESEARCH = '/research',
  RESEARCH_WEB = '/research_web',
  RESEARCH_LLM = '/research-llm',
  RESEARCH_CODE = '/research-code',
  OUTREACH = '/outreach',
  RESERVATION = '/reservation',
  STATUS = '/status',
  FUN = '/fun',
}

export enum Editables {
  ACTION = '[action]',
  ACTION_ITEM = '[task]',
  BUSINESS_NAME = '[business name]',
  COMMAND = '[command]',
  CONTACT = '[contact]',
  CONTACT_EMAIL = '[contact email address]',
  DETAILS = '[details]',
  DOCUMENT = '[document]',
  EMAIL = '[email address]',
  MEETING_DETAILS = '[meeting details]',
  NAME = '[name]',
  NONE = '',
  RELEVANT_CONTENT = '[relevant content]',
  PHONE = '[phone number]',
  PREFERRED_ACTION = '[preferred action]',
  PREFERRED_TIME = '[preferred time frame]',
  PROBLEM_STATEMENT = '[problem statement]',
  TIME = '[time frame]',
  TOPIC = '[topic]',
}

export type EditableInserts = Commands | Editables;

export enum AvatarAnimations {
  LISTENING = 'listening',
  THINKING = 'thinking',
  IDLE = 'idleaccent',
}

export enum KeyCodes {
  SPACE = ' ',
  ENTER = 'Enter',
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  BACKSPACESHIFT = 'BackspaceShift',
  ESCAPE = 'Escape',
  AT = '@',
  HASH = '#',
  SLASH = '/',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
}

export type ExampleType = {
  title: string;
  description: string;
};

export enum TimeSavingFilter {
  Week = 'This week',
  Month = 'This month',
  Year = 'This year',
  All = 'All time',
}

export enum PeriodType {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ALL = 'all_time',
}

export type Gender = 'female' | 'male' | 'both';

export interface BaseDataOption {
  id: string;
  url: string;
}

export interface AvatarOption extends BaseDataOption {
  gender: Gender;
}

export interface AgentAI extends BaseDataOption {
  gender: Gender;
  name: string;
}

export interface CommunicationToneOption {
  id: string;
  title: string;
  description: string;
}

export interface AgentOption {
  title: string;
  description: string;
  agents: AgentAI[];
  communicationStyle: {
    casualness: number;
    creativity: number;
  };
}

export interface EnvironmentOption extends BaseDataOption {
  hasDynamicBackground: boolean;
}

export interface CameraAngleOption extends BaseDataOption {
  cinematicMode: boolean;
}

export interface VoiceOption {
  languageCode: string;
  displayLanguageText: string;
  gender: Gender;
  dispalyVoiceName: string;
  voiceID: string;
  isDefault: boolean;
}

export interface VideoLinks {
  avatar: string;
  research: string;
  scheduler: string;
  coder: string;
  deepResearch: string;
  welcome: string;
  enterpriseWelcome: string;
}

export interface VoiceLanguageSampleOption {
  languageCode: string;
  text: string;
}

export enum OfflineRecordingParams {
  recordWithScript = 'recordWithScript',
  screenRecording = 'screenRecording',
  screenshot = 'screenshot',
  listOfVideos = 'listOfVideos',
}

export enum DaysOfWeek {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum NotificationActionType {
  NONE = 'none',
  SOCKET_RETRY = 'socket_retry',
  DISMISS = 'dismiss',
}

export enum NotificationType {
  ERROR = 'error',
  ALERT = 'alert',
  WARNING = 'warning',
  STATUS = 'status',
}

export interface Notification {
  type: NotificationType;
  notification: string;
  title?: string;
  action?: NotificationActionType;
}

export enum CalendarLLMTypes {
  CHAT_GPT4 = 'gpt-4-32k',
  NINJA_LLM_7B = 'code-llama-7b-instruct',
  NINJA_LLM_34B = 'code-llama-34b-instruct',
}

export enum CalendarLLMTypesLabels {
  CHAT_GPT4 = 'ChatGPT 4.0',
  NINJA_LLM_7B = 'Ninja LLM 7B',
  NINJA_LLM_34B = 'Ninja LLM 34B',
}

export enum SourceTypesLabels {
  // TODO deprecated ones:
  CHAT_GPT4 = 'ChatGPT 4.0',
  ANTHROPIC_2 = 'Anthropic Claude 2.0',
  GOOGLE_PALM2 = 'Google PaLM 2',
  GOOGLE_GEMINI_PRO = 'Google Gemini Pro',
  //new ones:
  OPEN_AI = 'OpenAI',
  ANTHROPIC = 'Anthropic',
  GOOGLE = 'Google',
}

export enum CodeSourceTypesLabels {
  // TODO deprecated ones:
  NINJA_CODER_1 = 'Ninja Coder',
  CHAT_GPT4 = 'ChatGPT 4.0',
  GOOGLE_GEMINI_PRO = 'Google Gemini Pro',
  ANTHROPIC_2 = 'Anthropic Claude 2.0',
  GOOGLE_PALM2 = 'Google PaLM 2',
  // new ones:
  OPEN_AI = 'OpenAI',
  GOOGLE = 'Google',
  ANTHROPIC = 'Anthropic',
}

export enum WebSearchTypesLabels {
  IMAGE_SEARCH = 'Images',
  VIDEO_SEARCH = 'Videos',
}

export type ExternalModelProviderType = {
  icon?: ({ size }: { size?: number }) => JSX.Element;
  icon_name: string;
  label: string;
  url: string;
};

// 3 modes horizontal menu at the top
export enum SideNavigationMenu {
  CHAT = 'chat',
  AVATAR = 'avatar',
  CALENDAR = 'calendar',
}

// vertical menu with profile icon first
export enum SideNavigationSubMenu {
  NONE = 'none',
  NOTIFICATIONS = 'notifications',
  LLM = 'llm',
  // continue the list
}

export interface SavingsInsightsResponse {
  view_period: string;
  since?: string;
  to?: string;
  completed_tasks_count?: number;
  breakdowns: { [key: string]: number };
}

export interface ChartDataset {
  label: string;
  data: number[];
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
}

export interface ChartData {
  labels: string[];
  datasets: ChartDataset[];
}

export enum TasksListSortByTime {
  DEFAULT = 'created_at',
  UPDATED_AT = 'updated_at',
}

export enum TasksListSortByStatus {
  ALL = 'All',
  BLOCKED = 'Blocked',
  IN_PROGRESS = 'In Progress',
  DONE = 'Completed',
  UNDO = 'Undo',
  ARCHIVE = 'Archived',
  FAILED = 'Failed',
}

export enum MediaKind {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum MediaTabs {
  ALL = 'All',
  VIDEOS = 'Videos',
  IMAGES = 'Images',
}

export type MediaData = {
  kind: MediaKind;
  url: string;
  date?: Date;
  name?: string;
  icon?: JSX.Element;
  width: number;
  height: number;
  thumbnail?: string;
};

export interface ScrollerOptions {
  delay: number;
  instant?: boolean;
  toScreenTop?: boolean;
}

export enum RenameType {
  TASK = 'task',
  CONVERSATION = 'conversation',
}

export type RenameTypeProps = {
  itemId: string;
  itemName: string;
  itemType: RenameType;
};

// UI coverage if the filters
export enum TaskStatusFilter {
  ALL = 'All Chats',
  ALL_AND_ARCHIVED = 'All including archived chats',
  BLOCKED = 'Blocked',
  DONE = 'Done',
  IN_PROGRESS = 'In Progress',
  FAILED = 'Failed',
  STOPPED = 'Stopped',
}

export enum ChatsTasksFilter {
  BLOCKED = 'blocked',
  DONE = 'done',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  STOPPED = 'stopped',
}

export interface GroupedMessagesByDate {
  [date: string]: Message[];
}

export enum BannerType {
  LOW_CREDITS = 'LOW_CREDITS',
  INSUFFICIENT_CREDITS = 'INSUFFICIENT_CREDITS',
  LOW_TASKS = 'LOW_TASKS',
  LOW_DAYS = 'LOW_DAYS',
  AVATAR_IDLE = 'AVATAR_IDLE',
  AVATAR_QUEUE = 'AVATAR_QUEUE',
  DOWNLOAD_DESKTOP_APP = 'DOWNLOAD_DESKTOP_APP',
  USER_RATE_LIMITED = 'USER_RATE_LIMITED',
  USER_RATE_LIMITED_UNTIL = 'USER_RATE_LIMITED_UNTIL',
  DOWNLOAD_MOBILE_APP = 'DOWNLOAD_MOBILE_APP',
}

export type Uploader = {
  login: string;
  id: number;
  node_id: string;
  avatar_url: string;
  gravatar_id: string;
  url: string;
  html_url: string;
  followers_url: string;
  following_url: string;
  gists_url: string;
  starred_url: string;
  subscriptions_url: string;
  organizations_url: string;
  repos_url: string;
  events_url: string;
  received_events_url: string;
  type: string;
  site_admin: boolean;
};

export type ReleaseAsset = {
  url: string;
  id: number;
  node_id: string;
  name: string;
  label: string;
  uploader: Uploader;
  content_type: string;
  state: string;
  size: number;
  download_count: number;
  created_at: string; // ISO 8601 date string
  updated_at: string; // ISO 8601 date string
  browser_download_url: string;
};

export type LLMTableItemType = {
  icon?: ({ size }: { size?: number }) => JSX.Element;
  icon_name: string;
  display_name: string;
  count: number;
};

export interface ConvertedImageGenData extends ImageGenData {
  isDisabled?: boolean;
}

export interface FileObject {
  file?: File;
  isLoading?: boolean;
  errorMessage?: string | null;
  convertedFileName?: string;
  isEdit?: boolean;
  mask_url?: string;
  image_url?: string;
}

export type ResearcherSettingsMediaItem = {
  value: WebSearchTypes;
  label: string;
  icon: Icon;
};
