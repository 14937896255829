import {
  Conversation,
  ApiTask,
  ConversationUpdateRequest,
  ConversationState,
  TaskSkill,
} from 'src/types';
import { conversationApi } from '../services/conversationApi';
import store from 'src/store';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { DEFAULT_CHAT_NAME } from 'src/constants';

// helpers
const getFilterBySkill = (skill?: TaskSkill) => {
  if (!skill) {
    return;
  }

  if (skill === TaskSkill.CHITCHAT || skill === TaskSkill.CODING) {
    return [TaskSkill.CHITCHAT, TaskSkill.CODING];
  }

  return [skill];
};

// getConversations
export const updateQueryGetConversations = (
  args: ConversationUpdateRequest,
  filter_task_skills?: TaskSkill,
) => {
  const { user_id, conversation_id, ...patch } = args;

  return conversationApi.util.updateQueryData(
    'getConversations',
    {
      user_id,
      page_token: '',
      include_archived: false,
      filter_task_skills: getFilterBySkill(filter_task_skills),
    },
    (draft) => ({
      ...draft,
      data: draft?.data
        ?.map((item) => {
          if (item.conversation_id === conversation_id) {
            return {
              ...item,
              ...patch,
            };
          } else {
            return item;
          }
        })
        .filter((item) => item.state !== ConversationState.ARCHIVED),
    }),
  );
};

export const updateQueryGetConversationsWithArchived = (
  args: ConversationUpdateRequest,
) => {
  const { user_id, conversation_id, ...patch } = args;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, page_token: '', include_archived: true },
    (draft) => {
      draft?.data?.forEach((item) => {
        if (item.conversation_id === conversation_id) {
          Object.assign(item, patch);
        }
      });
    },
  );
};

export const addConversationToList = (conversation: Conversation) => {
  const { user_id, conversation_id = '' } = conversation;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, page_token: '', include_archived: false },
    (draft) => {
      const hasCurrentConversation = draft?.data?.find(
        (item) => item.conversation_id === conversation_id,
      );

      return hasCurrentConversation
        ? { ...draft }
        : {
            ...draft,
            data: [conversation, ...(draft?.data || [])],
          };
    },
  );
};

export const updateConversationInConversationsList = ({
  user_id,
  conversation,
  filter_task_skills,
}: {
  user_id: string;
  conversation: Partial<Conversation>;
  filter_task_skills?: TaskSkill;
}) => {
  const { conversation_id = '' } = conversation;

  return conversationApi.util.updateQueryData(
    'getConversations',
    {
      user_id,
      page_token: '',
      include_archived: false,
      filter_task_skills: getFilterBySkill(filter_task_skills),
    },
    (draft) => ({
      ...draft,
      data: draft?.data?.map((item) =>
        item.conversation_id === conversation_id
          ? { ...item, ...conversation }
          : item,
      ),
    }),
  );
};

export const addTaskToConversationsList = (newTask: ApiTask) => {
  const { parent_conversation_id = '', user_id, task_id } = newTask;

  return conversationApi.util.updateQueryData(
    'getConversations',
    { user_id, page_token: '', include_archived: false },
    (draft) => ({
      ...draft,
      data: draft?.data?.map((item) => {
        if (item.conversation_id === parent_conversation_id) {
          return {
            ...item,
            tasks: item.tasks?.some((task) => task.task_id === task_id)
              ? item.tasks
              : [...(item.tasks || []), newTask],
          };
        } else {
          return item;
        }
      }),
    }),
  );
};

export const addTaskToFilteredBySkillConversationsList = (newTask: ApiTask) => {
  const { parent_conversation_id = '', user_id, skill } = newTask;

  return conversationApi.util.updateQueryData(
    'getConversations',
    {
      user_id,
      page_token: '',
      include_archived: false,
      filter_task_skills: getFilterBySkill(skill),
    },
    (draft) => {
      if (!skill) {
        return draft;
      }

      if (
        !draft?.data?.some(
          ({ conversation_id }) => conversation_id === parent_conversation_id,
        )
      ) {
        const state = store.getState();

        const cachedTopConversations =
          conversationApi.endpoints.getConversations.select({
            user_id,
            include_archived: false,
          })(state);

        if (cachedTopConversations.status === QueryStatus.uninitialized) {
          const newConversation: Conversation = {
            conversation_id: parent_conversation_id,
            user_id,
            is_top_conversation: true,
            conversation_hash: `#${DEFAULT_CHAT_NAME}`,
            tasks: [],
          };

          return {
            ...draft,
            data: [
              {
                ...newConversation,
                tasks: [newTask],
              },
              ...(draft?.data || []),
            ],
          };
        }

        const currentConversation = cachedTopConversations.data?.data.find(
          ({ conversation_id }) => conversation_id === parent_conversation_id,
        );

        return currentConversation
          ? {
              ...draft,
              data: [
                {
                  ...currentConversation,
                  tasks: [newTask],
                },
                ...(draft?.data || []),
              ],
            }
          : draft;
      }

      return {
        ...draft,
        data: draft?.data?.map((conversation) => {
          if (conversation.conversation_id === parent_conversation_id) {
            return {
              ...conversation,
              tasks: conversation.tasks
                ? [...conversation.tasks, newTask]
                : [newTask],
            };
          } else {
            return conversation;
          }
        }),
      };
    },
  );
};

export const updateTaskInConversationsList = ({
  user_id,
  task: updatedTask,
  filter_task_skills,
}: {
  user_id: string;
  task: Partial<ApiTask>;
  filter_task_skills?: TaskSkill;
}) => {
  const { parent_conversation_id = '', task_id } = updatedTask;

  return conversationApi.util.updateQueryData(
    'getConversations',
    {
      user_id,
      page_token: '',
      include_archived: false,
      filter_task_skills: getFilterBySkill(filter_task_skills),
    },
    (draft) => ({
      ...draft,
      data: draft?.data?.map((conversation) => {
        if (conversation.conversation_id === parent_conversation_id) {
          return {
            ...conversation,
            tasks: conversation.tasks?.map((task) =>
              task.task_id === task_id ? { ...task, ...updatedTask } : task,
            ),
          };
        } else {
          return conversation;
        }
      }),
    }),
  );
};

// getConversationById
export const updateQueryGetConversationById = (
  args: ConversationUpdateRequest,
) => {
  const { user_id, conversation_id, ...patch } = args;
  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      if (draft) {
        Object.assign(draft, patch);
      }
    },
  );
};

export const addConversation = (conversation: Conversation) => {
  const { user_id, conversation_id = '' } = conversation;

  return conversationApi.util.upsertQueryData(
    'getConversationById',
    { user_id, conversation_id },
    conversation,
  );
};

export const updateConversationState = ({
  user_id,
  conversation,
}: {
  user_id: string;
  conversation: Partial<Conversation>;
}) => {
  const { conversation_id = '' } = conversation;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id },
    (draft) => {
      if (draft) {
        Object.assign(draft, conversation);
      }
    },
  );
};

export const addTaskToConversation = (newTask: ApiTask) => {
  const { parent_conversation_id = '', user_id } = newTask;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: parent_conversation_id },
    (draft) => {
      if (draft) {
        draft.tasks ? draft.tasks.push(newTask) : (draft.tasks = [newTask]);
      }
    },
  );
};

export const updateTaskInConversation = ({
  user_id,
  task: updatedTask,
}: {
  user_id: string;
  task: Partial<ApiTask>;
}) => {
  const { parent_conversation_id = '', task_id } = updatedTask;

  return conversationApi.util.updateQueryData(
    'getConversationById',
    { user_id, conversation_id: parent_conversation_id },
    (draft) => {
      const focusTask = draft?.tasks?.find((task) => task.task_id === task_id);

      if (!focusTask) {
        return;
      }

      Object.assign(focusTask, updatedTask);
    },
  );
};
