import { useMemo, useState, useContext, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { VideoCamera, X } from '@phosphor-icons/react';
import { DownloadVideoButton } from '../DownloadVideoButton';
// import { ShareButton } from 'src/components/ShareButton';
import Select, {
  GroupBase,
  StylesConfig,
  SingleValue,
  MultiValue,
} from 'react-select';
import { getCustomStyles } from 'src/pages/ManageTasksChatPage/components/SettingsSection/selectHelper';
import { Icon, IconName } from 'src/components/Icon';
import { AvailableVideoFormats } from 'src/constants';
import {
  AvatarVideos,
  AvatarVideosStatus,
  AvatarVideoPresetName,
} from 'src/types';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { lightMode, darkMode } from 'src/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useColorTheme, usePrevious } from 'src/hooks';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';

type ModalWithVideoProps = {
  isOpen: boolean;
  onClose: () => void;
  videoData: AvatarVideos;
  isShowDownloadShareButtons?: boolean;
};

type VideoFormatOptionType = {
  value: AvatarVideoPresetName;
  label: string;
  subTitle: string;
  icon: IconName;
};

const SVG_SIZE = 24;

/**
 * @deprecated
 */
export const ModalWithVideo = ({
  isOpen,
  onClose,
  videoData,
  isShowDownloadShareButtons = true,
}: ModalWithVideoProps) => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { isDarkTheme } = useColorTheme();

  const { status } = videoData;

  const resolutionOptions = useMemo(
    () =>
      AvailableVideoFormats.filter(({ value }) =>
        videoData.urls?.find(({ name }) => value === name),
      ),
    [videoData],
  );

  const [selectedVideoFormat, setSelectedVideoFormat] = useState<
    SingleValue<VideoFormatOptionType>
  >(resolutionOptions[0]);

  const previousVideoDataStatus = usePrevious(status);

  useEffect(() => {
    if (
      status === AvatarVideosStatus.AVAILABLE &&
      previousVideoDataStatus &&
      status !== previousVideoDataStatus
    ) {
      setSelectedVideoFormat(resolutionOptions[0]);
    }
  }, [status, previousVideoDataStatus, resolutionOptions]);

  const handleVideoFormatSelect = (
    newValue:
      | SingleValue<VideoFormatOptionType>
      | MultiValue<VideoFormatOptionType>
      | null,
  ) => {
    setSelectedVideoFormat(newValue as SingleValue<VideoFormatOptionType>);
  };

  const videoUrl = useMemo(() => {
    if (selectedVideoFormat) {
      return (
        videoData.urls?.find(({ name }) => name === selectedVideoFormat.value)
          ?.url || ''
      );
    }
    return '';
  }, [selectedVideoFormat, videoData]);

  const formatOptionLabel = (
    { ...data }: VideoFormatOptionType,
    { context }: FormatOptionLabelMeta<VideoFormatOptionType>,
  ) => {
    return (
      <div className="nj-video-avatar-modal-select-option">
        <Icon type={data.icon} size={20} />
        <div className="nj-video-avatar-modal-select-option-label-wrapper">
          <p>{data.label}</p>
          {context === 'menu' && (
            <span className="nj-video-avatar-modal-select-option-label-subtitle">
              {data.subTitle}
            </span>
          )}
        </div>
      </div>
    );
  };

  const videoHeight = useMemo(() => {
    if (selectedVideoFormat) {
      switch (selectedVideoFormat.value) {
        case AvatarVideoPresetName._4K_RAW:
          return 405;
        case AvatarVideoPresetName.HD_VERTICAL:
          return 720;
        case AvatarVideoPresetName.SQUARE:
          return 600;
        default:
          return 720;
      }
    } else return 720;
  }, [selectedVideoFormat]);

  const videoWidth = useMemo(() => {
    if (selectedVideoFormat) {
      switch (selectedVideoFormat.value) {
        case AvatarVideoPresetName._4K_RAW:
          return 720;
        case AvatarVideoPresetName.SQUARE:
          return 600;
        case AvatarVideoPresetName.HD_VERTICAL:
        default:
          return 405;
      }
    } else return 405;
  }, [selectedVideoFormat]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access nj-modal--large',
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <>
        <div className="nj-video-avatar-modal-header">
          <div className="nj-video-avatar-modal-header-name">
            <span className="nj-video-avatar-modal-header-name-date">
              {videoData.created_at
                ? new Date(videoData.created_at).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                : ''}
            </span>
            <h5 className="nj-video-avatar-modal-header-name-wrapper">
              <VideoCamera size={20} /> <span>{videoData.title}</span>
            </h5>
          </div>
          {isShowDownloadShareButtons && (
            <div className="nj-video-avatar-modal-header-actions">
              <DownloadVideoButton
                videoInfo={videoData}
                buttonIconSize={SVG_SIZE}
              />
              {/* <ShareButton
                disabled={!resolution}
                videoData={videoData}
                videoResolution={resolution}
              /> */}
            </div>
          )}
        </div>
        <div className="nj-video-avatar-modal-content">
          <div className="nj-video-avatar-modal-content-select-wrapper">
            <Select
              aria-label="video-formats"
              options={resolutionOptions}
              theme={isDarkTheme ? darkMode : lightMode}
              styles={
                getCustomStyles<VideoFormatOptionType>() as StylesConfig<
                  VideoFormatOptionType,
                  boolean,
                  GroupBase<VideoFormatOptionType>
                >
              }
              isSearchable={false}
              value={selectedVideoFormat}
              onChange={handleVideoFormatSelect}
              formatOptionLabel={formatOptionLabel}
            />
          </div>
          <div className="nj-video-avatar-modal-content-video-player-wrapper">
            <LazyReactPlayer
              url={videoUrl}
              controls
              height={videoHeight}
              width={videoWidth}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
