import { Paperclip } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useContext } from 'react';
import {
  AttachmentFileInputId,
  MAX_NUMBER_OF_FILES,
  SVG_SIZE_M,
} from 'src/constants';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { useBreakpoint, useRightSidePanelState, useSession } from 'src/hooks';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './AttachFileButton.module.scss';

interface AttachFileButtonProps {
  withTitle?: boolean;
  onShowTooltip: () => void;
}

export const AttachFileButton = ({
  withTitle,
  onShowTooltip,
}: AttachFileButtonProps) => {
  const { isOpenTier } = useSession();
  const { isMobile } = useBreakpoint();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { promptLoading, threadInputBoxFiles, fileDataToSend } =
    useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);

  const isDisabled =
    promptLoading ||
    fileDataToSend?.length > 0 ||
    (!!threadInputBoxFiles &&
      Object.keys(threadInputBoxFiles).length === MAX_NUMBER_OF_FILES);

  const handleAttachClick = () => {
    if (isOpenTier) {
      onShowTooltip();
    } else {
      document.getElementById(AttachmentFileInputId)?.click();
    }
  };

  return (
    <Button
      disabled={isDisabled || isRightPanelExpanded}
      onClick={handleAttachClick}
      fullWidth={isMobile}
      data-tooltip-id="attachment-tooltip"
      data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
      shape={withTitle ? 'regular' : 'round'}
      color="transparent"
      // TODO(olha): double-check this style. looks like unnecessary
      // (irina): transparent conflicts with regular paddings, so we need to add a class to fix it
      className={classNames({
        [styles.extendedButton]: withTitle,
        [styles.mobileButton]: isMobile,
      })}
    >
      <Paperclip size={SVG_SIZE_M} />

      {withTitle && 'Attach file(s)'}
    </Button>
  );
};
