import { useCallback, useMemo } from 'react';
import { useGetUserByIdQuery } from 'src/store/services';
import { useSession } from 'src/hooks';
import { useUserData } from 'src/hooks';
import { ExternalModelSettings, ImageGenSettingsOption } from 'src/types';

export const useImageSettings = () => {
  const {
    appUser: { user_id },
  } = useSession();

  const { updateUserSettings } = useUserData();

  const { data } = useGetUserByIdQuery(user_id, {
    skip: !user_id,
  });

  const imageGenSettings = useMemo(
    () => data?.settings?.image_gen_settings || {},
    [data],
  );

  const imageExternalModels = useMemo(
    () => data?.settings?.image_gen_settings?.models || [],
    [data],
  );

  const updateImageExternalModels = useCallback(
    async (updatedSettings: {
      textExternalModels?: Partial<ExternalModelSettings>;
      imageExternalModels?: ImageGenSettingsOption[];
    }) => {
      await updateUserSettings({
        image_gen_settings: {
          ...imageGenSettings,
          models: updatedSettings.imageExternalModels ?? imageExternalModels,
        },
      });
    },
    [updateUserSettings, imageExternalModels, imageGenSettings],
  );

  return useMemo(
    () => ({
      imageExternalModels,
      updateImageExternalModels,
    }),
    [imageExternalModels, updateImageExternalModels],
  );
};
