import { useMemo } from 'react';
import { ShareButton } from 'src/components/ShareButton';
import {
  isChitChatCard,
  MessageType,
  TaskState,
  isImageGenerationCard,
  TaskSkill,
  MessageSkill,
} from 'src/types';
import {
  useSession,
  useCurrentMessage,
  useCurrentConversationTasks,
} from 'src/hooks';
import styles from './MessageActions.module.scss';
import { getSkillFromMessage, getTaskIdFromMessage } from 'src/utils';
import { CopyMessageButton } from 'src/pages/ManageTasksChatPage/components/ChatMessage/components/CopyMessageButton';
import { RegenerateMessageControl } from 'src/v2/components/ThreadMessage/components/RegenerateMessageControl';
import { TaskDetailsButton } from '../TaskDetailsButton';

export const SKILLS_WITH_REDO: Array<MessageSkill> = [
  MessageSkill.WRITER,
  MessageSkill.CODER,
  MessageSkill.RESEARCHER,
  MessageSkill.IMAGE_GENERATION,
  MessageSkill.VIDEO_GENERATION,
];

interface MessageActionsProps {
  messageId?: string;
  isFinalMessageChunk?: boolean;
}

export const MessageActions = ({
  messageId,
  isFinalMessageChunk,
}: MessageActionsProps) => {
  const {
    isOpenTier,
    isFreeTier,
    isStandardTier,
    isLimitedTier,
    isTeamsStandardTier,
  } = useSession();

  const { currentMessage } = useCurrentMessage(messageId);
  const { getTaskFromConversationById } = useCurrentConversationTasks();

  const { payload, message_type } = currentMessage || {};

  const taskId = getTaskIdFromMessage(currentMessage);
  const taskState = taskId ? getTaskFromConversationById(taskId)?.state : null;

  const shouldEnableActions = useMemo(() => {
    if (
      (isOpenTier || isFreeTier || isStandardTier || isTeamsStandardTier) &&
      isChitChatCard(payload) &&
      payload.data?.skill === TaskSkill.DOCUMENT_QA
    ) {
      return false;
    }
    if (isImageGenerationCard(payload) && (isOpenTier || isFreeTier)) {
      return false;
    }
    if (
      isImageGenerationCard(payload) &&
      !!payload.watermarked_images &&
      isLimitedTier
    ) {
      return false;
    }

    // TODO(olha): temporary
    if (message_type === MessageType.VIDEO_CARD) {
      return false;
    }

    if (
      message_type === MessageType.ERROR_MESSAGE ||
      message_type === MessageType.SCHEDULER_TASK_CREATION_CARD
    ) {
      return false;
    }

    if (message_type === MessageType.CONVERSATION && isFinalMessageChunk) {
      return true;
    }

    if (taskState === TaskState.DONE && isFinalMessageChunk) {
      return true;
    }

    if (!taskId && isFinalMessageChunk) {
      return true;
    }

    return false;
  }, [
    message_type,
    payload,
    isFinalMessageChunk,
    taskState,
    taskId,
    isOpenTier,
    isFreeTier,
    isStandardTier,
    isLimitedTier,
    isTeamsStandardTier,
  ]);

  const currentSkill = useMemo(
    () => getSkillFromMessage(currentMessage),
    [currentMessage],
  );

  const isRedoDisabled = useMemo(
    () =>
      !!(
        !isFinalMessageChunk ||
        (currentSkill && !SKILLS_WITH_REDO.includes(currentSkill))
      ),
    [isFinalMessageChunk, currentSkill],
  );

  return (
    <div className={styles.root}>
      <TaskDetailsButton messageId={messageId} />

      <RegenerateMessageControl
        messageId={messageId}
        disabled={isRedoDisabled}
      />

      <CopyMessageButton
        messageId={messageId}
        disabled={
          !shouldEnableActions ||
          message_type === MessageType.IMAGE_CARD ||
          message_type === MessageType.VIDEO_CARD
        }
      />

      <ShareButton messageId={messageId} disabled={!shouldEnableActions} />
    </div>
  );
};
