import { Link } from 'react-router-dom';
import { ArrowSquareOut } from '@phosphor-icons/react';
import classNames from 'classnames';
import { SVG_SIZE_M } from 'src/constants';
import styles from './LearnMoreLink.module.scss';

interface LearnMoreLinkProps {
  to: string;
  dataE2E: string;
  title?: string;
  className?: string;
}

export const LearnMoreLink = ({
  to,
  dataE2E,
  title = 'Learn more',
  className,
}: LearnMoreLinkProps) => {
  return (
    <Link
      to={to}
      target="_blank"
      className={classNames(styles.root, className)}
      data-e2e={dataE2E}
      rel="noreferrer"
    >
      {title} <ArrowSquareOut size={SVG_SIZE_M} />
    </Link>
  );
};
