import { useRef } from 'react';
import { Plus } from '@phosphor-icons/react';
import { Button } from 'src/v2/commonComponents/Button';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { useBreakpoint, useOutsideClick, useVisible } from 'src/hooks';
import { SVG_SIZE_M } from 'src/constants';
import { AddAttachmentButton } from 'src/v2/components/ThreadInputBox/components/AddAttachmentButton';
import { CapturePhoto } from 'src/components/CapturePhoto';
import styles from './MoreActionsButton.module.scss';
import { useRightSidePanelState } from 'src/hooks';
import { BottomDrawer } from 'src/components/BottomDrawer';

const MORE_ACTIONS_ANCHOR_ID = 'more-actions-input-trigger';

const MoreActionsButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();
  const { isRightPanelExpanded } = useRightSidePanelState();
  const { isTabletAndUp } = useBreakpoint();

  const handleOutsideClick = () => {
    if (isTabletAndUp) {
      onVisibilityRemove();
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div id={MORE_ACTIONS_ANCHOR_ID} ref={containerRef} className={styles.root}>
      <Button
        className={styles.trigger}
        shape="round"
        color="transparent"
        onClick={onVisibilityToggle}
        disabled={isRightPanelExpanded}
        tooltipContent={isTabletAndUp ? 'Attach files' : undefined}
      >
        <Plus size={SVG_SIZE_M} />
      </Button>

      {isTabletAndUp ? (
        <ReactTooltip
          place="top-start"
          id={MORE_ACTIONS_ANCHOR_ID}
          isOpen={isVisible}
          offset={-38}
          className={styles.tooltip}
        >
          <AddAttachmentButton withTitle onClose={onVisibilityRemove} />

          <CapturePhoto withTitle onClose={onVisibilityRemove} />
        </ReactTooltip>
      ) : (
        <BottomDrawer isOpen={isVisible} onClose={onVisibilityRemove}>
          <AddAttachmentButton withTitle onClose={onVisibilityRemove} />
        </BottomDrawer>
      )}
    </div>
  );
};

export default MoreActionsButton;
