import {
  ApiTask,
  ChatsTasksFilter,
  ConversationState,
  ConversationUpdateRequest,
  Message,
  MessageChunk,
} from 'src/types';
import { tasksApi } from '../services';
import { recursiveDeepMerge, taskGTMEvent } from 'src/utils';

/**
 * @deprecated
 */
export const updateQueryGetFilteredTasks = (
  args: ConversationUpdateRequest,
  filter_state: ChatsTasksFilter,
) => {
  const { user_id, conversation_id, ...patch } = args;

  return tasksApi.util.updateQueryData(
    'getFilteredTasks',
    { user_id, page_token: '', filter_state },
    (draft) => ({
      ...draft,
      data: draft?.data
        ?.map((item) =>
          item?.conversation_id === conversation_id
            ? { ...item, ...patch }
            : item,
        )
        .filter((item) => item.state !== ConversationState.ARCHIVED),
    }),
  );
};

export const addMessageToTask = (newMessage: Message) => {
  const { user_id, conversation_id, task_id = '', message_id } = newMessage;

  return tasksApi.util.updateQueryData(
    'getTaskById',
    { user_id, task_id },
    (draft) => {
      const newConversation = draft?.conversation
        ? { ...draft.conversation }
        : {
            conversation_id,
            user_id,
            timestamp: new Date().toISOString(),
            messages: [],
          };

      newConversation.messages = [...(newConversation?.messages || [])];

      const existingMessageIndex = newConversation?.messages?.findIndex(
        (message: Message) => message.message_id === message_id,
      );

      if (existingMessageIndex !== -1) {
        newConversation.messages[existingMessageIndex] = {
          ...newConversation?.messages[existingMessageIndex],
          ...newMessage,
        };
      } else {
        newConversation?.messages.push(newMessage);
      }

      return {
        ...draft,
        conversation: newConversation,
      };
    },
  );
};

export const addTask = (newTask: ApiTask) => {
  const { user_id, task_id = '' } = newTask;

  return tasksApi.util.upsertQueryData(
    'getTaskById',
    { user_id, task_id },
    newTask,
  );
};

export const addTaskMessageContentChunk = ({
  user_id,
  messageChunk,
}: {
  user_id: string;
  messageChunk: MessageChunk;
}) => {
  const { task_id = '', content, message_id } = messageChunk;

  return tasksApi.util.updateQueryData(
    'getTaskById',
    { user_id, task_id },
    (draft) => {
      return {
        ...draft,
        conversation: {
          ...draft?.conversation,
          user_id,
          messages:
            draft?.conversation?.messages?.map((item) =>
              item.message_id === message_id
                ? {
                    ...item,
                    content: item.content + content,
                  }
                : item,
            ) || [],
        },
      };
    },
  );
};

export const appendTaskMessage = (appendedMessage: Message) => {
  const { user_id, task_id = '', message_id } = appendedMessage;

  return tasksApi.util.updateQueryData(
    'getTaskById',
    { user_id, task_id },
    (draft) => {
      return {
        ...draft,
        conversation: {
          ...draft?.conversation,
          user_id,
          messages:
            draft?.conversation?.messages?.map((item) => {
              if (item.message_id === message_id) {
                const mergedMessagePayload = recursiveDeepMerge(
                  item?.payload || {},
                  appendedMessage.payload || {},
                );

                return {
                  ...item,
                  ...appendedMessage,
                  payload: mergedMessagePayload,
                };
              } else {
                return item;
              }
            }) || [],
        },
      };
    },
  );
};

export const replaceTaskMessage = (replacedMessage: Message) => {
  const { user_id, task_id = '', message_id } = replacedMessage;

  return tasksApi.util.updateQueryData(
    'getTaskById',
    { user_id, task_id },
    (draft) => {
      const messageExists = draft?.conversation?.messages?.some(
        (item) => item.message_id === message_id,
      );

      const updatedMessages = messageExists
        ? draft?.conversation?.messages?.map((item) =>
            item.message_id === message_id
              ? { ...item, ...replacedMessage }
              : item,
          )
        : [...(draft?.conversation?.messages || []), replacedMessage];

      return {
        ...draft,
        conversation: {
          ...draft?.conversation,
          user_id,
          messages: updatedMessages,
        },
      };
    },
  );
};

export const updateTaskState = ({
  user_id,
  task,
}: {
  user_id: string;
  task: Partial<ApiTask>;
}) => {
  const { task_id = '' } = task;

  taskGTMEvent(task);

  return tasksApi.util.updateQueryData(
    'getTaskById',
    { user_id, task_id },
    (draft) => {
      return {
        ...draft,
        ...task,
      };
    },
  );
};
