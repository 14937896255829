// Main breakpoints
export const BREAKPOINT_ULTRA_WIDE = 1920;
export const BREAKPOINT_DESKTOP = 1280;
export const BREAKPOINT_TABLET = 768;

// Additional breakpoints
export const BREAKPOINT_DESKTOP_XL = 1440;
export const BREAKPOINT_DESKTOP_L = 1360;
export const BREAKPOINT_LAPTOP_L = 1024;
export const BREAKPOINT_LAPTOP = 992;

// Custom breakpoints
export const CUSTOM_BREAKPOINT_DESKTOP_XL_AVATAR = 1515;
export const CUSTOM_BREAKPOINT_DESKTOP_SMALL_WITH_LEFT_PANEL = 1640;
