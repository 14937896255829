import classNames from 'classnames';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsDimensionResolutionTypes,
  ImageGenSettingsResolutions,
  ImageGenSettingsSelectedDimension,
} from 'src/types';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';

import styles from './ResolutionSlider.module.scss';
import { SegmentedControl } from 'src/v2/components/SegmentedControl';

const resolutionLabels = {
  0: 'S',
  1: 'M',
  2: 'L',
};

interface ResolutionSliderProps {
  isVisible?: boolean;
  selectedData: ImageGenSettingsSelectedDimension;
  dimensionGroups: ImageGenSettingsDimensionGroups;
  onChange: (value: ImageGenSettingsDimensionResolutionTypes) => void;
}

export const ResolutionSlider = ({
  isVisible,
  selectedData,
  dimensionGroups,
  onChange,
}: ResolutionSliderProps) => {
  const { option, group, resolution } = selectedData;
  const currentGroup = dimensionGroups[group] || {};

  const { resolutions } =
    (
      currentGroup as unknown as Record<
        ImageGenSettingsDimensionOptionTypes,
        ImageGenSettingsResolutions
      >
    )[option] || {};

  const resolutionsData = Object.values(resolutions || []);

  const options = resolutionsData.map((item, index) => ({
    label: resolutionLabels[index as keyof typeof resolutionLabels],
    value: item.name.toLowerCase(),
    disabled: !item.is_capable,
    is_capable: item.is_capable,
  }));

  const handleSegmentedChange = (value: string, index: number) => {
    const selectedResolution = resolutionsData[index];

    if (selectedResolution && selectedResolution.is_capable) {
      onChange(value as ImageGenSettingsDimensionResolutionTypes);
    }
  };

  return (
    <div className={styles.root}>
      <SegmentedControl
        options={options}
        isVisible={isVisible}
        activeValue={resolution}
        onChange={handleSegmentedChange}
      />

      <div className={styles.sizeLabels}>
        {resolutionsData.map(({ name, width, height, is_capable }) => {
          const isActive = resolution === name.toLowerCase();
          return (
            <div key={name} className={styles.sizeLabel}>
              <span
                className={classNames(styles.sizeLabelText, {
                  [styles.active]: isActive,
                  [styles.disabled]: !is_capable,
                })}
              >
                {width}x{height}
              </span>
              {!is_capable &&
                (name === ImageGenSettingsDimensionResolutionTypes._1080P ? (
                  <LabelPro />
                ) : (
                  <LabelUltra />
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
