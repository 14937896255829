import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT, SVG_SIZE_L } from 'src/constants';
import {
  useSession,
  useLeftPanelState,
  useConversations,
  useConversationParams,
  useConversationActions,
} from 'src/hooks';
import { Conversation } from 'src/types';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import { ScrollToEdgeButton } from 'src/components/ScrollToEdgeButton';
import styles from './ConversationsList.module.scss';
import { ConversationsGroupedByDate } from './components/ConversationsGroupedByDate';
import { UpsellChatsMessage } from 'src/components/UpsellChatsMessage';
import { EmptyContainer } from './components/EmptyContainer';

export const ConversationsList = () => {
  const { isOpenTier } = useSession();
  const { isLeftPanelExpanded } = useLeftPanelState();

  const {
    conversations = [],
    isConversationsLoading,
    isConversationsFetching,
    newPageToken,
  } = useConversations();
  const { currentConversationId } = useConversationParams();

  const { setPageToken } = useConversationActions();

  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isButtonScrollUpVisible, setIsButtonScrollUpVisible] = useState(false);

  useEffect(() => {
    const scrollRef = containerRef?.current;

    const onConversationsScroll = () => {
      const {
        scrollTop = 0,
        clientHeight = 0,
        scrollHeight = 0,
      } = scrollRef || {};

      setIsButtonScrollUpVisible(scrollTop > 30);

      if (!scrolledToBottom) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setScrolledToBottom(true);
        }
      }
    };

    scrollRef?.addEventListener('scroll', onConversationsScroll);

    return () => {
      scrollRef?.removeEventListener('scroll', onConversationsScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversations.length === 0) {
      setScrolledToBottom(false);
    }

    if (scrolledToBottom && newPageToken) {
      if (newPageToken) {
        setPageToken(newPageToken);
      }

      setScrolledToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom]);

  const groupedConversations: Record<string, Conversation[]> = useMemo(() => {
    const acc: Record<string, Conversation[]> = {};

    if (conversations.length === 0) {
      return acc;
    }

    for (const conversation of conversations) {
      const groupedDate = dayjs(conversation.timestamp).format(
        DEFAULT_DATE_FORMAT,
      );

      if (!acc[groupedDate]) {
        acc[groupedDate] = [conversation];
      } else {
        acc[groupedDate] = [...acc[groupedDate], conversation];
      }
    }

    return acc;
  }, [conversations]);

  const isEmpty = useMemo(() => {
    return Object.keys(groupedConversations).length === 0;
  }, [groupedConversations]);

  return (
    <div className={styles.root} ref={containerRef}>
      {isConversationsLoading ? (
        <div className={styles.spinnerWrapper}>
          <SpinnerSimple />
        </div>
      ) : (
        <>
          <div className={styles.container}>
            {Object.entries(groupedConversations).map(([date, group]) => (
              <ConversationsGroupedByDate
                key={date}
                date={date}
                conversations={group}
                selectedConversationId={currentConversationId}
              />
            ))}
          </div>

          {isOpenTier && <UpsellChatsMessage />}

          {!isEmpty && isConversationsFetching && (
            <SpinnerSimple size={SVG_SIZE_L} />
          )}

          {!isConversationsLoading && isEmpty && <EmptyContainer />}
        </>
      )}

      <ScrollToEdgeButton
        direction="up"
        scrollableContainerRef={containerRef}
        isVisible={isButtonScrollUpVisible && isLeftPanelExpanded}
        className={styles.scrollButton}
      />
    </div>
  );
};
