import {
  setActiveSuperAgentModel,
  threadState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';
import { SuperAgentModels } from 'src/types';

export const useSuperAgentModel = () => {
  const { activeSuperAgentModel } = useAppSelector(threadState);
  const dispatch = useAppDispatch();

  const onChangeSuperAgentModel = useCallback(
    (value: SuperAgentModels | null) => {
      batch(() => {
        dispatch(setActiveSuperAgentModel(value));
      });
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      activeSuperAgentModel,
      onChangeSuperAgentModel,
    }),
    [activeSuperAgentModel, onChangeSuperAgentModel],
  );
};
