import { useMemo } from 'react';

import { useAppSelector, conversationsListState } from 'src/store';

export const useConversationsFiltering = () => {
  const { taskStatusFilter, taskSkillFilter } = useAppSelector(
    conversationsListState,
  );

  return useMemo(
    () => ({
      taskStatusFilter,
      taskSkillFilter,
    }),
    [taskStatusFilter, taskSkillFilter],
  );
};
