import { memo, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { Message } from 'src/types';
import { MessageAuthorPicture } from '../MessageAuthorPicture';
import { UserMessageBody } from './components/UserMessageBody';
import styles from './UserMessage.module.scss';
import { PencilSimple } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { formatTimestampToHoursMinutes, isBetaEnv } from 'src/utils';
import {
  useBreakpoint,
  useLeftPanelState,
  useTimeZone,
  useVisible,
} from 'src/hooks';
import { EditableMessageInput } from './components/EditableMessageInput';
import className from 'classnames';

interface UserMessageProps {
  message: Message;
}

const UserMessageComponent = ({ message }: UserMessageProps) => {
  const { userTimeZone } = useTimeZone();
  const { isVisible, onVisibilityToggle } = useVisible();
  const { isLeftPanelExpanded } = useLeftPanelState();
  const { isDesktopSmallChat } = useBreakpoint();

  const { timestamp, content } = message;

  const currentTime = useMemo(
    () => formatTimestampToHoursMinutes(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  return (
    <div
      className={className(styles.root, {
        [styles.smallWidth]: isLeftPanelExpanded && isDesktopSmallChat,
      })}
    >
      <MessageAuthorPicture message={message} />

      <div className={styles.container}>
        {!isVisible && isBetaEnv && (
          <div className={styles.header}>
            <span className={styles.timeLabel}>{currentTime}</span>

            <Button
              color="link"
              className={styles.editButton}
              onClick={onVisibilityToggle}
            >
              <PencilSimple size={SVG_SIZE_M} />
            </Button>
          </div>
        )}

        {isVisible ? (
          <EditableMessageInput
            onCancel={onVisibilityToggle}
            defaultContent={content}
          />
        ) : (
          <UserMessageBody message={message} />
        )}
      </div>
    </div>
  );
};

export const UserMessage = memo(
  UserMessageComponent,
  (prevProps, nextProps) => {
    return isEqual(prevProps.message, nextProps.message);
  },
);
