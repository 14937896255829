import { createApi } from '@reduxjs/toolkit/query/react';
import { baseWalletAmpQuery } from './config';
import { UserTier } from 'src/types/models/UserTier';

export interface PreOnboardingRequest {
  amp_customer_id: string;
  amp_product_code: string;
}

export interface PreOnboardingResponse {
  success: boolean;
  user_signed_up: boolean;
  user_exists: boolean;
  user_tier?: UserTier;
}

export interface CompleteOnboardingRequest {
  email: string;
  amp_customer_id: string;
  amp_product_code: string;
}

export enum AmpOnboardingFailureReason {
  GENERAL = 'general',
  ON_ANOTHER_TEAMS_PLAN = 'on_another_teams_plan',
  ON_INDIVIDUAL_PLAN = 'on_individual_plan',
  ON_APPLE_INDIVIDUAL_PLAN = 'on_apple_individual_plan',
}

export interface CompleteOnboardingResponse {
  success: boolean;
  failure_reason?: AmpOnboardingFailureReason;
}

export enum WalletAmpTags {
  WalletAmp = 'WalletAmp',
}

export const walletAmpApi = createApi({
  reducerPath: 'WalletAmpApi',
  tagTypes: [WalletAmpTags.WalletAmp],
  baseQuery: baseWalletAmpQuery,
  endpoints: (builder) => ({
    onboard: builder.mutation<
      CompleteOnboardingResponse,
      CompleteOnboardingRequest
    >({
      query: (body) => ({
        url: '/onboard',
        method: 'PATCH',
        body,
      }),
    }),
    preOnboard: builder.mutation<PreOnboardingResponse, PreOnboardingRequest>({
      query: (body) => ({
        url: '/pre-onboard',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { usePreOnboardMutation, useOnboardMutation } = walletAmpApi;
