export const DEFAULT_NINJA_BETA_LINK = 'https://www.ninjatech.ai/';
export const DEFAULT_PRIVACY_POLICY_LINK = 'https://www.ninjatech.ai/privacy';

export const DEFAULT_TERMS_OF_SERVICE_LINK =
  'https://www.ninjatech.ai/terms-of-service';

export const DEFAULT_ACCEPTABLE_USE_POLICY =
  'https://www.ninjatech.ai/acceptable-use-policy';

export const DEFAULT_NINJATECH_LINK = 'https://www.ninjatech.ai';
export const DEFAULT_NINJATECH_BLOG_LINK = 'https://www.ninjatech.ai/news';
export const DEFAULT_NINJATECH_FAQ_LINK = 'https://www.ninjatech.ai/faq';
export const DEFAULT_NINJATECH_PRICING_LINK =
  'https://www.ninjatech.ai/pricing';

export const LEARN_MORE_PRICES_LINK =
  'https://www.ninjatech.ai/pricing#compare-plans';
export const PAY_PER_USE = 'https://www.ninjatech.ai/pricing#pay-per-use';
export const RATE_LIMIT = 'https://www.ninjatech.ai/pricing#rate-limits';

export const EXTERNAL_MODELS_LINK =
  'https://www.ninjatech.ai/product/external-model-access#all-models';

export const DEEP_RESEARCHER =
  'https://www.ninjatech.ai/product/ai-research-agent';
export const SUPER_AGENT = 'https://www.ninjatech.ai/product/super-agent';
export const IMAGE_GEN = 'https://www.ninjatech.ai/product/ai-image-generator';
export const IMAGE_EDITOR = 'https://www.ninjatech.ai/product/ai-image-editor';
export const CODER = 'https://www.ninjatech.ai/product/ai-code-generator';
export const SCHEDULER = 'https://www.ninjatech.ai/product/ai-scheduling-agent';
export const WRITER = 'https://www.ninjatech.ai/product/ai-writer';
export const FILE_AGENT =
  'https://www.ninjatech.ai/product/file-upload-analysis';

export const ONBOARDING_IMAGE_MOBILE =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Mobile_Onboarding_Modal.jpg';

export const ONBOARDING_IMAGE_DESKTOP =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Desktop_Onboarding_Modal.jpg';

export const WELCOME_PRO_TRIAL_IMAGE =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Welcome_to_ninja_pro_trial.gif';

export const DESKTOP_APP_DOWNLOAD_LATEST =
  'https://api.github.com/repos/NinjaTech-AI/desktop-app/releases/latest';
export const DESKTOP_APP_RELEASES_LATEST =
  'https://github.com/NinjaTech-AI/desktop-app/releases/latest';

export const EXTERNAL_MODELS_IMAGE_DARK =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/brand_logos_dark_mode.jpg';

export const EXTERNAL_MODELS_IMAGE_LIGHT =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/brand_logos_light_mode.jpg';

export const LEARN_MORE_ADVANCED_CODING =
  'https://www.ninjatech.ai/news/ninjatech-ai-achieves-state-of-the-art-humaneval-benchmark-with-new-agentic-workflow-for-codegen';

export const LEARN_MORE_VIDEO_GEN =
  'https://www.ninjatech.ai/product/ai-video-generator';

export const RANDOM_VIDEOS = [
  'https://d1h34sttp1evgt.cloudfront.net/Astronaut_watching_fireworks_dog.mp4',
  'https://d1h34sttp1evgt.cloudfront.net/Seashell_on_beach.mp4',
  'https://d1h34sttp1evgt.cloudfront.net/Cheeseburger_dolly_pan.mp4',
  'https://d1h34sttp1evgt.cloudfront.net/Flying_butterflies_neon_forest.mp4',
  'https://d1h34sttp1evgt.cloudfront.net/Campfire_in_forest.mp4',
  'https://d1h34sttp1evgt.cloudfront.net/Mountain_range_view.mp4',
];

export const IOS_MOBILE_APP_LINK =
  'https://apps.apple.com/ca/app/ninja-ai/id6702030312';

export const HELP_CENTER_LINK = 'https://help.myninja.ai/hc/en-us';
