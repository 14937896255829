import styles from './ThinkingStep.module.scss';
import { CheckCircle } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import type { CotReasoningStep } from 'src/types/models/CotReasoningStep';
import { StreamingText } from 'src/components/StreamingText';

type ThinkingStepProps = {
  step?: CotReasoningStep;
};

export const ThinkingStep = ({ step }: ThinkingStepProps) => {
  if (!step) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.checkWrapper}>
        <CheckCircle size={SVG_SIZE_M} weight="fill" />
      </div>
      <div className={styles.stepInfoWrapper}>
        <h4 className={styles.header}>{step.header}</h4>
        <StreamingText isStreaming={true} content={step.content || ''} />
      </div>
    </div>
  );
};
