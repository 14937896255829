import { useState } from 'react';
import { useExternalModels, useImageSettings, useSession } from 'src/hooks';
import { TextModelList } from '../TextModelList';
import { ImageModelList } from '../ImageModelList';
import { SubmitActions } from '../SubmitActions';
import { ExternalModelSettings, ExternalModelSettingsOption } from 'src/types';
import { TabList, Tab } from '../TabList';
import { NinjaTab } from '../NinjaTab';
import styles from './ExternalModelContainer.module.scss';

interface ExternalModelContainerProps {
  defaultTextModels: string[];
  defaultImageModels: string[];
  onClose: () => void;
}

export const ExternalModelContainer = ({
  defaultTextModels,
  defaultImageModels,
  onClose,
}: ExternalModelContainerProps) => {
  const {
    isFreeTier,
    isOpenTier,
    isStandardTier,
    isProTier,
    isProTrialTier,
    isTeamsStandardTier,
    isTeamsProTier,
  } = useSession();

  const { imageExternalModels } = useImageSettings();
  const { textExternalModels, updateExternalModels } = useExternalModels();

  const [selectedTextModels, setSelectedTextModels] =
    useState<string[]>(defaultTextModels);

  const [selectedImageModels, setSelectedImageModels] =
    useState<string[]>(defaultImageModels);

  const [activeTab, setActiveTab] = useState<Tab>('ninja');

  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const getUpdatedList = (selectedList: string[], triggeredItem: string) => {
    if (isSubmitDisabled) {
      setSubmitDisabled(false);
    }

    if (selectedList.includes(triggeredItem)) {
      return selectedList.filter((item) => item !== triggeredItem);
    } else {
      return selectedList.slice(-1).concat(triggeredItem);
    }
  };

  const handleTextModelClick = (textModel: string) => {
    setSelectedTextModels((prev) => getUpdatedList(prev, textModel));
  };

  const handleImageModelClick = (imageModel: string) => {
    setSelectedImageModels((prev) => getUpdatedList(prev, imageModel));
  };

  const handleReset = () => {
    if (isSubmitDisabled) {
      setSubmitDisabled(false);
    }

    setSelectedTextModels([]);
    setSelectedImageModels([]);
  };

  const updateEnablingTextModel = (models?: ExternalModelSettingsOption[]) => {
    return models?.map((item) => ({
      ...item,
      enabled: selectedTextModels.includes(item.model_name),
    }));
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);

    const updatedTextModels: Partial<ExternalModelSettings> = {
      amazon_bedrock: updateEnablingTextModel(
        textExternalModels.amazon_bedrock,
      ),
      openai: updateEnablingTextModel(textExternalModels.openai),
      google: updateEnablingTextModel(textExternalModels.google),
      together_ai: updateEnablingTextModel(textExternalModels.together_ai),
    };

    const updatedImageModels = imageExternalModels.map((item) => ({
      ...item,
      enabled: item.model.includes('ninjatech')
        ? item.enabled
        : selectedImageModels.includes(item.model),
    }));

    updateExternalModels({
      textExternalModels: updatedTextModels,
      imageExternalModels: updatedImageModels,
    });

    onClose();
  };

  const isLimitedForPro =
    isFreeTier || isOpenTier || isStandardTier || isTeamsStandardTier;
  const isLimitedForUltra = isProTier || isProTrialTier || isTeamsProTier;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <TabList activeTab={activeTab} setActiveTab={setActiveTab} />

        <div className={styles.tabContent}>
          {activeTab === 'ninja' && <NinjaTab />}

          {activeTab === 'external' && (
            <>
              <ImageModelList
                isLimitedForPro={isLimitedForPro}
                isLimitedForUltra={isLimitedForUltra}
                selectedImageModels={selectedImageModels}
                onClick={handleImageModelClick}
              />

              <hr className="divider" />

              <TextModelList
                isLimitedForPro={isLimitedForPro}
                isLimitedForUltra={isLimitedForUltra}
                selectedTextModels={selectedTextModels}
                onClick={handleTextModelClick}
              />
            </>
          )}
        </div>
      </div>

      {activeTab === 'external' && (
        <div>
          <hr className="divider" />
          <SubmitActions
            onReset={handleReset}
            onSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            isResetDisabled={
              isFreeTier ||
              isOpenTier ||
              isStandardTier ||
              (selectedTextModels.length === 0 &&
                selectedImageModels.length === 0)
            }
          />
        </div>
      )}
    </div>
  );
};
