import { useMemo, useRef } from 'react';
import { CaretUpDown, HeadCircuit } from '@phosphor-icons/react';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import {
  useBreakpoint,
  useExternalModels,
  useOutsideClick,
  useVisible,
  useConversationParams,
  useRightSidePanelState,
  useImageSettings,
} from 'src/hooks';
import { ExternalModelContainer } from './components/ExternalModelContainer';
import styles from './ExternalModelSelection.module.scss';
import { Counter } from 'src/v2/commonComponents/Counter';
import { ExternalModelSettingsOption } from 'src/types';

const TOOLTIP_ANCHOR_ID = 'external-model-selection-trigger';

export const ExternalModelSelection = () => {
  const { isLandingPage } = useConversationParams();

  const { imageExternalModels } = useImageSettings();
  const { textExternalModels } = useExternalModels();

  const containerRef = useRef(null);

  const { isMobileOrTablet } = useBreakpoint();
  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();
  const { shouldCompressThread } = useRightSidePanelState();

  const handleOutsideClick = () => {
    if (isMobileOrTablet) {
      return;
    }

    onVisibilityRemove();
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const handleToggleClick = () => {
    onVisibilityToggle();
  };

  const getSelectedList = (models?: ExternalModelSettingsOption[]) => {
    return (
      models
        ?.filter(({ enabled }) => enabled)
        .map(({ model_name }) => model_name) || []
    );
  };

  const defaultTextModelList = useMemo(
    () => [
      ...getSelectedList(textExternalModels.amazon_bedrock),
      ...getSelectedList(textExternalModels.openai),
      ...getSelectedList(textExternalModels.google),
      ...getSelectedList(textExternalModels.together_ai),
    ],
    [textExternalModels],
  );

  const defaultImageModelList = useMemo(
    () =>
      imageExternalModels
        .filter(({ enabled, model }) => enabled && !model.includes('ninjatech'))
        .map(({ model }) => model),
    [imageExternalModels],
  );

  const modelsLength = useMemo(
    () => defaultTextModelList.length + defaultImageModelList.length,
    [defaultTextModelList, defaultImageModelList],
  );

  return (
    <div ref={containerRef} className={styles.root}>
      <Button
        id={TOOLTIP_ANCHOR_ID}
        color="transparent"
        onClick={handleToggleClick}
        disabled={shouldCompressThread}
      >
        <HeadCircuit size={SVG_SIZE_M} />

        {!isMobileOrTablet && !shouldCompressThread && 'Model settings'}

        {modelsLength > 0 && (
          <Counter withPlus color="black" number={modelsLength} />
        )}

        <CaretUpDown size={14} />
      </Button>

      {isMobileOrTablet || shouldCompressThread ? (
        <BottomDrawer
          isOpen={isVisible}
          onClose={onVisibilityRemove}
          className={styles.drawer}
        >
          <ExternalModelContainer
            defaultTextModels={defaultTextModelList}
            defaultImageModels={defaultImageModelList}
            onClose={onVisibilityRemove}
          />
        </BottomDrawer>
      ) : (
        <ReactTooltip
          isOpen={isVisible}
          place={isLandingPage ? 'bottom-end' : 'left'}
          clickable
          className={styles.tooltip}
          noArrow
          anchorSelect={`#${TOOLTIP_ANCHOR_ID}`}
          openOnClick
          offset={isLandingPage ? -250 : -50}
          opacity={1}
        >
          <ExternalModelContainer
            defaultTextModels={defaultTextModelList}
            defaultImageModels={defaultImageModelList}
            onClose={onVisibilityRemove}
          />
        </ReactTooltip>
      )}
    </div>
  );
};
