import { ReactNode, useRef, useImperativeHandle, forwardRef, Ref } from 'react';
import { PlacesType } from 'react-tooltip';
import {
  useBreakpoint,
  useOutsideClick,
  useRightSidePanelState,
  useVisible,
} from 'src/hooks';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { ReactTooltip } from 'src/v2/commonComponents/ReactTooltip';
import { Button } from '../Button';
import { X } from '@phosphor-icons/react';
import styles from './DrawerTooltipContainer.module.scss';

export interface DrawerTooltipContainerRef {
  onVisibilityToggle: () => void;
}

interface TriggerProps {
  id: string;
  disabled?: boolean;
  isActive?: boolean;
  onClick: () => void;
}

interface DrawerTooltipContainerProps<T extends object> {
  id: string;
  place?: PlacesType;
  offset?: number;
  classNameDrawer?: string;
  classNameTooltip?: string;
  children: ReactNode;
  disabledToggle?: boolean;
  ToggleButton: (props: TriggerProps) => JSX.Element;
  toggleButtonProps?: T;
}

function DrawerTooltipContainerInner<T extends object>(
  {
    id,
    place,
    offset,
    classNameDrawer,
    classNameTooltip,
    children,
    disabledToggle,
    ToggleButton,
    toggleButtonProps = {} as T,
  }: DrawerTooltipContainerProps<T>,
  ref: Ref<DrawerTooltipContainerRef>,
) {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobileOrTablet } = useBreakpoint();
  const { shouldCompressThread } = useRightSidePanelState();

  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();

  useImperativeHandle(ref, () => ({
    onVisibilityToggle: onVisibilityRemove,
  }));

  const handleOutsideClick = () => {
    if (isMobileOrTablet || !isVisible) {
      return;
    }

    onVisibilityRemove();
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div ref={containerRef}>
      <ToggleButton
        id={id}
        disabled={disabledToggle}
        isActive={isVisible}
        onClick={onVisibilityToggle}
        {...toggleButtonProps}
      />

      {isMobileOrTablet || shouldCompressThread ? (
        <BottomDrawer
          isOpen={isVisible}
          onClose={onVisibilityRemove}
          className={classNameDrawer}
        >
          {children}
        </BottomDrawer>
      ) : (
        <ReactTooltip
          id={id}
          isOpen={isVisible}
          place={place}
          className={classNameTooltip}
          offset={offset}
        >
          <Button
            className={styles.closeButton}
            color="transparent"
            onClick={onVisibilityRemove}
          >
            <X size={12} />
          </Button>

          {children}
        </ReactTooltip>
      )}
    </div>
  );
}

export const DrawerTooltipContainer = forwardRef(
  DrawerTooltipContainerInner,
) as <T extends object>(
  props: DrawerTooltipContainerProps<T> & {
    ref?: Ref<DrawerTooltipContainerRef>;
  },
) => JSX.Element;
